import React from "react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Icon } from "semantic-ui-react";
import { options, optionsForDate } from "../GanttInfoRenderer";

export class MissionInfoRenderer extends React.Component<{ entity: any }> {

    render() {
        const ed = entityDescriptors["Mission2"];
        const { entity } = this.props;
        return entity ? <div className="flex-container-row flex-center flex-wrap gap3">
            {ed.getField("type").renderField(entity, options)}
            {ed.getField("inactivityType").renderField(entity, options)}
            {ed.getField("startAddress").renderField(entity, options)}
            {ed.getField("startTime").renderField(entity, optionsForDate)}
            <div><Icon name="arrow right" /></div>
            {ed.getField("endAddress").renderField(entity, options)}
            {ed.getField("endTime").renderField(entity, optionsForDate)}
            {ed.getField("humanResource").renderField(entity, options)}
            {ed.getField("equipmentResource").renderField(entity, options)}
            {ed.getField("equipmentType").renderField(entity, options)}
        </div > : null;
    }
}
