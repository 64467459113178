import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import React from "react";
import { Icon } from "semantic-ui-react";
import { options, optionsForDate } from "../GanttInfoRenderer";

export class FlightInfoRenderer extends React.Component<{ entity: any }> {

    renderFlight(entity: any) {
        const ed = entityDescriptors["Flight"];
        return entity ? <div className="flex-container-row flex-center flex-wrap gap3">
            {entity.name && ed.getField("departure").renderField(entity, options)}
            {ed.getField("name").renderField(entity, options)}
            {ed.getField("date").renderField(entity, optionsForDate)}
            {ed.getField("schengen").renderField(entity, options)}
            {ed.getField("parking").renderField(entity, options)}
            {ed.getField("lastKnownDateType").renderField(entity, options)}
            {ed.getField("origin").renderField(entity, options)}
            {ed.getField("destination").renderField(entity, options)}
            {ed.getField("comment").renderField(entity, options)}
            {ed.getField("passengersNo").renderField(entity, options)}
            {ed.getField("passengerWithDisabilities").renderField(entity, options)}
        </div> : null;
    }

    renderCommonFields(entity: any) {
        const ed = entityDescriptors["Flight"];
        return entity ? <div className="flex-container-row flex-center flex-wrap gap3">
            {ed.getField("planeIdentifier").renderField(entity, options)}
            {ed.getField("planeType").renderField(entity, options)}
        </div> : null;
    }

    renderTowedInfo(entity: any) {
        const ed = entityDescriptors["Flight"];
        return entity ? <div className="flex-container-row flex-center flex-wrap gap3">
            {entity.towedStatus && <Icon color={entity.towedStatus} name='truck' />}
            {ed.getField("towedInitialAddress").renderField(entity, options)}
            {!entity.towedRealStartTime && ed.getField("towedEstimatedStartTime").renderField(entity, options)}
            {ed.getField("towedRealStartTime").renderField(entity, optionsForDate)}
            {entity.towedStatus && <Icon name="arrow right" />}
            {ed.getField("towedAddress").renderField(entity, options)}
            {ed.getField("towedRealEndTime").renderField(entity, optionsForDate)}
        </div> : null;
    }

    render() {
        const { entity } = this.props;
        const arvFlight = entity.departure ? entity.rotationFlight : entity;           
        const depFlight = entity.departure ? entity : entity.rotationFlight;
        return entity ? <div className="flex-container gap5">
            {this.renderFlight(arvFlight)}
            {this.renderFlight(depFlight)}
            {this.renderCommonFields(entity)}
            {this.renderTowedInfo(entity)}
        </div> : null;
    }
}
