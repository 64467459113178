import { Utils } from "@crispico/foundation-react";
import _ from "lodash";
import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";
import { AbstractItemRenderer, AbstractItemRendererProps } from "./AbstractItemRenderer";
import { MissionStatus } from "./constants";
import { Icon } from "semantic-ui-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";

export class MissionItemRenderer extends AbstractItemRenderer<AbstractItemRendererProps> {

    protected get entityName(): string {
        return"Mission2";
    }

    static getMissionColor(mission: any, alpha?: number) {
        let color = "#808080"; // gray
        if (!mission?.status) {
            color = XopsColorRegistry.INSTANCE.getColor(Color.NO_MISSION);
        } else if (mission.finishedByUser) {
            color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED_BYUSER);
        } else {
            switch (mission?.status) {
                case MissionStatus.MISSION_STATUS_NEW:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_NEW);
                    break;
                case MissionStatus.MISSION_STATUS_VIEWED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_VIEWED);
                    break;
                case MissionStatus.MISSION_STATUS_STARTED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_STARTED);
                    break;
                case MissionStatus.MISSION_STATUS_FINISHED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED);
                    break;
                case MissionStatus.MISSION_STATUS_DRAFT:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_DRAFT);
                    break;
                case MissionStatus.MISSION_STATUS_TRANSFERED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_TRANSFERED);
                    break;
                case MissionStatus.MISSION_STATUS_IN_POSITION:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_IN_POSITION);
                    break;
                case MissionStatus.MISSION_STATUS_START_LOADING:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_START_LOADING);
                    break;
                case MissionStatus.MISSION_STATUS_STOP_LOADING:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_STOP_LOADING);
                    break;
                case MissionStatus.MISSION_STATUS_START_PAUSE:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_START_PAUSE);
                    break;
                case MissionStatus.MISSION_STATUS_VALIDATED:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_FINISHED);
                    break;
                case MissionStatus.MISSION_STATUS_PLANNED_NOT_DONE:
                    color = XopsColorRegistry.INSTANCE.getColor(Color.MISSION_STATUS_PLANNED_NOT_DONE);
                    break;
            }

        }
        return Utils.getRGBAColor(color, alpha);
    }

    protected renderContent(): string | JSX.Element {       
        const mission = this.propsCasted.entity;
        let content: React.ReactNode;
        if (!mission) {
            // nop
        } else if (mission?.inactivityType) {
            content = this.renderFields(mission, ["inactivityType.name"]);
        } else if (mission.tasks) {
            content = _.uniq(mission.tasks.map((task: any) => task.taskGroup)).map(flight => {
                return <span className="flex-container-row">{entityDescriptors["Flight"].getField("departure").renderField(flight)}{entityDescriptors["Flight"].getField("name").renderField(flight)} </span>
        });
        }
        return mission && (
            <>
                <span><Icon name="circle" style={{ color: MissionItemRenderer.getMissionColor(mission) }} /></span>
                {content}
            </>
        );
    }

    getColor(): string {
        const mission = this.propsCasted.entity;
        if (mission?.inactivityType && mission.inactivityType.color != undefined) {
            return Utils.convertColorToHex(mission.inactivityType.color);
        } 
        return mission?.type?.color !== undefined ? mission.type?.color : "grey";
    }

    getStyle() {
        var style = {
            ...super.getStyle(),
            borderColor: "grey",
        };
        return style;
    }

} 
