import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React from "react";

export const historicalMapAnalysisEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "HistoricalMapAnalysis",
    miniFields: ["name"],
    icon: "map outline"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "name", type: FieldType.string })
    .addFieldDescriptor({ name: "startDate", type: FieldType.date, enabled: false })
    .addFieldDescriptor({ name: "endDate", type: FieldType.date, enabled: false })
);
