import React from "react";
import { Draggable, Droppable } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import { GanttUtils } from "../GanttUtils";
import { ItemRenderer } from "@crispico/react-timeline-10000";
import { DragAndDropContoller } from "../DragAndDropControler";
import _ from "lodash";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";

export type AbstractItemRendererProps = {
    entity: any;
    ganttContextProps: any;
};

export class AbstractItemRenderer<P extends AbstractItemRendererProps = AbstractItemRendererProps> extends ItemRenderer {

    constructor(props: P) {
        super(props as any);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    protected get propsCasted(): P {
        return this.props as any as P;
    }

    protected get entityName(): string {
        throw new Error("This method needs to be implemented by subclasses!");
    }

    getStyle() {
        var style = {
            ...super.getStyle(),
            borderWidth: "2px",
            fontWeight: "bold",
            borderRadius: "5px"
        };
        return style;
    }

    protected getFieldValue(rootEntity: any, field: string) {
        let entity = { ...rootEntity };
        const fieldDescriptorChain = entityDescriptors[this.entityName].getFieldDescriptorChain(field);
        for (let index = 0; index < fieldDescriptorChain.length - 1; index++) {
            entity = entity[fieldDescriptorChain[index].getFieldName()];
            if (!entity) break;
        }
        return fieldDescriptorChain[0] ? fieldDescriptorChain[fieldDescriptorChain.length - 1].renderField(entity) : "";
    }

    protected renderFields(rootEntity: any, fields: string[], i: number = 0) {
        return fields.map((field) => {
            return <span key={i + "_" + field}>{this.getFieldValue(rootEntity, field)}</span>;
        })
    }

    getTitle(): string | JSX.Element {
        const { item } = this.props;
        return this.propsCasted.ganttContextProps.useDnD ? <Droppable className="flex-container-row flex-center" item={item} accept={DragAndDropContoller.INSTANCE.getAcceptedType(item.entityUid)} canDrop={DragAndDropContoller.INSTANCE.canDrop} drop={DragAndDropContoller.INSTANCE.onDrop}>
            <Draggable className="flex-container-row flex-center gap3" item={item} type={GanttUtils.fromEntityUid(item.entityUid).entityName}
                onDragStart={(ev: any) => {
                    const data = {
                        entityUid: item.entityUid,
                        offsetX: ev.clientX - ev.target!.getBoundingClientRect().x,
                        offsetY: ev.clientY - ev.target!.getBoundingClientRect().y,
                        duration: item.end - item.start
                    };
                    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
                }} >
                {this.renderContent()}
            </Draggable>
        </Droppable> : <div className="flex-container-row flex-center gap3">
            {this.renderContent()}
        </div>;
    }

    protected renderContent(): string | JSX.Element {
        return super.getTitle();
    }

    protected renderTooltip(): any {
        return undefined;
    }

}
