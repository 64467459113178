import { createSliceFoundation, EntityEditorPage, PropsFrom, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension } from "@crispico/foundation-react";
import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import React from "react";
import { InventoryPage, sliceInventoryPage } from "./InventoryPage";

export const inventoryHistoryDataEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "InventoryHistoryData",
    miniFields: ["id"],
    icon: "database"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "driverName", type: FieldType.string })
    .addFieldDescriptor({ name: "driverIdentifier", type: FieldType.string })
    .addFieldDescriptor({ name: "zone", type: FieldType.string })
    .addFieldDescriptor({ name: "creationDate", type: FieldType.date })
    .addFieldDescriptor({ name: "akhData", type: FieldType.string })
    .addFieldDescriptor({ name: "akeData", type: FieldType.string })
    .addFieldDescriptor({ name: "cpcData", type: FieldType.string })
    .addFieldDescriptor({ name: "cpbData", type: FieldType.string })
    
);

export const sliceEntityInventoryHistoryData = inventoryHistoryDataEntityDescriptor.infoEditor.slice = createSliceFoundation(class extends SliceEntityEditorPage {
    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
        inventoryPage: sliceInventoryPage
    }
}).setEntityDescriptor(inventoryHistoryDataEntityDescriptor);

inventoryHistoryDataEntityDescriptor.infoEditor.wrappedComponentClass = class extends EntityEditorPage<PropsFrom<typeof sliceEntityInventoryHistoryData>> {
    protected getExtraTabPanes() {
        return [...super.getExtraTabPanes(),
            {
                routeProps: { path: "/inventoryPage" },
                menuItemProps: { icon: "edit", content: _msg("InventoryPage.title") },
                render: () => <InventoryPage {...this.props.inventoryPage} dispatchers={this.props.dispatchers.inventoryPage} entityId={this.props.entity?.id}/>
            }
        ]
    }
}
