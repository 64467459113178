import { EntityDescriptor, EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";

export class AirlineEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Airline",
            icon: 'plane',
            miniFields: ["code"]
        });
    }

    protected customize() {
        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [
                    ...super.getExtraTabPanes(),
                    this.createOneToManyTabPane("AddressAirline", "airline.id", { entityField: "id" }),
                    this.createOneToManyTabPane("AirlineContact", "airline.id", { entityField: "id" })
                ];
            }
        }
    }
}