import gql from "graphql-tag";

export const FLIGHT = gql`
    fragment Flight on Flight {
        id name date departure parking {id name}
    }
`;

export const CONTAINER = gql`
    fragment Task on Task {
        id name
    }
`;

export const BAGGAGE = gql`
    fragment Baggage on Baggage {
        id number type
    }
`;

export const LOAD_FLIGHTS = gql`
    query loadFlights($params: FindByFilterParamsInput) { 
        flightService_findByFilter(params: $params) {
           results { ...Flight }
        }
    }
    ${FLIGHT}
`;

export const LOAD_CONTAINERS = gql`
    query loadContainers($params: FindByFilterParamsInput) { 
        taskService_findByFilter(params: $params) {
           results { ...Task }
        }
    }
    ${CONTAINER}
`;

export const LOAD_BAGGAGES = gql`
    query loadBaggages($params: FindByFilterParamsInput) { 
        baggageService_findByFilter(params: $params) {
           results { ...Baggage }
        }
    }
    ${BAGGAGE}
`;