import { createSliceFoundation } from "@crispico/foundation-react";
import { EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { BlocklyScriptEditorPage, SliceBlocklyScriptEditorPage } from "./BlocklyScriptXopsEditorPage";

export class BlocklyScriptXopsEntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.infoEditor.slice = createSliceFoundation(SliceBlocklyScriptEditorPage).setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = BlocklyScriptEditorPage;
    }

}

