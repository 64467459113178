import { apolloHttpLinkOptions, AppContainer, ENT_READ, initApolloClient, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { sessionGlobalReload } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { XopsInitializationsForClient } from "app";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { MissionEventValidationPage } from "pages/Mission2/MissionEventValidationPage";
import React from "react";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";

export const XOPS_MOBILE_PATH = "xops-mobile";
export const XOPS_MOBILE_SETTINGS_PATH = XOPS_MOBILE_PATH + "/settings";
export const XOPS_MOBILE_MISSIONS_PATH = XOPS_MOBILE_PATH + "/missions";
export const w = window as any;

w.xopsReact = XOPS_MOBILE_PATH;
w.adjustUrlToServerContext = Utils.adjustUrlToServerContext;

w.UtilsShowGlobalAlert = function (message: string, title?: string) {
  Utils.showGlobalAlert({
    title: title ? title : _msg("error.globalErrorMessage.network.title"),
    message: message,
    severity: Severity.ERROR
  });
}

w.xopsReactRedirectToMobilePathIfNecessary = async function (callback: any, path: string = "") {
  if (AppMetaTempGlobals.history.location.pathname?.indexOf(XOPS_MOBILE_PATH) === -1) {
    // if the user isn't on an xops-mobile page, we should first go there before going to next step
    // this is a workaround to display the modals of the mobile app
    // otherwize they are shown bellow, with default css (no bootstrap/bootbox css) => KO 
    AppMetaTempGlobals.history.push("/" + XOPS_MOBILE_PATH + path);
    // wait a period of time before continuing with the next step
    const delay = (ms: any) => new Promise(res => setTimeout(res, ms));
    await delay(700);
  }
  if (callback) {
    callback();
  }
}

w.xopsReactLogin = function (pathname: string) {
  AppMetaTempGlobals.history.push(pathname?.startsWith(XOPS_MOBILE_PATH) ? { pathname: "/login", state: { from: { pathname: "/" + pathname }}} : AppMetaTempGlobals.history.location);
}

w.xopsMobileWrapperAfterLogin = function () {
  const hr = (AppContainer.INSTANCE.getAppContainerContextValue() as XopsAppContainerContextValue).initializationsForClient.currentHumanResource;
  // set user in case when is logged as user/hr
  w.XopsLocalStorage.setUser(AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient.currentUser);
  if (!hr) {
    // this code is also called when the authentication is not with an HR.
    return;
  }
  // need to update the current qualification and driver at each login to have the up to date the values.
  w.XopsLocalStorage.setCurrentQualifications(hr.currentQualifications);
  w.XopsLocalStorage.setDriver(hr);

  // the messages are requested at initialization, but we can have a user with another language, need to request messages with correct language
  w.XopsUtils.requestMessages(function () {
    w.XopsUtils.afterLogin(hr, false);
  }, function () {
    w.xopsReactLogout();
  });
}

w.xopsReactLoadInitializationsForClient = async function(serverURL: any) {    
  // change graphql URI and init apollo client
  apolloHttpLinkOptions.uri = serverURL + "/graphql";
  Utils.forcedURL = serverURL;
  
  initApolloClient();
  const reload = window.sessionStorage.getItem(sessionGlobalReload);
  // get client init here
  await AppMetaTempGlobals.appMetaInstance.loadInitializationsForClient();
  
  if (AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient.currentUser && !reload) {
    w.xopsReactLogout();
  } else {
    if (reload) {
      // needed for old js code to re-put schedulers, data in localStorage
      const newDriver = (AppContainer.INSTANCE.getAppContainerContextValue() as XopsAppContainerContextValue).initializationsForClient.currentHumanResource;
      w.XopsUtils.afterLogin(newDriver, true);
    }
    w.xopsReactLogin(XOPS_MOBILE_PATH);
  }    
}

w.xopsReactLogout = function () {
  AppMetaTempGlobals.appMetaInstance.logout();
}

w.xopsMobileWrapperBeforeLogout = function () {
  w.XopsLocalStorage.removeUser();
  w.XopsLocalStorage.setLastActiveTime(null);
}

w.xopsReactHasPermission = function (permission: string) {
  return AppMetaTempGlobals.appMetaInstance.hasPermission(permission);
}

w.xopsReactChangeEquipmentResourceHandler = function () {
  // added additional check because when a quick logout is done before finishing to set the init data, this line will crash
  if (AppContainer.INSTANCE.getAppContainerContextValue().initializationsForClient && (AppContainer.INSTANCE.getAppContainerContextValue() as XopsAppContainerContextValue).initializationsForClient.currentHumanResource) {
    AppMetaTempGlobals.appMetaInstance.helperAppContainer.dispatchers.setInReduxState({
      initializationsForClient: {
        ...(AppContainer.INSTANCE.getAppContainerContextValue() as XopsAppContainerContextValue).initializationsForClient,
        currentHumanResource: {
          ...(AppContainer.INSTANCE.getAppContainerContextValue() as XopsAppContainerContextValue).initializationsForClient.currentHumanResource,
          vehicle: {identifier:  w.XopsLocalStorage.getEquipmentResource() }
        }
      }
    } as any);
  } 
}

w.parseEventMonitoringRulesJson = function(eventMonitoringRulesJson: string) {
  return MissionEventValidationPage.parseEventMonitoringRulesJson(eventMonitoringRulesJson);
}

export class XopsMobileWrapperState extends State { 
}
export class XopsMobileWrapperReducers<S extends XopsMobileWrapperState = XopsMobileWrapperState> extends Reducers<S> {
}
export class XopsMobileWrapper extends React.Component<RRCProps<XopsMobileWrapperState, XopsMobileWrapperReducers>> {

  static init() {
    console.log("XopsMobileWrapper, start init!");     
    var $ = require("jquery");
    var Backbone = require("backbone");
  
    // for Backbone.$ is not a function -> solution found here: https://stackoverflow.com/a/19529354			
    Backbone.$ = $;  
    w.jQuery = $;
    w.$ = $;
    w.UtilsShowSpinner = Utils.showSpinner;
    w.UtilsHideSpinner = Utils.hideSpinner;

    require("jquery-ui");
    require("underscore");
  
    require("bootstrap/dist/js/bootstrap");
    require("bootbox");
  
    require("../../xops-mobile-lib/lib/typeahead.bundle");
    require("../../xops-mobile-lib/lib/bootstrap-datetimepicker.min");
    require("ion-sound");
    require("bootstrap-select");
  
    require('./XopsMobileApp');
  
    w.xopsMobileAppInitialized = true;
  
    console.log("XopsMobileWrapper, end init!"); 
  }

  static isDeviceMobileApp() {
    if (w.isMobileApp === undefined) {
      w.isMobileApp = /(xopsMobileAPKMode)/i.test(navigator.userAgent);
    }
    return w.isMobileApp;
  }
  
  static getMobileVersion() {
    return XopsMobileWrapper.isDeviceMobileApp() && w.XopsConstants ? w.XopsConstants.WWW_VERSION : undefined;
  }

  static getMobileDeviceInfo() {
    return { mobileDeviceIdentifier: w.XopsGlobals ? w.XopsGlobals.getInstance().getIdentifier() : undefined };
  }

  render() {
    return <div className="xops-mobile flex-container flex-grow" >
      <div className="container-fluid" id="navbar"></div>

      <div id="refreshButtonDiv"></div>

      <div className="container-fluid" id="alert"></div>
      <div className="container-fluid" id="content"></div>

      <div id="developmentDiv"></div>
     
      {!w.xopsMobileAppInitialized ?
        <div id="screenBlockerDiv" className="modal fade " data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-hidden="true"
          style={{ display: 'block', opacity: 1, backgroundColor: 'rgb(227, 242, 253)' }}>
          <div className="center-div container" style={{ textAlign: "center" }} >
            <div className="row" id="logoDiv"> <img src={Utils.adjustUrlToServerContext("com/crispico/xops/images/xops_big.png", undefined, !w.isMobileApp)}
              style={{ minWidth: '50px', maxWidth: '100px', width: "50%" }} /></div>
            <div className="row"><i className="fa fa-spinner fa-spin fa-3x" style={{ color: '#337ab7' }}></i></div>
            <div className="row"><span id="screenBlockerText" style={{ fontWeight: 'bold', fontSize: 'large', color: '#337ab7' }}></span></div>
          </div>
        </div>
        : null}

    </div>
  }
}

const BACKBONE_ROUTES: string[] = [ '/user', '/driver', '/flexLogin', '/preAddChecklist', '/mission',
  '/addEvent', '/editEvent', '/BaggageView', '/ModifyObjectProcessedBaggagesView', '/ModifyObjectQuantityView',
  '/AddObjectView', '/AddCommentView', '/ModifyAddressView', '/OpenExternalAppView', '/missions', '/logout',
  '/flights', '/preCheckList', '/preAssignments', '/exitapp', '/flightConnections', '/createMission',
  '/manageVersions', '/crewBaggageMenu', '/scanCrewBaggage', '/messageHistory'
].map(r => "/" + XOPS_MOBILE_PATH + r);

const BACKBONE_PUBLIC_ROUTES: string[] = [ '', '/settings'
].map(r => "/" + XOPS_MOBILE_PATH + r);


export const XopsMobileWrapperHOC = ReduxReusableComponents.connectRRC(XopsMobileWrapperState, XopsMobileWrapperReducers, XopsMobileWrapper);

export const xopsMobileWrapperBackboneRoutes = () => {
  return BACKBONE_ROUTES.map(r =>
    <PrivateRoute key={"xM" + r} path={r}
      render={(props) => <XopsMobileWrapperHOC {...props} id={"xM" + r} />} />);
};
export const xopsMobileWrapperPageUrlPageMenuEntry = () => { return { content: _msg("XopsMobile.title"), to: "/" + XOPS_MOBILE_PATH, exact: true, icon: "mobile alternate", permission: Utils.pipeJoin([ENT_READ, missionEntityDescriptor.name]) } };
export const xopsMobileWrapperSettingsMenuEntry = () => { return { content: _msg("XopsMobile.settingsPage.title"), to: "/" + XOPS_MOBILE_SETTINGS_PATH, icon: "settings", exact: true } };

export const xopsMobileWrapperBackbonePublicRoutes = () => {
  return BACKBONE_PUBLIC_ROUTES.map(r =>
    <PrivateRoute key={"xPublicM" + r} path={r} privateRoute={false}
      render={(props) => <XopsMobileWrapperHOC {...props} id={"xPublicM" + r} />} />);
};
