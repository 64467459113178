import { EntityDescriptor, EntityTablePage, EntityTablePageState, EntityTablePageReducers, EntityTablePageProps } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

class BaggageTablePage extends EntityTablePage<EntityTablePageProps> {
    addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
        if (oneToManyModeField === "flight") {
            filters!.push(Filter.createComposed(FilterOperators.forComposedFilter.or,
                [Filter.create("inFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                Filter.create("outFlight", FilterOperators.forNumber.equals, oneToManyModeCachedId)]));
        } else {
            super.addOneToManyModeFilters(filters, oneToManyModeField, oneToManyModeEntityDescriptor, oneToManyModeCachedId);
        }

    }
}

const BaggageTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, BaggageTablePage);

export class BaggageEntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.addFieldDescriptor({ name: "inFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] } })
        this.addFieldDescriptor({ name: "outFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] } })
        this.isInDefaultColumnConfig(true, "number", "date", "airportCode", "location", "status", "inFlight", "outFlight")
    }

    renderTable() {
        return <BaggageTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}
