import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { CheckboxProps, Radio } from "semantic-ui-react";
import _ from "lodash";
import { FieldEditor, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export const DOMESTIC = 'D';
export const INTERNATIONAL = 'I';

export class FlightSchengenFieldEditor extends FieldEditor<boolean, FieldEditorProps> {

    handleChange = (_: any, data: CheckboxProps, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) => {
        if (data.value === DOMESTIC) {
            s.setFieldValue(hw, true);
        } else if (data.value === INTERNATIONAL) {
            s.setFieldValue(hw, false);
        }
        // rerender for update the radios
        this.forceUpdate();
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        const schengen = this.getValue();
        return <div className="flex-container-row">
            <Radio className="very-small-padding" data-tooltip={_msg("flight.schengen.domestic")}
                label={DOMESTIC} value={DOMESTIC}
                checked={schengen === true}
                onChange={(event, data) => this.handleChange(event, data, s, hw)}
            />
            <Radio className="very-small-padding" data-tooltip={_msg("flight.schengen.international")}
                label={INTERNATIONAL} value={INTERNATIONAL}
                checked={schengen === false}
                onChange={(event, data) => this.handleChange(event, data, s, hw)}
            />
        </div>;
    }
}
