import { FilterOperators } from "@crispico/foundation-gwt-js";
import { addEntityDescriptor, apolloClient, EntityDescriptor, EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, ITableActionParamForRun, Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { addAfterStartupRunnable } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Modal, Form, Segment, Button, CheckboxProps, Checkbox, Icon, Dropdown, Menu, DropdownProps } from "semantic-ui-react";
import React, { ReactNode } from "react";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import gql from "graphql-tag";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { default as lodash } from "lodash";
import { Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { IAction } from "@crispico/react-timeline-10000/types/components/ContextMenu/IAction";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

enum POSITION_DB_TYPE {
    REAL_TIME,
    LONG_STORAGE
}

export let positionEntityDescriptor: EntityDescriptor;

class PositionTablePageState extends EntityTablePageState {
    telemetryEventMapping: string[] = [];
    markUnmappedFields: boolean = false;
    currentDb: POSITION_DB_TYPE = POSITION_DB_TYPE.REAL_TIME;
}

class PositionTablePageReducers<S extends PositionTablePageState = PositionTablePageState> extends EntityTablePageReducers<S> { }

type PositionTablePageProps = EntityTablePagePartialProps & RRCProps<PositionTablePageState, PositionTablePageReducers>;

class PositionTablePage<P extends PositionTablePageProps = PositionTablePageProps> extends EntityTablePage<P> {

    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    constructor(props: P) {
        super(props);

        this.setOptions({ countMode: undefined });
    }

    async getTelemetryEventMapping() {
        const loadOperationName = "telemetryEventMappingService_findByFilter";
        const customFields_findByFilter = gql(`query q($params: FindByFilterParamsInput) { 
            ${loadOperationName}(params: $params) { results { mappingFrom } } }`);
        const result = (await apolloClient.query({ query: customFields_findByFilter, variables: FindByFilterParams.create() })).data[loadOperationName];
        if (result.results.length > 0) {
            this.props.r.setInReduxState({ telemetryEventMapping: result.results.map((x: { mappingFrom: string }) => x.mappingFrom.toUpperCase()) })
        }
    }

    getLoadOperationName(ed: EntityDescriptor) {
        if (this.props.s.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
            return `${lodash.lowerFirst(ed.name)}Service_findByFilterFromLongStorage`;
        }
        return super.getLoadOperationName(ed);
    }

    async refresh() {
        if (!(this.props.s.telemetryEventMapping && this.props.s.telemetryEventMapping.length > 0)) {
            this.getTelemetryEventMapping();
        }
        super.refresh();
    }

    private onCheckboxChange = (e: any, data: CheckboxProps) => {
        this.props.r.setInReduxState({ markUnmappedFields: data.checked });
        this.refresh();
    };

    private onDbChange = (e: any, data: DropdownProps) => {
        this.props.r.setInReduxState({ currentDb: data.value as POSITION_DB_TYPE });
        this.refresh();
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        const { hasLongStorage } = this.context.initializationsForClient;
        return [
            ...super.preRenderButtons(params),
            { elementType: Checkbox, props: { key: "markUnmappedFields", checked: this.props.s.markUnmappedFields, label: _msg("Position.markUnmappedFields"), onChange: this.onCheckboxChange, className: "tiny-margin-right" } },
            hasLongStorage && {
                element: <Dropdown key="xxxx" placeholder='Dropdown' options={[
                    { text: _msg("Position.realTimeDb"), value: POSITION_DB_TYPE.REAL_TIME },
                    { text: _msg("Position.archivedDb"), value: POSITION_DB_TYPE.LONG_STORAGE }
                ]} selection value={this.props.s.currentDb} onChange={this.onDbChange} compact className="CustomQueryBar_sortFilterAddButton" />
            }
        ];
    }

    protected onDoubleClickItem(entity: any) {
        if (this.props.s.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
            // show notification that archived cannot be shown in editor
            Utils.showGlobalAlert({ message: _msg("Position.archiveNoEditor"), severity: Severity.WARNING, title: _msg("general.warning") });
        } else {
            super.onDoubleClickItem(entity);
        }
    }

    protected isEditableCell(initialFormikValues: any, fieldDescriptor?: FieldDescriptor): boolean {
        if (this.props.s.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
            // disable editing of fields
            return false;
        }
        return super.isEditableCell(initialFormikValues, fieldDescriptor);
    }

    protected renderCompactBarMenuModal() {
        if (this.props.s.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
            // show only the "Display as card" menu
            return <Menu vertical className="wh100" borderless>
                <div className="flex-center EntityTablePage_compactMenu_additional">
                    <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("ColumnConfig.displayAsCards.label")}</div>
                    <Checkbox checked={this.columnConfigDropdownRef.current?.props.dispatchers.getState().columnConfig?.displayAsCards} onChange={(e, data) =>
                        this.applyContextMenu({ displayAsCards: data.checked })
                    } />
                </div>
            </Menu>;
        }
        return super.renderCompactBarMenuModal();
    }

    protected provideActionsForRow(actionParam: ITableActionParamForRun): IAction[] {
        if (this.props.s.currentDb == POSITION_DB_TYPE.LONG_STORAGE) {
            // replace the context menu with a text saying that archived position can not be edited
            return [
                {
                    renderInMenu: (param) => <div className="flex-center EntityTablePage_compactMenu_additional">
                        <div className="small-margin-right" style={{ flexGrow: 1 }}>{_msg("Position.archiveNoEditor")}</div>
                    </div>
                }];
        }
        return super.provideActionsForRow(actionParam);
    }

    renderMain() {
        if (this.props.oneToManyMode?.entity && !this.props.oneToManyMode.entity[this.props.oneToManyMode.entityField!]) {
            return <Segment size="large" inverted color="red" style={{ textAlign: 'center' }} ><Icon name="exclamation triangle" /> {_msg("Position.missingEntityField", _msg(this.props.oneToManyMode.entityDescriptor.name + ".label"), _msg(this.props.oneToManyMode.entityDescriptor.name + "." + this.props.oneToManyMode.entityField! + ".label"))}</Segment>
        }
        return super.renderMain()
    }

};

const PositionTablePageRRC = ReduxReusableComponents.connectRRC(PositionTablePageState, PositionTablePageReducers, PositionTablePage);

type TelemetryFieldType = FieldRendererProps & { telemetryEventMapping: string[], markUnmappedFields: boolean }
class TelemetryFieldRenderer extends React.Component<TelemetryFieldType, { modalOpen: boolean }> {

    constructor(props: TelemetryFieldType) {
        super(props);
        this.state = { modalOpen: false };
    }

    render() {
        const text = this.props.value ? String(this.props.value) : "";
        let field = <>{text}</>;

        if (this.props.markUnmappedFields) {
            field = <>{text.split(",").map(x => {
                if (x === "") return null;
                const fieldValue = x.split(":");
                if (this.props.telemetryEventMapping.includes(fieldValue[0].toUpperCase())) {
                    return <>{x},</>;
                } else {
                    return <><span style={{ color: "red" }}>{fieldValue[0]}</span>:{fieldValue[1]},</>
                }
            })}</>
        }

        return <>
            <span onDoubleClick={(evt) => { this.setState({ modalOpen: true }); evt.stopPropagation() }} >{field}</span>
            <ModalExt open={this.state.modalOpen} closeOnDocumentClick onClose={() => this.setState({ modalOpen: false })} size='tiny'>
                <Modal.Content>
                    <Form className="wh100">
                        <Segment style={{ overflowWrap: "break-word" }}>{field}</Segment>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={() => this.setState({ modalOpen: false })}>Close</Button>
                </Modal.Actions>
            </ModalExt>
        </>
    }
}

addAfterStartupRunnable(() => {
    const telemetryFieldDescriptor = new class extends FieldDescriptor {
        constructor() {
            super();
            this.name = "telemetry";
            this.type = "custom";
            this.sortable = false;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            return <TelemetryFieldRenderer {...props}
                markUnmappedFields={(this.parent.entityTablePage.current?.props as PositionTablePageProps).s.markUnmappedFields}
                telemetryEventMapping={(this.parent.entityTablePage.current?.props as PositionTablePageProps).s.telemetryEventMapping} />;
        }
    }();

    class PositionEntityDescriptor extends EntityDescriptor {
        constructor() {
            super({
                name: "Position",
                miniFields: ["id"],
                icon: "point",
                defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today, ''),
                defaultSort: { field: "date", direction: "DESC" }
            });
        }

        renderTable() {
            return <PositionTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
        }
    }

    positionEntityDescriptor = addEntityDescriptor(new PositionEntityDescriptor()
        .removeFieldDescriptors("hasTelemetry")

        .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
        .addFieldDescriptor({ name: "date", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "dateReceived", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "dateProcessed", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
        .addFieldDescriptor({ name: "gpsProvider", type: FieldType.dropdown })
        .addFieldDescriptor({ name: "plateNumber", type: FieldType.string })
        .addFieldDescriptor({ name: "longitude", type: FieldType.double })
        .addFieldDescriptor({ name: "latitude", type: FieldType.double })
        .addFieldDescriptor(telemetryFieldDescriptor)
        .addFieldDescriptor({ name: "speed", type: FieldType.number })
        .addFieldDescriptor({ name: "accuracy", type: FieldType.double })
        .addFieldDescriptor({ name: "engineState", type: FieldType.string })
        .addFieldDescriptor({ name: "odometer", type: FieldType.double })
        .addFieldDescriptor({ name: "motorHour", type: FieldType.double })
    );

});