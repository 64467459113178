import { DummyToRememberPeopleToCast, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { FormikProps } from "formik";
import { Upload } from "antd";
import { UploadChangeParam, UploadProps } from "antd/lib/upload";
import { Button, Icon, TextArea } from "semantic-ui-react";

const IN_PROGRESS = "inProgress";
const DBC_TYPE = ".dbc";

export class DbcContentFieldDescriptor extends FieldDescriptor {
    
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        const props: UploadProps = {
            onChange: (info: UploadChangeParam) => {
                const file = info.fileList[info.fileList.length - 1];
                if (file.status !== 'uploading') {
                    formikProps.setStatus(IN_PROGRESS);
                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        if (e.target) {
                            formikProps.setFieldValue("name", file.name.replace(DBC_TYPE, ""));
                            formikProps.setFieldValue(this.name, e.target.result as string);
                            formikProps.setStatus(undefined);
                        }
                    }
                    if (file.originFileObj) {
                        reader.readAsText(file.originFileObj as Blob);
                    }
                }
                if (file.status === 'error') {
                    Utils.showGlobalAlert({ message: _msg('Dbc.uploadContent.error') });
                }
            },
            multiple: false,
            accept: DBC_TYPE,
            showUploadList: false
        };
        return <>
            <TextArea rows={10} value={formikProps.values[this.name] || ""} onInput={(e) => e.preventDefault()} />
            <p/>
            <Upload {...props} disabled={formikProps?.status === IN_PROGRESS} beforeUpload={() => false}>
                <Button disabled={formikProps?.status === IN_PROGRESS} color="blue">
                    <Icon loading={formikProps?.status === IN_PROGRESS} name={formikProps?.status === IN_PROGRESS ? "spinner" : "upload"} />
                    {_msg("Dbc.uploadContent.label")}
                </Button>
            </Upload>
            <Button disabled={formikProps?.status === IN_PROGRESS} color="blue" onClick={() => {
                formikProps.setStatus(IN_PROGRESS);
                this.downloadFile(formikProps.values[this.name], formikProps.values["name"] + DBC_TYPE, "text/plain");
                formikProps.setStatus(undefined);
            }}>
                <Icon loading={formikProps?.status === IN_PROGRESS} name={formikProps?.status === IN_PROGRESS ? "spinner" : "download"} />
                {_msg("Dbc.downloadContent.label")}
            </Button>
        </>
    }

    downloadFile(content: string, filename: string, contentType: string) {
        const blob = new Blob([content], { type: contentType });
        const url = URL.createObjectURL(blob);
        
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename;
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
    }
}