import { Button, Checkbox, Icon } from "semantic-ui-react";

const puckIdentifier = [2, 1, 6, 5, 22];

const bluetoothle = require("bluetoothle")

export interface AbstractBluetoothHelper {
    init: (addDevice: Function) => void,
    decodeAdvertisement: (advertisement: string) => { decodedAdvertisement: string[], checkIsPuck: boolean }
    scan: (addDevice: Function, changeValues: Function, scanInterval: number) => any,
    renderOptions: (addDevice: Function, openMenu: Function, changeValues: Function, scanInterval: number, renderMenu: Function) => JSX.Element,
    initializeSuccess?: (result: any, addDevice: Function) => void,
    startScan?: (addDevice: Function) => void,
    isPuck?: (advertisement : string) => boolean,
}

export const MobileBluetoothHelper: AbstractBluetoothHelper = {
    init: (addDevice: Function) => {
        document.addEventListener('deviceready', function () {
            new Promise(function (resolve) {
                bluetoothle.initialize(resolve, { request: true, statusReceiver: false });
            }).then((value) => MobileBluetoothHelper.initializeSuccess && MobileBluetoothHelper.initializeSuccess(value, addDevice), (error) => console.log(error));
        });
    },

    // Decode advertisements based on https://elainnovation.com/wp-content/uploads/2022/06/BLE-Range-User-Guide-12F-EN-1.pdf,
    // Chapter 5, <<MOV>> format example
    decodeAdvertisement: (advertisement: string) => {
        let decodedAdvertisement = [advertisement];
        let checkIsPuck = false;
        if (MobileBluetoothHelper.isPuck && MobileBluetoothHelper.isPuck(advertisement)) {
            let bytes: any[] = bluetoothle.encodedStringToBytes(advertisement);
            let LSB = bytes[7];
            let MSB = bytes[8] << 8;
            let MOV_data = MSB | LSB;
            decodedAdvertisement = [
                _msg("BluetoothDevice.counter.label") + " " + (MOV_data >> 1),
                _msg("BluetoothDevice.level.label") + " " + (MOV_data & 1)
            ];
            checkIsPuck = true;
        }
        return { decodedAdvertisement, checkIsPuck };
    },

    scan: (addDevice: Function, changeValues: Function, scanInterval: number) => MobileBluetoothHelper.init(addDevice),

    renderOptions: (addDevice: Function, openMenu: Function, changeValues: Function, scanInterval: number, renderMenu: Function) => {
        return <Checkbox toggle label={_msg("BluetoothDevice.puck.label")} onChange={(event, data) =>
            changeValues({ displayOnlyPucks: data.checked as boolean })
        } />
    },

    initializeSuccess: (result: any, addDevice: Function) => {
        if (result.status === "enabled") {
            setTimeout(() => {
                MobileBluetoothHelper.startScan && MobileBluetoothHelper.startScan(addDevice);
            }, 1000);
        }
    },

    startScan: (addDevice: Function) => {
        bluetoothle.startScan((value: any) => {
            if (value.status !== "scanStarted") {
                addDevice(value);
            }
        }, (error : any) => console.log(error), {
            "allowDuplicates": false,
            "callbackType": bluetoothle.CALLBACK_TYPE_ALL_MATCHES,
        });

        setTimeout(() => {
            bluetoothle.stopScan(() => { }, () => { });
            setTimeout(() => {
                MobileBluetoothHelper.startScan && MobileBluetoothHelper.startScan(addDevice);
            }, 10000);
        }, 10000);
    },

    isPuck: (advertisement) => {
        let bytes = bluetoothle.encodedStringToBytes(advertisement);
    
        if (bluetoothle.bytesToEncodedString(puckIdentifier) ===
            bluetoothle.bytesToEncodedString(Array.from(bytes).splice(0, 5))) {
            return true;
        }
    
        return false;
    }
}

export const WebBluetoothHelper: AbstractBluetoothHelper = {
    init: (addDevice: Function) => { },

    decodeAdvertisement: (advertisement: string) => { return { decodedAdvertisement: [], checkIsPuck: false } },

    scan: async (addDevice: Function, changeValues: Function, scanInterval: number) => {
        try {
            let scan = await navigator.bluetooth.requestLEScan({ acceptAllAdvertisements: true });

            changeValues({ scanStarted: true });

            navigator.bluetooth.addEventListener('advertisementreceived', event => {
                addDevice(event);
            });

            setTimeout(() => {
                changeValues({ scanStarted: false });
                scan.stop();
            }, scanInterval * 1000);
        }
        catch (error) {
            changeValues({ errorMessage: error as string });
        }
    },

    renderOptions: (addDevice: Function, openMenu: Function, changeValues: Function, scanInterval: number, renderMenu: Function) => {
        return <>
            <Button onClick={() => WebBluetoothHelper.scan(addDevice, changeValues, scanInterval)} className="BluetoothDevicesPage_scanButton">
                <Icon name="rss" />{_msg("BluetoothDevicesPage.scan")}
            </Button>
            <Button id="scanDropdownButton" className="BluetoothDevicesPage_scanDropdownButton" icon="dropdown"
                onClick={(e) => { openMenu(e); }} />
            {renderMenu()}
        </>;
    }
}