import gql from "graphql-tag";

export const CREATE_ACTION_FROM_FLIGHT = gql`
    mutation createActionFromFlight($flight: FlightInput, $comment: String) {
        flightAndObjectActionService_createActionFromFlight(flight: $flight, comment: $comment) 
    }
`;

export const DELETE_COMMENT_FROM_ACTION = gql`
    mutation removeCommentFromAction($flight: FlightInput) {
        flightAndObjectActionService_removeCommentFromAction(flight: $flight) 
    }
`;

export const LOAD_ROTATION_FLIGHTS = gql`
    query getRotationFlights($params: FindByFilterParamsInput) {
        flightService_findByFilter(params: $params) {
            results { id name date origin destination __typename }
        }
    }
`;