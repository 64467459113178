import { apolloClient, apolloClientHolder, PrivateRoute, PrivateRouteProps, Utils } from "@crispico/foundation-react";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment from "moment";
import React, { RefObject } from "react";
import { Button, Checkbox, Header, Icon, Modal, Segment, SemanticICONS } from "semantic-ui-react";
import { GanttDoubleDisplayMode, GanttDoublePage, GanttDoublePageRRC } from "./GanttDouble";
import { LOAD_DATA_FOR_GANTT_PAGE } from "./queries";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import gql from "graphql-tag";
import { isFlexMode } from "app";
import { GanttSelectDate, GanttSelectDateRRC } from "./components/GanttSelectDate";
import { HW, ScriptableUi, castWithHw } from "@famiprog-foundation/scriptable-ui";
import { XopsAppContainerContext } from "XopsAppContainerContext";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";

const REFRESH_RATE = 3000;

export interface RealTimeGanttPageSettings {
    degradedMode: boolean;
}

export class RealTimeGanttPageState extends State {
    settings: RealTimeGanttPageSettings | undefined;
    dateForQueryAsString: string | undefined;
    autoRefresh: boolean | undefined;
    settingsModal = false as boolean | [number, number];
    isLoading = false;
}

export class RealTimeGanttPageReducers<S extends RealTimeGanttPageState = RealTimeGanttPageState> extends Reducers<S> {
}

export namespace ScriptableUiRealTimeGanttPage {
    export class TabBar extends ScriptableUi<TabBar> {
        getComponentName() { return "ScriptableUiRealTimeGanttPage.TabBar" };
        onClickSettingsBtn() { };
    }
}

type RealTimeGanttPageProps = RRCProps<RealTimeGanttPageState, RealTimeGanttPageReducers> & { displayMode: GanttDoubleDisplayMode }
export class RealTimeGanttPage extends React.Component<RealTimeGanttPageProps> {
    protected ganttDoublePageRef = React.createRef<GanttDoublePage>();
    protected topBarRef: RefObject<HTMLDivElement> = React.createRef();
    protected ganttSelectDateRef = React.createRef<GanttSelectDate>();

    private timer: number | undefined = undefined;

    constructor(props: any) {
        super(props);
        this.closeSettingModal = this.closeSettingModal.bind(this);
    }

    private async getSetting() {
        const settings = (await apolloClientHolder.apolloClient.query({
            query: gql(`query q { 
                mission2Service_realTimeGanttPageSettings {
                    degradedMode
                }
            }`)
        })).data["mission2Service_realTimeGanttPageSettings"];
        this.props.r.setInReduxState({ settings })
    }

    componentDidMount() {
        AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = AppMetaTempGlobals.history.location;
        this.refresh();
    }

    refresh() {
        let autoRefresh = window.sessionStorage.getItem(REAL_TIME_GANTT_PAGE_AUTO_REFRESH) as boolean | string | null,
            dateForQueryAsString = window.sessionStorage.getItem(REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY);
        if (autoRefresh === null) {
            autoRefresh = true;
        } else {
            autoRefresh = autoRefresh === "true";
        }
        if (dateForQueryAsString === null) {
            dateForQueryAsString = moment().format(Utils.dateFormat);
        }
        this.props.r.setInReduxState({ autoRefresh, dateForQueryAsString });

        this.getSetting();

        if (isFlexMode()) {
            globalThis.ganttDateChange = (date: number) => {
                this.props.r.setInReduxState({ dateForQueryAsString: moment(date).format(Utils.dateFormat) });
            }
        }
    }

    componentDidUpdate(prevProps: RealTimeGanttPageProps) {
        let shouldRefreshEntities = false;

        if (prevProps.s.autoRefresh != this.props.s.autoRefresh) {
            window.sessionStorage.setItem(REAL_TIME_GANTT_PAGE_AUTO_REFRESH, this.props.s.autoRefresh ? "true" : "false");
            shouldRefreshEntities = true;
        }
        if (prevProps.s.dateForQueryAsString !== this.props.s.dateForQueryAsString) {
            window.sessionStorage.setItem(REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY, this.props.s.dateForQueryAsString || moment().format(Utils.dateFormat));
            shouldRefreshEntities = true;
        }
        if (shouldRefreshEntities) {
            this.stopTimer();
            this.loadEntities();
        }
    }

    componentWillUnmount() {
        AppMetaTempGlobals.appMetaInstance.tempLocationForEditorBackground = undefined;
        this.stopTimer();
    }

    private startTimer(forceStart: boolean = false) {
        // if a timer is in progress, let's stop it;
        this.stopTimer();
        this.timer = forceStart || this.props.s.autoRefresh ? window.setTimeout(() => this.loadEntities(), REFRESH_RATE) : undefined;
    }

    private stopTimer() {
        clearTimeout(this.timer);
    }

    private loadEntities() {
        this.props.r.setInReduxState({ isLoading: true });
        apolloClient.query({
            query: LOAD_DATA_FOR_GANTT_PAGE,
            variables: { date: this.props.s.dateForQueryAsString },
            context: { showSpinner: false }
        }).then(async result => {
            if (this.ganttDoublePageRef.current) {
                const entities = result.data["diffUpdateService_ganttData"];
                this.ganttDoublePageRef.current!.updateEntities(entities);
                this.props.r.setInReduxState({ isLoading: false });
                this.startTimer();
            }
        })
    }

    private closeSettingModal() {
        this.props.r.setInReduxState({ settingsModal: false });
    }

    protected renderTabBar() {
        if (isFlexMode()) {
            // on flex mode hide the top bar
            return null;
        } 
        return <ScriptableUiRealTimeGanttPage.TabBar id={ScriptableUi.SINGLETON} implementation={{
            onClickSettingsBtn: () => { const rect = document.getElementById("settingsButtonRef")!.getBoundingClientRect(); this.props.r.setInReduxState({ settingsModal: [rect.left, rect.bottom] })}
        }}>{s => <>
        <Segment className="less-padding no-margin flex-container-row flex-center flex-wrap gap5" style={{ zIndex: 10 }}>
            <div className="flex-container-row">
                <GanttSelectDateRRC id='ganttSelectDate' ref={this.ganttSelectDateRef} initialDate={moment(this.props.s.dateForQueryAsString, Utils.dateFormat).startOf("day").toISOString()}
                    onChange={(date) => this.props.r.setInReduxState({ dateForQueryAsString: moment(date).format(Utils.dateFormat) })}
                />
                <HW id="settingBtn" children={hw => <Button className="less-padding" id="settingsButtonRef" color="green" size="tiny" icon="settings" onClick={(e) => castWithHw(s).onClickSettingsBtn(hw)}/> }/>
                {this.props.s.isLoading && <Icon name="spinner" color="red" size="large" loading />}
                <ModalExt className="RealTimeGanttPage_settingsModal" transparentDimmer open={this.props.s.settingsModal}
                    onMount={() => /* rerender because the content contains the portalContainer for gantts */  this.forceUpdate()}
                    onClose={this.closeSettingModal} >
                    <Modal.Content>
                        <div className="flex-container">{_msg("RealTimeGanttPage.autoRefresh.title")}
                            <Checkbox toggle size="tiny" checked={this.props.s.autoRefresh} onClick={(event, data) =>
                                this.props.r.setInReduxState({ autoRefresh: data.checked ? true : false })
                            } />
                        </div>
                        <div className="flex-container gap5" ref={this.topBarRef} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeSettingModal} >{_msg("general.close")}</Button>
                    </Modal.Actions>
                </ModalExt>
            </div>
        </Segment></>}</ScriptableUiRealTimeGanttPage.TabBar>
    }

    protected renderContent() {
        return <div className="flex-container flex-grow less-padding">
            {!isFlexMode() && <Header as="h3" className="small-margin-top no-margin"><Icon name={realTimeGanttPageMenuEntry().icon as SemanticICONS} />{realTimeGanttPageMenuEntry().content}</Header>}
            {this.renderTabBar()}
            <Segment className="flex-container-row flex-grow less-padding less-margin-top-bottom flex-center">
                <GanttDoublePageRRC id="GanttDoublePageRRC" ref={this.ganttDoublePageRef} portalContainerForTopBar={this.topBarRef.current} displayMode={this.props.displayMode}
                    startDate={moment(this.props.s.dateForQueryAsString, Utils.dateFormat).startOf('day').toString()} endDate={moment(this.props.s.dateForQueryAsString, Utils.dateFormat).endOf('day').toString()} />
            </Segment>
        </div>;
    }

    render() {      
        return <AppContainerContext.Consumer>{ context =>
            <>
                <Utils.Observer value={context.initializationsForClient.currentOrganization} didUpdate={() => this.refresh()} />
                {this.renderContent()}
            </>
        }
        </AppContainerContext.Consumer>;
    }
}

export const RealTimeGanttPageRRC = ReduxReusableComponents.connectRRC(RealTimeGanttPageState, RealTimeGanttPageReducers, RealTimeGanttPage);

export const realTimeGanttPageUrl = "/RealTimeGantt";
export const realTimeGanttPageRoute = () =>
    <PrivateRoute key="realTimeGanttPage"
        path={realTimeGanttPageUrl}
        render={(props) => {
            const displayMode = new URLSearchParams(props.location.search).get("displayMode") === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? GanttDoubleDisplayMode.FLEX_OBJECT_GANTT : GanttDoubleDisplayMode.DEFAULT;
            return <RealTimeGanttPageRRC displayMode={displayMode} id="realTimeGanttPage" />
        }
        } />

export const realTimeGanttPageMenuEntry = () => {
    return {
        id: "realTimeGanttPage",
        content: _msg("RealTimeGanttPage.title"),
        to: realTimeGanttPageUrl, exact: true, icon: "chart bar outline",
    }
};

const REAL_TIME_GANTT_PAGE_AUTO_REFRESH = 'realTimeGanttPage.autoRefresh';
const REAL_TIME_GANTT_PAGE_DATE_FOR_QUERY = 'realTimeGanttPage.dateForQuery';
