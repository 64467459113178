import { addEntityDescriptor, EntityDescriptor, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FoundationEntityDescriptors } from "@crispico/foundation-react/FoundationEntityDescriptors";
import { AddressEntityDescriptor } from "pages/Address/AddressEntityDescriptor";
import { QualificationEntityDescriptor } from "pages/QualificationEntityDescriptor";
import { Mission2EntityDescriptor } from "pages/Mission2/Mission2EntityDescriptor";
import { HumanResourceEntityDescriptor } from "pages/HumanResourceEntityDescriptor";
import { BaggageEntityDescriptor } from "pages/BaggageEntityDescriptor";
import { BlocklyScriptXopsEntityDescriptor } from "pages/blocklyScriptXops/BlocklyScriptXopsEntityDescriptor";
import { ChartEntityDescriptor } from "pages/Chart/chartEntityDescriptor";
import { FlightEntityDescriptor } from "pages/flight/FlightEntityDescriptor";
import { FlightAndObjectActionEntityDescriptor } from "pages/FlightAndObjectAction/FlightAndObjectActionEntityDescriptor";
import { GanttAssignmentEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentEntityDescriptor";
import { GanttAssignmentParamsEntityDescriptor } from "pages/ganttAssignment/GanttAssignmentParamsEntityDescriptor";
import { newTaskEntityDescriptor } from "pages/Task/TaskEntityDescriptor";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { BackgroundProcessEntityDescriptor } from "pages/BackgroundProcessEntityDescriptor";
import { defaultXopsColorEntityDescriptor } from "pages/XopsColor/XopsColorEntityDescriptor";
import { HumanResourceScheduleEntityDescriptor } from "pages/HumanResourceSchedule/HumanResourceScheduleEntityDescriptor";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { AlertEntityDescriptor } from "pages/Alert/AlertEntityDescriptor";
import { DiagnosticTroubleCodeEntityDescriptor, GoToPositionFieldDescriptor } from "pages/EquipmentResource/diagnosticTroubleCodeEntityDescriptor";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { MissionEvent2EntityDescriptor } from "pages/Mission2/MissionEvent2EntityDescriptor";
import { DbcContentFieldDescriptor } from "pages/EquipmentResource/dbcFieldDescriptors";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { AirlineEntityDescriptor } from "pages/Airline/AirlineEntityDescriptor";

// TODO by CS: I think they will disappear because of the new menu system
export let addressEntityDescriptor!: EntityDescriptor;
export let blocklyScriptEntityDescriptor!: EntityDescriptor;
export let chartEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentEntityDescriptor!: EntityDescriptor;
export let ganttAssignmentParamsEntityDescriptor!: EntityDescriptor;
export let humanResourceEntityDescriptor: EntityDescriptor;
export let qualificationEntityDescriptor: EntityDescriptor;
export let flightEntityDescriptor: EntityDescriptor;
export let missionEntityDescriptor: EntityDescriptor;
export let missionEvent2EntityDescriptor: EntityDescriptor;
export let taskEntityDescriptor: EntityDescriptor;
export let baggageEntityDescriptor: EntityDescriptor;
export let flightAndObjectActionEntityDescriptor: EntityDescriptor;
export let interdictionEntityDescriptor: EntityDescriptor;
export let cDGPortParkingEntityDescriptor: EntityDescriptor;
export let zoneDistanceEntityDescriptor: EntityDescriptor;
export let radioEntityDescriptor: EntityDescriptor;
export let inactivityTypeEntityDescriptor: EntityDescriptor;
export let xopsColorEntityDescriptor: EntityDescriptor;
export let humanResourceScheduleEntityDescriptor: EntityDescriptor;

export class AppEntityDescriptors extends FoundationEntityDescriptors {

    init() {
        super.init();

        // please keep sorted alphabetically
        addressEntityDescriptor = addEntityDescriptor(new AddressEntityDescriptor({ name: "Address", icon: "map marker" }))
            .addFieldDescriptor(new EntityToTagFieldDescriptor());
        addEntityDescriptor(new EntityDescriptor({ name: "AddressAirline", icon: "map marker" }));
        addEntityDescriptor(new AirlineEntityDescriptor());
        addEntityDescriptor(new EntityDescriptor({ name: "AirlineContact", icon: "address book" }));
        addEntityDescriptor(new AlertEntityDescriptor());
        blocklyScriptEntityDescriptor = addEntityDescriptor(new BlocklyScriptXopsEntityDescriptor({ name: "BlocklyScriptXops" }));
        addEntityDescriptor(new EntityDescriptor({ name: "EquipmentUsageLog", icon: "clock outline" }))
            .isInDefaultColumnConfig(false, "user");
        addEntityDescriptor(new EntityDescriptor({ name: "Comment", miniFields: ["text"] }));
        chartEntityDescriptor = addEntityDescriptor(new ChartEntityDescriptor());

        addEntityDescriptor(new BackgroundProcessEntityDescriptor({
            name: "BackgroundProcess", icon: "file alternate",
            defaultSort: [{ field: "creationDate", direction: "DESC" }],
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today)
        }));

        cDGPortParkingEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "CDGPortParking",
            miniFields: ["port", "parking"],
            defaultSort: [{ field: "port", direction: "ASC" }, { field: "parking", direction: "ASC" }]
        }));

        flightEntityDescriptor = addEntityDescriptor(new FlightEntityDescriptor({
            name: "Flight", icon: "plane",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" },
            miniFields: ["name", "origin", "destination"]
        }));


        flightAndObjectActionEntityDescriptor = addEntityDescriptor(new FlightAndObjectActionEntityDescriptor({
            name: "FlightAndObjectAction",
            icon: "plane"
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "FlightConnexion",
            icon: "plane"
        }).addFieldDescriptor({
            name: "incomingFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] }
        }).addFieldDescriptor({
            name: "outgoingFlight", type: "Flight", additionalFieldEditorProps: { innerEntityDescriptor: entityDescriptors["Flight"] }
        }));

        ganttAssignmentEntityDescriptor = addEntityDescriptor(new GanttAssignmentEntityDescriptor());

        ganttAssignmentParamsEntityDescriptor = addEntityDescriptor(new GanttAssignmentParamsEntityDescriptor());

        humanResourceEntityDescriptor = addEntityDescriptor(new HumanResourceEntityDescriptor({
            name: "HumanResource", icon: "male",
            miniFields: ["identifier", "firstName", "lastName"],
        }));

        humanResourceScheduleEntityDescriptor = addEntityDescriptor(new HumanResourceScheduleEntityDescriptor());

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceLog", icon: "male",
            miniFields: ["humanResource.identifier", "event", "date"],
            defaultSort: [{ field: "date", direction: "DESC" }],
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today)
        }).addFieldDescriptor({
            name: "event", type: FieldType.dropdown, fieldDescriptorSettings: {
                fieldIntervals: [
                    { from: "IN", to: "IN", label: _msg("HumanResourceLog.in").toUpperCase() },
                    { from: "OUT", to: "OUT", label: _msg("HumanResourceLog.out").toUpperCase() }]
            }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceShift", icon: "male",
            miniFields: ["name"],
            defaultFilter: Filter.createForClient("startTime", FilterOperators.forDate.today),
            defaultSort: { field: "startTime", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "HumanResourceMessage", icon: "male",
            miniFields: ["humanResource.identifier", "sentTime"],
            defaultSort: [{ field: "sentTime", direction: "DESC" }],
            defaultFilter: Filter.createForClient("sentTime", FilterOperators.forDate.today)
        }).addFieldDescriptor({ name: "message", type: FieldType.text }))
            .addFieldDescriptor({ name: "answer", type: FieldType.text })
            .isInDefaultColumnConfig(false, "xopsUser", "eventId");

        interdictionEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "Interdiction",
            miniFields: ["airline", "planeTypeObservations"],
            defaultSort: [{ field: "airline", direction: "ASC" }]
        })
            .addFieldDescriptor({
                name: "planeTypeObservations", type: FieldType.dropdown, fieldDescriptorSettings: {
                    fieldIntervals: [
                        { from: "lowWing", label: _msg("planeType.observations.lowWing") },
                        { from: "highWing", label: _msg("planeType.observations.highWing") },
                        { from: "family320", label: _msg("planeType.observations.family320") }]
                }
            })
        );

        missionEntityDescriptor = addEntityDescriptor(new Mission2EntityDescriptor({
            name: "Mission2", icon: "list", miniFields: ["id"],
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
            defaultSort: { field: "creationDate", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MissionType",
            miniFields: ["name"],
        }).addFieldDescriptor({ name: "color", type: FieldType.color }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MissionWorkflowElement",
            miniFields: ["id", "name"],
        }).isInDefaultColumnConfig(true, "id", "name", "type", "label"));

        missionEvent2EntityDescriptor = addEntityDescriptor(new MissionEvent2EntityDescriptor({
            name: "MissionEvent2",
            defaultSort: { field: "creationdate", direction: "DESC" }
        }));

        addEntityDescriptor(new EntityDescriptor({
            name: "MobileDeviceActivationHistory",
            icon: "history",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" }
        })).addFieldDescriptor({
            name: "event", type: FieldType.dropdown, fieldDescriptorSettings: {
                fieldIntervals: [
                    { from: "IN", to: "IN", label: _msg("HumanResourceLog.in").toUpperCase() },
                    { from: "OUT", to: "OUT", label: _msg("HumanResourceLog.out").toUpperCase() }]
            }
        })

        addEntityDescriptor(new EntityDescriptor({ name: "Puck", icon: "paperclip", miniFields: ["name", "territory.name"] }));
        qualificationEntityDescriptor = addEntityDescriptor(new QualificationEntityDescriptor({ name: "Qualification", icon: "check circle outline", miniFields: ["qualificationType.name"] }));

        addEntityDescriptor(new EntityDescriptor({ name: "QualificationType", icon: "check circle outline", miniFields: ["name", "description"] })
            .isInDefaultColumnConfig(true, "name", "description")
            .addFieldDescriptor({ name: "description", type: "text" }));

        addEntityDescriptor(new EntityDescriptor({ name: "PlaneType", icon: "plane" }));

        radioEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "Radio",
            miniFields: ["identifier"],
            defaultSort: [{ field: "identifier", direction: "ASC" }]
        }));

        baggageEntityDescriptor = addEntityDescriptor(new BaggageEntityDescriptor({
            name: "Baggage",
            miniFields: ["number"],
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            defaultSort: { field: "date", direction: "DESC" }
        }));

        taskEntityDescriptor = addEntityDescriptor(newTaskEntityDescriptor);

        xopsColorEntityDescriptor = addEntityDescriptor(defaultXopsColorEntityDescriptor);

        zoneDistanceEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "ZoneDistance",
            miniFields: ["zone1.name", "zone2.name"],
            defaultSort: [{ field: "zone1", direction: "ASC" }, { field: "zone2", direction: "ASC" }]
        }));

        inactivityTypeEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
            name: "InactivityType",
            miniFields: ["name", "duration"],
            defaultSort: [{ field: "name", direction: "ASC" }],
            icon: "clipboard list"
        })).addFieldDescriptor({ name: "color", type: FieldType.color, colorType: "number" });

        addEntityDescriptor(new DiagnosticTroubleCodeEntityDescriptor())
            .addFieldDescriptor({ name: "timestamp", type: FieldType.date,
                additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
                additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat })
            })
            .addFieldDescriptor({ name: "goToPosition" }, new GoToPositionFieldDescriptor());

        addEntityDescriptor(new EntityDescriptor({
            name: "Dbc",
            miniFields: ["name"],
            defaultSort: [{ field: "name", direction: "ASC" }]
        })).addFieldDescriptor({ name: "content", type: FieldType.text }, new DbcContentFieldDescriptor());

        return this;
    }
}
