import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, AppContainer, createSliceFoundation, getBaseImpures, getBaseReducers, Optional, Organization, PropsFrom, Utils } from "@crispico/foundation-react";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Dashboard } from "@crispico/foundation-react/pages/dashboard/DashboardEntityDescriptor";
import { DashboardTab, DashboardTabReducers, DashboardTabState } from "@crispico/foundation-react/pages/dashboard/dashboardTab/DashboardTab";
import { infoPersonalHomePage, PersonalHomePage, PersonalHomePageProps, slicePersonalHomePage } from "@crispico/foundation-react/pages/PersonalHomePage";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { loadOrganizationsForMap, loadOrganizationsForMapVariables } from "apollo-gen/loadOrganizationsForMap";
import { OrganizationForMap } from "apollo-gen/OrganizationForMap";
import { chartEntityDescriptor } from "AppEntityDescriptors";
import { MapContainerLeaflet, MapContainerLeafletRRC, MARKER_TYPE, MarkerData, SelectedLayer } from "components/MapContainerLeaflet/MapContainerLeaflet";
import gql from "graphql-tag";
import { LOAD_ORGANIZATIONS_FOR_MAP } from "graphql/queries";
import React from "react";
import { Button, Icon, Label, Popup, Segment } from "semantic-ui-react";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { airportEntityDescriptor, equipmentResourceEntityDescriptor } from "./EquipmentResource/equipmentResourceEntityDescriptor";

// it seems the `organizationEntityDescriptor.name = undefined` while displaying this page; as a workaround, I use this const for the ED name
const ORGANIZATION = "Organization";
export const sliceXopsPersonalHomePage = createSliceFoundation(class SliceXopsHomePage {
    initialState = {
        ...slicePersonalHomePage.initialState,
        organizations: [] as OrganizationForMap[],
        showMap: false as boolean
    }

    reducers = {
        ...slicePersonalHomePage.reducers, ...getBaseReducers<SliceXopsHomePage>(this),
    }

    impures = {
        ...slicePersonalHomePage.impures, ...getBaseImpures<SliceXopsHomePage>(this),

        async loadOrganizations(currentOrganization: Optional<Organization>) {
            this.getDispatchers().setInReduxState({ organizations: undefined });
          
            const params = currentOrganization ? FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.or, [
                Filter.create("qualifiedName", FilterOperators.forString.equals, currentOrganization.qualifiedName),
                Filter.create("qualifiedName", FilterOperators.forString.like, currentOrganization.qualifiedName + ".%")
            ])) : FindByFilterParams.create();

            const orgs: Optional<OrganizationForMap[]> = (await apolloClient.query<loadOrganizationsForMap, loadOrganizationsForMapVariables>({
                query: LOAD_ORGANIZATIONS_FOR_MAP,
                variables: params, context: { showSpinner: false }
            })).data.organizationService_findByFilter?.results as Optional<OrganizationForMap[]>;
            if (!orgs || orgs.length === 0) {
                return;
            }

            let displayedOrgs: OrganizationForMap[] = [];
            orgs.forEach((org: OrganizationForMap) => {
                if (org.airport && org.airport.longitude && org.airport.latitude) {
                    displayedOrgs.push(org);
                }
            });
            if (displayedOrgs.length > 0) {
                this.getDispatchers().setInReduxState({ 
                    organizations: displayedOrgs, 
                    showMap: new Set(displayedOrgs.map(x => x.airport?.id).filter(x => x)).size > 1 });               
            }
        },
    }
});
export type XopsPersonalHomePageProps = PropsFrom<typeof sliceXopsPersonalHomePage> & PersonalHomePageProps;
export class XopsPersonalHomePage extends PersonalHomePage<XopsPersonalHomePageProps> {

    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: XopsPersonalHomePageProps) {
        super(props);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this);
    }

    protected getClassName() {
        return this.props.showMap ? "XopsPersonalHomePage_with_map" : super.getClassName();
    }

    protected refreshOrganizations() {
        this.props.showMap && this.props.dispatchers.loadOrganizations(this.context.initializationsForClient.currentOrganization);
    }

    componentDidUpdate(prevProps: XopsPersonalHomePageProps) {
        if (prevProps.showMap !== this.props.showMap && this.props.showMap) {
            let data: MarkerData[] = [];
            this.props.organizations.forEach((org: OrganizationForMap) => {
                if (org.airport && org.airport.longitude && org.airport.latitude) {
                    data.push({ id: org.id, text: this.context.initializationsForClient.currentOrganization ? Utils.substringAfter(org.qualifiedName!, ".") : org.qualifiedName!, point: { longitude: org.airport.longitude, latitude: org.airport.latitude } });
                }
            });
            this.mapContainerRef.current!.clearMap();
            this.mapContainerRef.current!.addOrUpdateLayers(data, ORGANIZATION);
            this.mapContainerRef.current!.fitBounds(ORGANIZATION);
        }
    }

    renderMarkerIcon(markerData: MarkerData, type: string): React.ReactNode {
        return <Label horizontal className="flex-container-row flex-center" style={{ width: 'fit-content', backgroundColor: "white" }}><Icon name={airportEntityDescriptor.icon as any} />{markerData.text}</Label>;
    }

    protected onSelectLayer(layer: Optional<SelectedLayer>) {
        if (!layer) {
            return;
        }
        AppContainer.INSTANCE.setCurrentOrganization(this.context.organizations?.find(o => o.id === layer.id));
    }

    protected renderHeader() {
        return <>
            {super.renderHeader()}
            {this.props.showMap ? <div className="flex-container MapRealTime_topParent XopsPersonalHomePage_map" >
                <MapContainerLeafletRRC id={"mapContainerLeafletHomePage"} ref={this.mapContainerRef}
                    pruneClusterMode={false} renderMarkerIcon={this.renderMarkerIcon}
                    azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                    bingAPIKey={this.context.initializationsForClient.mapSettings.bingAPIKey} mapId={"XopsPersonalHomePage_map"}
                    layers={{ [ORGANIZATION]: { layerType: MARKER_TYPE, options: { hideStyleOnSelectedLayer: true, hideStyleOnHoveredLayer: true, hideTooltipOnHoveredLayer: true, flyToSelectedMarker: false } } }}
                    enableGestureHandling
                    onSelectLayer={layer => this.onSelectLayer(layer)}
                />
            </div> : <></>}
        </>;
    }

    protected renderDashboard(index: number, dashboard: Dashboard) {
        let result = super.renderDashboard(index, dashboard);
        if (result) {
            result = React.createElement(MyDashboardTabRRC, result.props);
        }
        return result;
    }

    protected renderMain() {
        return <AppContainerContext.Consumer>{context =>
            <>
                <Utils.Observer value={context.initializationsForClient.currentOrganization} didUpdate={() => this.refreshOrganizations()} />
                {super.renderMain()}
            </>
        }</AppContainerContext.Consumer>;
    }
}

infoPersonalHomePage.slice = sliceXopsPersonalHomePage;
infoPersonalHomePage.wrappedComponentClass = XopsPersonalHomePage;
class MyDashboardTab extends DashboardTab {

    protected tripSummaryId: number = 0;
    protected tripPerformanceId: number = 0;
    protected tripSummaryName: string = "";
    protected tripPerformanceName: string = "";

    componentDidMount(): void {
        super.componentDidMount();
    }

    async findTripReports() {
        const params = FindByFilterParams.create().filter(Filter.createComposed(FilterOperators.forComposedFilter.or, [
            Filter.create("type", FilterOperators.forString.equals, "tripSummary"),
            Filter.create("type", FilterOperators.forString.equals, "tripPerformance")
        ])).sorts([{ field: "id", direction: "DESC" }]);

        const charts = (await apolloClient.query({
            query: gql(`
                query findTripReports($params: FindByFilterParamsInput) { 
                    chartService_findByFilter(params: $params) {
                        results  { id type name } 
                    }
                }`),
            variables: params, context: { showSpinner: false }
        })).data.chartService_findByFilter?.results;
        
        if (!charts || charts.length === 0) {
            this.forceUpdate();
            return;
        }
        for (const chart of charts) {
            if (this.tripSummaryId == 0 && chart.type == "tripSummary") {
                this.tripSummaryId = chart.id;
                this.tripSummaryName = chart.name;
                if (this.tripPerformanceId != 0) {
                    break;
                }
            }
            if (this.tripPerformanceId == 0 && chart.type == "tripPerformance") {
                this.tripPerformanceId = chart.id;
                this.tripPerformanceName = chart.name;
                if (this.tripSummaryId != 0) {
                    break;
                }
            }
        }
        this.forceUpdate();
    }

    protected getReportButton(id: number, name: string, org: Organization) {
        if (id == 0) {
            return undefined;
        }
        return <Popup content = {_msg("Menu.report")} trigger={<Button
            onClick={() => {                
                AppMetaTempGlobals.history.push(chartEntityDescriptor.getEntityEditorUrl(id) + "/chart");
            }}>{name}</Button>}>
        </Popup>
    }

    protected renderOrganizationTitleBar(org: Organization) {       
        return <AppContainerContext.Consumer>
            {context =>
                <>
                    <Utils.Observer value={context.initializationsForClient.currentOrganization} didUpdate={() => this.findTripReports()} />
                    <div className="flex-container-row flex-center gap5 less-margin-top-bottom">
                        <Segment className="no-margin no-padding-top-bottom" style={{ backgroundColor: "#E0E1E2" }}>{super.renderOrganizationTitleBar(org)}</Segment>
                        <Button
                            onClick={() => {
                                AppMetaTempGlobals.history.push(equipmentResourceEntityDescriptor.getEntityTableUrl() + "/table");
                            }}>{_msg("tooltipComponent.fleet")}</Button>
                        <Button
                            onClick={() => {
                                AppMetaTempGlobals.history.push(equipmentResourceEntityDescriptor.getEntityTableUrl() + "/realTimeMap");
                            }}>{_msg("MapRealTime")}</Button>
                        {this.getReportButton(this.tripSummaryId, this.tripSummaryName, org)}
                        {this.getReportButton(this.tripPerformanceId, this.tripPerformanceName, org)}
                    </div>
                </>
            }</AppContainerContext.Consumer>;
    }

}

const MyDashboardTabRRC =  ReduxReusableComponents.connectRRC(DashboardTabState, DashboardTabReducers, MyDashboardTab);
