import { apolloClient, Utils } from "@crispico/foundation-react";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import { LOAD_COLORS } from "./queries";

export enum Color {
    ALERT_LEVEL_MEDIUM = 'ALERT_LEVEL_MEDIUM',
    ALERT_LEVEL_OLD = 'ALERT_LEVEL_OLD',
    ALERT_LEVEL_READ = 'ALERT_LEVEL_READ',
    ALERT_LEVEL_UNREAD = 'ALERT_LEVEL_UNREAD',
    ALERT_LEVEL_RECENT = 'ALERT_LEVEL_RECENT',
    ALERT_TYPE_ERROR = 'ALERT_TYPE_ERROR',
    FLIGHT_AND_OBJECT_ACTION_CHILD = 'FLIGHT_AND_OBJECT_ACTION_CHILD',
    FLIGHT_ARRIVAL = 'FLIGHT_ARRIVAL',
    FLIGHT_CANCELED = 'FLIGHT_CANCELED',
    FLIGHT_DELAYED = 'FLIGHT_DELAYED',
    FLIGHT_DELETED = 'FLIGHT_DELETED',
    FLIGHT_DEPARTURE = 'FLIGHT_DEPARTURE',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_A = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_A',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_C = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_C',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_D = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_D',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_E = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_E',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_H = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_H',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_I = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_I',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_M = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_M',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_N = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_N',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_P = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_P',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_Q = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_Q',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_R = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_R',
    FLIGHT_LAST_KNOWN_HOUR_COLOR_T = 'FLIGHT_LAST_KNOWN_HOUR_COLOR_T',
    FLIGHT_LIST_COLOR = 'FLIGHT_LIST_COLOR',
    FLIGHT_ON_HOLD = 'FLIGHT_ON_HOLD',
    FLIGHT_READY_TO_TREAT = 'FLIGHT_READY_TO_TREAT',
    FLIGHT_SCHEDULE_DATE = 'FLIGHT_SCHEDULE_DATE',
    FLIGHT_SPECIAL_CONDITION = 'FLIGHT_SPECIAL_CONDITION',
    FLIGHT_STATUS_FINISHED = 'FLIGHT_STATUS_FINISHED',
    FLIGHT_STATUS_NO_MISSION = 'FLIGHT_STATUS_NO_MISSION',
    FLIGHT_STATUS_STARTED = 'FLIGHT_STATUS_STARTED',
    HR_BORROWED_TO_UNIT = 'HR_BORROWED_TO_UNIT',
    MESSAGES_ALL_READ = 'MESSAGES_ALL_READ',
    MESSAGES_EXISTS_UNREAD = 'MESSAGES_EXISTS_UNREAD',
    MISSION_BORDER_FOR_MODIFIED_FLIGHT = 'MISSION_BORDER_FOR_MODIFIED_FLIGHT',
    MISSION_FROM_TEMPORARY_STORAGE = 'MISSION_FROM_TEMPORARY_STORAGE',
    MISSION_SENT_TO_UNIT = 'MISSION_SENT_TO_UNIT',
    MISSION_SPECIAL = 'MISSION_SPECIAL',
    MISSION_STATUS_CANCELED_BY_AIRLINE = 'MISSION_STATUS_CANCELED_BY_AIRLINE',
    MISSION_STATUS_DRAFT = 'MISSION_STATUS_DRAFT',
    MISSION_STATUS_EXITED_TRIEUR = 'MISSION_STATUS_EXITED_TRIEUR',
    MISSION_STATUS_FINISHED = 'MISSION_STATUS_FINISHED',
    MISSION_STATUS_FINISHED_BYUSER = 'MISSION_STATUS_FINISHED_BYUSER',
    MISSION_STATUS_IN_POSITION = 'MISSION_STATUS_IN_POSITION',
    MISSION_STATUS_INSIDE_TRIEUR = 'MISSION_STATUS_INSIDE_TRIEUR',
    MISSION_STATUS_NEW = 'MISSION_STATUS_NEW',
    MISSION_STATUS_STARTED = 'MISSION_STATUS_STARTED',
    MISSION_STATUS_START_LOADING = 'MISSION_STATUS_START_LOADING',
    MISSION_STATUS_START_PAUSE = 'MISSION_STATUS_START_PAUSE',
    MISSION_STATUS_STOP_LOADING = 'MISSION_STATUS_STOP_LOADING',
    MISSION_STATUS_TRANSFERED = 'MISSION_STATUS_TRANSFERED',
    MISSION_STATUS_VIEWED = 'MISSION_STATUS_VIEWED',
    MISSION_STATUS_PLANNED_NOT_DONE = "MISSION_STATUS_PLANNED_NOT_DONE",
    MISSION_TO_TEMPORARY_STORAGE = 'MISSION_TO_TEMPORARY_STORAGE',
    MOBILE_DEVICE_APP_STARTED = 'MOBILE_DEVICE_APP_STARTED',
    MOBILE_DEVICE_OFFLINE = 'MOBILE_DEVICE_OFFLINE',
    MOBILE_DEVICE_ONLINE = 'MOBILE_DEVICE_ONLINE',
    MOBILE_DEVICE_TOTAL = 'MOBILE_DEVICE_TOTAL',
    NO_MISSION = 'NO_MISSION',
    OBJECT_SPECIAL_COLOR = 'OBJECT_SPECIAL_COLOR',
    OTHER_ACTIVITY_STATUS_DRAFT = 'OTHER_ACTIVITY_STATUS_DRAFT',
    OTHER_ACTIVITY_STATUS_FINISHED_BY_USER = 'OTHER_ACTIVITY_STATUS_FINISHED_BY_USER',
    OTHER_ACTIVITY_STATUS_NEW = 'OTHER_ACTIVITY_STATUS_NEW',
    OTHER_ACTIVITY_STATUS_VIEWED = 'OTHER_ACTIVITY_STATUS_VIEWED',
    OTHER_ACTIVITY_STATUS_FINISHED = 'OTHER_ACTIVITY_STATUS_FINISHED',
    OTHER_ACTIVITY_STATUS_STARTED = 'OTHER_ACTIVITY_STATUS_STARTED',
    SCHEDULE_FINISHED = 'SCHEDULE_FINISHED',
    SCHEDULE_MISSION_EXITED_TRIEUR = 'SCHEDULE_MISSION_EXITED_TRIEUR',
    SCHEDULE_MISSION_INSIDE_TRIEUR = 'SCHEDULE_MISSION_INSIDE_TRIEUR',
    SCHEDULE_MISSION_NEW = 'SCHEDULE_MISSION_NEW',
    SCHEDULE_MISSION_VIEWED = 'SCHEDULE_MISSION_VIEWED',
    SCHEDULE_NO_EQUIPMENT = 'SCHEDULE_NO_EQUIPMENT',
    SCHEDULE_WITH_EQUIPMENT = 'SCHEDULE_WITH_EQUIPMENT',
    SCHEDULE_NO_MISSION_VIEWED = 'SCHEDULE_NO_MISSION_VIEWED',
    STOP_BORDER = 'STOP_BORDER',
    STOP_STATUS_FINISHED = 'STOP_STATUS_FINISHED',
    STOP_STATUS_NEW = 'STOP_STATUS_NEW',
    STOP_STATUS_STARTED = 'STOP_STATUS_STARTED'
}

export class XopsColorRegistryState extends State {
    data: { [key: string]: number } = {};
}

export class XopsColorRegistryReducers<S extends XopsColorRegistryState = XopsColorRegistryState> extends Reducers<S> {
}

export class XopsColorRegistry extends React.Component<RRCProps<XopsColorRegistryState, XopsColorRegistryReducers>> {
    static INSTANCE: XopsColorRegistry;

    async init() {
        let data = {} as any;

        (await apolloClient.query({
            query: LOAD_COLORS,
            variables: FindByFilterParams.create().pageSize(-1),
            context: { showSpinner: false }
        })).data["xopsColorService_findByFilter"]["results"].map((color: any) => {
            data[color.name] = color.colorValue;
        });

        this.props.r.setInReduxState({ data });
    }

    get(name: string): number {
        return this.props.s.data[name];
    }

    getColor(name: string): string {
        return Utils.convertColorToHex(this.get(name));
    }

    render() {
        // this is a non visual component
        return null;
    }
}

export const XopsColorRegistryRRC = ReduxReusableComponents.connectRRC(XopsColorRegistryState, XopsColorRegistryReducers, XopsColorRegistry);