import { Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { AssociationEditorProps, AssociationExtraProps, AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps, fieldEditors } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FindByStringParams } from "@crispico/foundation-react/entity_crud/FindByStringParams";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import moment from "moment";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";

export class FlightFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps> {
    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    protected createFindByStringParams(searchQuery: string): FindByStringParams {
        const p = super.createFindByStringParams(searchQuery);
        const now = Utils.now();
        const findByStringFlightDateOffsetHours = this.context.initializationsForClient.flightSettings.findByStringFlightDateOffsetHours;
        const additionalFilter = Filter.create("date", FilterOperators.forDate.between, moment(now).subtract(findByStringFlightDateOffsetHours, "hours").toISOString() + Filter.AND_DELIMITER + moment(now).add(findByStringFlightDateOffsetHours, "hours").toISOString());
        let { enabled, label, ...composedFilter } = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, Utils.isNullOrEmpty(p.params.filter) ? [ additionalFilter ] : [ p.params.filter as Filter, additionalFilter ]);
        p.params.filter = composedFilter;
        return p;
    }
}

fieldEditors["Flight"] = FlightFieldEditor;