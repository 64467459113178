import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import moment from "moment";
import React from "react";
import { GanttResources, GanttResourcesOptions, GanttResourcesRRC } from "./GanttResources";
import { GanttTasks, GanttTasksMode, GanttTasksOptions, GanttTasksRRC } from "./GanttTasks";
import { DragAndDropArea } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import _ from "lodash";

export interface GanttDoubleOptions {
    ganttTasksOptions: GanttTasksOptions,
    ganttResourcesOptions: GanttResourcesOptions,
}

class GanttDoubleState extends State {
    hideResources: { [key: string]: number[] } = {};
}

export enum GanttDoubleDisplayMode { FLEX_OBJECT_GANTT = "FLEX_OBJECT_GANTT", DEFAULT = "DEFAULT" }

class GanttDoublePageReducers<S extends GanttDoubleState = GanttDoubleState> extends Reducers<S> {

}

type Props = RRCProps<GanttDoubleState, GanttDoublePageReducers> & {
    startDate: string,
    endDate: string,
    portalContainerForTopBar?: any,
    displayMode: GanttDoubleDisplayMode,
    ganttDoubleOptions?: GanttDoubleOptions,
};

export class GanttDoublePage extends React.Component<Props, { entitiesVersion: number, entities: { [entityName: string]: { [id: number]: any } } }> {

    protected ganttResourcesRef = React.createRef<GanttResources>();
    protected ganttTasksRef = React.createRef<GanttTasks>();

    constructor(props: Props) {
        super(props);

        this.state = { entities: {}, entitiesVersion: 0 };
    }

    getGanttResourcesRef() {
        return this.ganttResourcesRef.current;
    }

    getGanttTasksRef() {
        return this.ganttTasksRef.current;
    }

    updateEntities(newEntities: { [entityName: string]: { [id: number]: any } }) {
        this.setState({ entities: newEntities, entitiesVersion: this.state.entitiesVersion + 1 });
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    protected async componentDidUpdateInternal(prevProps?: Props) {
        if (!prevProps || prevProps.displayMode != this.props.displayMode) {
            if (this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT) {
                this.ganttTasksRef.current?.props.r.setInReduxState({ mode: this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? GanttTasksMode.TASKS : GanttTasksMode.FLIGHT_TASKS });
            }
        }
        if (!prevProps || prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            const start = moment(this.props.startDate).valueOf();
            const end = moment(this.props.endDate).valueOf();
            this.ganttTasksRef.current?.props.r.setInReduxState({ start: start, end: end });
            this.ganttResourcesRef.current?.props.r.setInReduxState({ start: start, end: end });
        }
        if (this.props.ganttDoubleOptions && !_.isEqual(prevProps?.ganttDoubleOptions?.ganttTasksOptions, this.props.ganttDoubleOptions.ganttTasksOptions)) {
            this.ganttTasksRef.current?.props.r.setInReduxState({
                mode: this.props.ganttDoubleOptions.ganttTasksOptions.displayMode,
                hideAssignedTasks: this.props.ganttDoubleOptions.ganttTasksOptions.hideAssignedTasks,
                tableFields: this.props.ganttDoubleOptions.ganttTasksOptions.tableFields
            });
        }
        if (this.props.ganttDoubleOptions && !_.isEqual(prevProps?.ganttDoubleOptions?.ganttResourcesOptions, this.props.ganttDoubleOptions.ganttResourcesOptions)) {
            this.ganttResourcesRef.current?.props.r.setInReduxState({
                selectedGroupBy: this.props.ganttDoubleOptions.ganttResourcesOptions.groupBy,
                selectedSortBy: this.props.ganttDoubleOptions.ganttResourcesOptions.sortBy,
                tableFields: this.props.ganttDoubleOptions.ganttResourcesOptions.tableFields,
                showMessageButton: this.props.ganttDoubleOptions.ganttResourcesOptions.showMessageButton
            });
        }
    }

    render() {
        return <DragAndDropArea>
            <SplitPaneExt defaultSize={this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? "100%" : "50%"}>
                <GanttTasksRRC id="GanttDoublePage_GanttTasks" ref={this.ganttTasksRef}
                    portalContainerForTopBar={this.props.portalContainerForTopBar} showStatusFilter={true}
                    entities={this.state.entities} entitiesVersion={this.state.entitiesVersion} hideResources={this.props.s.hideResources} hideTopBar />
                {this.props.displayMode === GanttDoubleDisplayMode.FLEX_OBJECT_GANTT ? <></> :
                    <GanttResourcesRRC id="GanttDoublePage_GanttResources" ref={this.ganttResourcesRef}
                        portalContainerForTopBar={this.props.portalContainerForTopBar}
                        hideGroupBy={this.props.ganttDoubleOptions?.ganttResourcesOptions.hideGroupBy}
                        entities={this.state.entities} entitiesVersion={this.state.entitiesVersion} hideResources={this.props.s.hideResources} hideTopBar />}
            </SplitPaneExt>
        </DragAndDropArea >;
    }
}

export const GanttDoublePageRRC = ReduxReusableComponents.connectRRC(GanttDoubleState, GanttDoublePageReducers, GanttDoublePage);
