import { createSliceFoundation, DummyToRememberPeopleToCast, EntityDescriptor, EntityEditorPage, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, PropsFrom, SliceEntityEditorPage } from "@crispico/foundation-react";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { FormikProps } from "formik";
import { ReactNode } from "react";
import { Form } from "semantic-ui-react";
import { XopsColorRegistry } from "./XopsColorRegistry";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

const sliceXopsColorEntityEditorPage = createSliceFoundation(class SliceXopsColorEntityEditorPage extends SliceEntityEditorPage {
});

class XopsColorEntityEditorPage extends EntityEditorPage<PropsFrom<typeof sliceXopsColorEntityEditorPage>> {
    protected async onSave() {
        XopsColorRegistry.INSTANCE.init();

        super.onSave();
    }
}

class XopsColorTablePage extends EntityTablePage<EntityTablePageProps> {
    static defaultProps = {
        itemsHidedFromCell: ["deleteAllFilteredRows", "add"],
        showDtoCrudButtons: true,
        showImportButton: true,
        showExportButton: true
    };
}

const XopsColorTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, XopsColorTablePage);

export class XopsColorEntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.infoEditor.slice = sliceXopsColorEntityEditorPage.setEntityDescriptor(this);
        this.infoEditor.wrappedComponentClass = XopsColorEntityEditorPage;
    }

    renderTable() {
        return <XopsColorTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} itemsHidedFromCell={["add"]} />;
    }
}

export class DescriptionFieldDescriptor extends FieldDescriptor {
    protected hasDescription(description: string, name: string) {
        return description === null && _msg("color.description." + name) !== "color.description." + name;
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        if (this.hasDescription(entity.description, entity.name)) {
            return <span>{_msg("color.description." + entity.name)}</span>
        }
        return <span>{entity.description}</span>;
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <Form.Input onChange={((e, data) => formikProps.setFieldValue("description", data.value))}
            value={this.hasDescription(formikProps.values["description"], formikProps.values["name"]) ? _msg("color.description." + formikProps.values["name"]) : formikProps.values["description"]} />;
    }
}

export const defaultXopsColorEntityDescriptor = new XopsColorEntityDescriptor({
    name: "XopsColor",
    icon: "paint brush",
    hasDuplicateButton: false,
})
    .isInDefaultColumnConfig(true, "name", "description", "colorValue")
    .addFieldDescriptor({ name: "colorValue", type: FieldType.color, colorType: "number" })
    .addFieldDescriptor({ name: "description" }, new DescriptionFieldDescriptor());
