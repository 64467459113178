import React from "react";
import moment from "moment";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig } from "../ChartTab";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { HistogramWithDetailsRRC, HistogramPoint, HistogramSerie } from "@crispico/foundation-react/components/histogramWithDetails/HistogramWithDetails";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";
import { Utils } from "@crispico/foundation-react";


export class HistogramCountInTerritoriesTabState extends ChartTabState {
    histogramData = [] as Array<HistogramSerie>;
    initialStartDate = 0 as number;
    initialEndDate = 0 as number;
}

export class HistogramCountInTerritoriesTabReducers<S extends HistogramCountInTerritoriesTabState = HistogramCountInTerritoriesTabState> extends ChartTabReducers<S> {

    private convertEntitiesToMiniString(entities: any[]): string[] {
        return entities.map(entity => entityDescriptors["EquipmentResource"].toMiniString(entity));
    }

    private convertEntitiesToIds(entities: any[]): string[] {
        return entities.map(entity => entity.id.toString());
    }

    prepareData(p: { savedData: string, config: ChartConfig }) {
        this.s.initialStartDate = moment(p.config.startDate).toDate().getTime();
        this.s.initialEndDate = moment(p.config.endDate).toDate().getTime();

        let result = JSON.parse(p.savedData) as Array<{ territory: any, entries: Array<{ date: string, total: Array<any>, exited: Array<any>, entered: Array<any> }> }>;

        const histogramData: Array<HistogramSerie> = [];
        let id = 0;
        result.forEach(h => {
            const points: Array<HistogramPoint> = []
            let index = 0;
            h.entries.forEach(e => {
                points.push({
                    id: id++,
                    index: index++,
                    x: moment(e.date).valueOf(),
                    y: e.total.length,
                    total: this.convertEntitiesToMiniString(e.total),
                    out: this.convertEntitiesToMiniString(e.exited),
                    in: this.convertEntitiesToMiniString(e.entered),
                    total_ids: this.convertEntitiesToIds(e.total),
                    out_ids: this.convertEntitiesToIds(e.exited),
                    in_ids: this.convertEntitiesToIds(e.entered)
                })
            })
            histogramData.push({ id: h.territory.id, color: h.territory.color, territoryName: h.territory.name, data: points });
        })
        this.s.histogramData = histogramData;
    }

}

type Props = RRCProps<HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers> & ChartTabProps;
type LocalState = {}
export class HistogramCountInTerritoriesTab extends ChartTab<Props, LocalState> {

    protected renderHistogramPoints(point: HistogramPoint, rowKey: string, rowColor: string): (React.ReactNode | string)[] {
        const rowIds: string[] = point[rowKey + "_ids"];
        if (Utils.isNullOrEmpty(rowIds)) {
            return point[rowKey];
        }
        return rowIds.map((id, index) => <NavLink to={entityDescriptors["EquipmentResource"].getEntityEditorUrl(id)}>{point[rowKey][index]}</NavLink>);
    }

    protected renderHistogramSerieLabel(serie: HistogramSerie): React.ReactNode | string {
        return <NavLink to={entityDescriptors["Territory"].getEntityEditorUrl(serie.id)}>{serie.territoryName}</NavLink>
    }

    render() {
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            {this.renderTopBar(moment(this.props.s.initialStartDate), moment(this.props.s.initialEndDate))}
            <HistogramWithDetailsRRC id="histogramWithDetails" data={this.props.s.histogramData}
                startDate={this.props.s.initialStartDate} endDate={this.props.s.initialEndDate} hideExportButton={true}
                legendY={entityDescriptors["EquipmentResource"].getLabel(true)} gridSerieLabel={entityDescriptors["Territory"].getLabel()}
                renderHistogramPointRows={(point, rowKey, rowColor) => this.renderHistogramPoints(point, rowKey, rowColor)}
                renderHistogramSerieLabel={(serie) => this.renderHistogramSerieLabel(serie)}
            />
        </div>;
    }
}

export const HistogramCountInTerritoriesTabRRC = ReduxReusableComponents.connectRRC(HistogramCountInTerritoriesTabState, HistogramCountInTerritoriesTabReducers, HistogramCountInTerritoriesTab);
