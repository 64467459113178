import { FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Grid } from "semantic-ui-react";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { AssociationFieldRenderer } from "@crispico/foundation-react/entity_crud/fieldRenderers/AssociationFieldRenderer";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import NumberFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/NumberFieldRenderer";
import { WidgetProps } from "@crispico/foundation-react/pages/dashboard/dashboardTab/WidgetWrapper";
export class VehicleInformationWidget extends React.Component<WidgetProps> {

    private renderRow(value: any, message: string, icon?: ReactNode) {
        return <Grid.Row><div className="VehicleInformationWidget_GridRow">
            <div className="VehicleInformationWidget_Icon">
                {icon}
            </div>
            <div className="VehicleInformationWidet_Value">
                <div>{message}</div>
                <div>{value}</div>
            </div>
        </div></Grid.Row>
    }

    render() {       
        const odometerFd = equipmentResourceEntityDescriptor.getField('odometer');        
        const closestAddressFd = equipmentResourceEntityDescriptor.getField('closestAddress');        
        const engineHoursFd = equipmentResourceEntityDescriptor.getField('engineHours');
        const updatedFd = equipmentResourceEntityDescriptor.getField('updated');
        const { entityForAttachedDashboard } = this.props; 
        try {
            return <div className="flex-container-row flex-center flex-grow-shrink-no-overflow"><Grid centered container columns="2" padded >
                <Grid.Column>                   
                    {this.renderRow(odometerFd.renderField(entityForAttachedDashboard, FieldDescriptor.castAdditionalFieldRendererProps(NumberFieldRenderer, { showMeasurementUnit: true })), odometerFd.getLabel(false, false), odometerFd.getIcon())}
                    {this.renderRow(engineHoursFd.renderField(entityForAttachedDashboard, FieldDescriptor.castAdditionalFieldRendererProps(NumberFieldRenderer, { showMeasurementUnit: true })), engineHoursFd.getLabel(false, false), engineHoursFd.getIcon())}
                </Grid.Column>
                <Grid.Column> 
                    {this.renderRow(updatedFd.renderField(entityForAttachedDashboard, FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { showMeasurementUnit: true })), updatedFd.getLabel(false, false), updatedFd.getIcon())}               
                    {this.renderRow(closestAddressFd.renderField(entityForAttachedDashboard, FieldDescriptor.castAdditionalFieldRendererProps(AssociationFieldRenderer, { showMeasurementUnit: true })), closestAddressFd.getLabel(false, false), closestAddressFd.getIcon())}
                </Grid.Column>
            </Grid></div>
        } catch (e) {
            return <>{_msg("general.error")}</>
        }
    }
}
