import { EntityDescriptor, EntityEditorPage, EntityEditorPageProps, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { humanResourceScheduleTeamsPageTab } from "./HumanResourceSchedule/HumanResourceScheduleEntityDescriptor";
import { EntityToTagFieldDescriptor } from "@crispico/foundation-react/pages/EntityToTag/entityToTagDescriptor";
import { GanttMessagesFieldDescriptor } from "./CommonFieldDescriptors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

const FIELDS_FROM_DATA: string[] = ["correspondingAstusName"];

class HumanResourceTablePage extends EntityTablePage<EntityTablePageProps> {

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        let extraTabPanes = [
            humanResourceScheduleTeamsPageTab,
            ...super.getExtraTabPanes()
        ];
        return extraTabPanes;
    }

}

const HumanResourceTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, HumanResourceTablePage);

export class HumanResourceEntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.isInDefaultColumnConfig(true, "unit", "organization", "identifier", "firstName", "lastName", "rfidIdentifier", "available", "vehicle",
            "lastEquipmentResourceAssignmentViaRfid", "pdaIdentifier", "todayStartTime", "todayEndTime", "comment", "mobileDevice")
            .addFieldDescriptor(new EntityToTagFieldDescriptor())
            .addFieldDescriptor(new GanttMessagesFieldDescriptor())
            .addFieldDescriptor({ name: "qualifications", type: FieldType.oneToMany("Qualification"), oneToManyOppositeField: "humanResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })
            .addFieldDescriptor({ name: "missions", type: FieldType.oneToMany("Mission2"), oneToManyOppositeField: "humanResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })

        this.doForFields(FIELDS_FROM_DATA, fd => fd.filterable = false);
        this.doForFields(FIELDS_FROM_DATA, fd => fd.sortable = false);

        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [
                    ...super.getExtraTabPanes(),
                    this.createOneToManyTabPane("Qualification", "humanResource"),
                    this.createOneToManyTabPane("EquipmentUsageLog", "driver", { filter: Filter.createForClient("creationDate", FilterOperators.forDate.today), sort: { field: "creationDate", direction: "ASC" } }),
                    this.createOneToManyTabPane("HumanResourceSchedule", "humanResource", { filter: Filter.createForClient("startTime", FilterOperators.forDate.lessThanDaysAgo, "7"), sort: { field: "startTime", direction: "ASC" } }),
                    this.createOneToManyTabPane("HumanResourceLog", "humanResource"),
                    this.createOneToManyTabPane("Mission2", "humanResource"),
                    this.createOneToManyTabPane("MobileDeviceActivationHistory", "humanResource"),
                    this.createOneToManyTabPane("Interdiction", "humanResource")
                ];
            }
        }
    }

    renderTable() {
        return <HumanResourceTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />
    }

}