import gql from "graphql-tag";

export const TERRITORY_COORDINATES_FOR_MAP = gql`
    fragment TerritoryCoordinates on Pair_Double_Double {
        a b
    } 
`;

export const TERRITORY_FOR_MAP = gql`
    fragment TerritoryForMap on Territory {
        id version name color coordinates { ...TerritoryCoordinates}
    }
    ${TERRITORY_COORDINATES_FOR_MAP}
`;

export const LOAD_TERRITORIES_FOR_MAP = gql`
    query loadTerritoriesForMap($params: FindByFilterParamsInput) { 
        territoryService_findByFilter(params: $params) {
            results  { ...TerritoryForMap } 
        }
    }
    ${TERRITORY_FOR_MAP}
`;

export const SAVE_TERRITORIES_FOR_MAP = gql`
    mutation saveTerritoriesForMap($params: [SaveParams_LongInput]) { 
        territoryService_saveAll(params: $params) { id }
    }
`;
