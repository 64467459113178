import React from "react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { options, optionsForDate } from "../GanttInfoRenderer";

export class MissionEventInfoRenderer extends React.Component < { entity: any } > {
    render() {
        const { entity } = this.props;
        const ed = entityDescriptors["MissionEvent2"];
        return < div className="flex-container gap5">
            <div className="flex-container-row flex-center flex-wrap gap3">
                {ed.getField("creationdate").renderField(entity, optionsForDate)}
                {ed.getField("generationSource").renderField(entity, options)}
                {ed.getField("stopInfo").renderField(entity, options)}
                {ed.getField("flight").renderField(entity, options)}
                {ed.getField("objectActionInfo").renderField(entity, options)}
                {ed.getField("descriptorType").renderField(entity, options)}
            </div>
        </div>
    }
}

export class MissionEventsInfoRenderer extends React.Component<{ events: any[] }> {

    render() {
        return <> {
            this.props.events.sort((e1, e2) => {
                if (e1.creationdate == e2.creationdate) {
                    return e1.id - e2.id;
                }
                return e1.creationdate - e2.creationdate;
            }).filter(e => !e.hidden && !e.deleted).map(event => {
                return <MissionEventInfoRenderer entity={event}/>;
            })
        } </>;
    }
}
