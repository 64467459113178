import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { Icon, Popup } from "semantic-ui-react";

export class FlightPlaneIdentifierFieldRender extends StringFieldRenderer<FieldRendererProps> {

    private renderTooltip() {
        const { entity } = this.props;
        return <>
            {entity.lastModifiedPlaneIdentifier}
            {entity.modifiedPlaneIdentifier && <Icon name="arrow right" />}
            {entity.planeIdentifier}
        </>
    }

    protected getContent() {
        const content = super.getContent();
        if (this.props.entity["modifiedPlaneIdentifier"]) {
            return <div className="flex-container-row">
                <Popup trigger={<Icon name="plane" color="red" />} className="no-margin-padding">
                    {this.renderTooltip()}
                </Popup>
                {content}
            </div>
        }
        return content;
    }
}
