import gql from "graphql-tag";

export const ADDRESS_FOR_MAP = gql`
    fragment AddressForMap on Address {
        id version name latitude longitude
    }   
`;

export const LOAD_ADDRESSES_FOR_MAP = gql`
    query loadAddressesForMap($params: FindByFilterParamsInput) { 
        addressService_findByFilter(params: $params) {
            results  { ...AddressForMap } 
        }
    }
    ${ADDRESS_FOR_MAP}
`;

export const SAVE_ADDRESSES_FOR_MAP = gql`
    mutation saveAddressesForMap($params: SaveParams_LongInput) { 
        addressService_save(params: $params) { id }
    }
`;


export const DELETE_ADDRESS_FROM_MAP = gql`
    mutation deleteAddressFromMap($id: Long) { 
        addressService_deleteById(id: $id)
    }
`;
