import { addEntityDescriptor, EntityDescriptor, EntityEditorPage, EntityEditorPageProps, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { RealTimeMapRRC } from "components/realTimeMap/RealTimeMap";
import { uniqueId } from "lodash";
import { equipmentResourceEntityDescriptor, MOBILE_DEVICE_TYPE } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

export class MobileDeviceEntityDescriptor extends EntityDescriptor {

    constructor() {
        super({
            name: "MobileDevice",
            defaultSort: { field: "lastUpdate", direction: "DESC" },
            miniFields: ["identifier"],
            icon: "mobile alternate",
        });
    }

    renderTable() {
        return <MobileDeviceTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }

    protected customize() {
        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [
                    ...super.getExtraTabPanes(),
                    this.createOneToManyTabPane("MobileDeviceActivationHistory", "mobileDevice", { entityField: "id" }),
                    this.createOneToManyTabPane("Position", "plateNumber", { entityField: "identifier" })
                ];
            }
        }
    }

}

export const mobileDeviceEntityDescriptor = addEntityDescriptor(new MobileDeviceEntityDescriptor())
    .addFieldDescriptor({ name: "status", icon: "signal", type: FieldType.dropdown })
    .addFieldDescriptor({ name: "updated", type: FieldType.date, icon: "database", additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
    .addFieldDescriptor({ name: "lastUpdate", type: FieldType.date, icon: "feed", additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })

class MobileDeviceTablePage extends EntityTablePage<EntityTablePageProps> {

    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        const that = this;
        let extraTabPanes = [...super.getExtraTabPanes(), {
            routeProps: { path: "/realTimeMap" },
            menuItemProps: { icon: "map outline", content: _msg("MapRealTime") },
            render: () => (<>
                {that.renderRealTimeMap(false, true, Filter.createForClient('equipmentType.name', FilterOperators.forString.equals, MOBILE_DEVICE_TYPE))}
            </>)
        }];
        return extraTabPanes;
    }

    protected renderRealTimeMap(showGoToTableButton: boolean = true, withCustomQueryBar: boolean = true, rootFilter: Filter | undefined) {
        const id = uniqueId("realTimeMap-tab-" + mobileDeviceEntityDescriptor.name);
        return <RealTimeMapRRC id={id}
            rootFilter={rootFilter} showCustomQueryBar={withCustomQueryBar} showGoToTableButton={showGoToTableButton} mapId={id} entityTypes={[equipmentResourceEntityDescriptor.name]} />
    }

}

const MobileDeviceTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, MobileDeviceTablePage);
