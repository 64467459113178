import React from 'react'
import { Button, Input, Popup } from 'semantic-ui-react';
import { RRCProps, Reducers, ReduxReusableComponents, State } from '@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents';
import DropdownFieldEditor from '@crispico/foundation-react/entity_crud/fieldEditors/DropdownFieldEditor';
import { Formik } from 'formik';
import { SelectExt } from '@crispico/foundation-react/components/selectExt/SelectExt';
import { flightEntityDescriptor } from 'AppEntityDescriptors';
import { Filter } from '@crispico/foundation-react/components/CustomQuery/Filter';
import { FilterOperators } from '@crispico/foundation-gwt-js';
import { Optional, Utils } from '@crispico/foundation-react';
import _ from 'lodash';

export type FlightFilter = {
    departureValue: boolean | undefined;
    flightTypeValue: string | undefined;
    lastKnownDateTypeValue: string | undefined;
    nameOrPlaneIdentifierValue: string | undefined;

}
class FlightFilterComponentState extends State {
    filter: FlightFilter = {
        departureValue: undefined,
        flightTypeValue: undefined,
        lastKnownDateTypeValue: undefined,
        nameOrPlaneIdentifierValue: undefined
    }
}

class FlightFilterComponentReducers<S extends FlightFilterComponentState = FlightFilterComponentState> extends Reducers<S> { }

type FlightFilterComponentProps = RRCProps<FlightFilterComponentState, FlightFilterComponentReducers> & {
    showDepartureFilter: boolean,
    showFlightTypeFilter: boolean,
    showLastKnownDateTypeFilter: boolean,
    onFilterChange(filter: FlightFilter): void,
    defaultFilter?: FlightFilter
}

const departure = [
    { value: "arrival", label: _msg("Flight.arrival.label") },
    { value: "departure", label: _msg("Flight.departure.label") }
]

export default class FlightFilterComponent extends React.Component<FlightFilterComponentProps> {

    static createFilter(filter: Optional<FlightFilter>) {
        let newFilter = Filter.createComposed(FilterOperators.forComposedFilter.and, []);
        if (!filter) {
            return newFilter;
        }
        !Utils.isNullOrEmpty(filter.departureValue) && newFilter.filters!.push(Filter.create("departure", FilterOperators.forBoolean.equals, filter.departureValue!.toString()));
        !Utils.isNullOrEmpty(filter.flightTypeValue) && newFilter.filters!.push(Filter.create("flightType", FilterOperators.forString.equals, filter.flightTypeValue));
        !Utils.isNullOrEmpty(filter.lastKnownDateTypeValue) && newFilter.filters!.push(Filter.create("lastKnownDateType", FilterOperators.forString.equals, filter.lastKnownDateTypeValue));
        !Utils.isNullOrEmpty(filter.nameOrPlaneIdentifierValue) && newFilter.filters!.push(Filter.createComposed(FilterOperators.forComposedFilter.or, [
            Filter.create("name", FilterOperators.forString.contains, filter.nameOrPlaneIdentifierValue),
            Filter.create("parking.name", FilterOperators.forString.contains, filter.nameOrPlaneIdentifierValue),
            Filter.create("planeIdentifier", FilterOperators.forString.contains, filter.nameOrPlaneIdentifierValue),
        ]));
        return newFilter;
    }

    static filterMatchesFlight(flight: any, filter: FlightFilter) {
        if (!flight) {
            return false;
        }
        if (!Utils.isNullOrEmpty(filter.lastKnownDateTypeValue) && filter.lastKnownDateTypeValue !== flight.lastKnownDateType) {
            return false;
        }
        if (!Utils.isNullOrEmpty(filter.flightTypeValue) && filter.flightTypeValue !== flight.flightType) {
            return false;
        }
        let result = true;
        if (!Utils.isNullOrEmpty(filter.nameOrPlaneIdentifierValue)) {
            result = ((flight.name && flight.name.toUpperCase().search(filter.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1)
                || (flight.planeIdentifier && flight.planeIdentifier.toUpperCase().search(filter.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1)
                || (flight.parking?.name && flight.parking.name.toUpperCase().search(filter.nameOrPlaneIdentifierValue!.toUpperCase()) !== -1));
        }
        if (!Utils.isNullOrEmpty(filter.departureValue)) {
            result = result && filter.departureValue === flight.departure;
        }
        return result;
    }

    private searchInputRef = React.createRef<HTMLInputElement>();
    private lastKnowDateTypeFormikRef = React.createRef<any>();
    private flightTypeFormikRef = React.createRef<any>();

    constructor(props: FlightFilterComponentProps) {
        super(props);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    }

    componentDidMount(): void {
        this.props.defaultFilter && this.props.r.setInReduxState({ filter: this.props.defaultFilter });
    }

    componentDidUpdate(prevProps: FlightFilterComponentProps) {
        if (!prevProps || !_.isEqual(this.props.s.filter, prevProps.s.filter)) {
            this.props.onFilterChange(this.props.s.filter);
        }
    }

    private onSearchKeyDown(e: any) {
        if (e.key === "Enter") {
            this.props.r.setInReduxState({
                filter: {
                    ...this.props.s.filter,
                    nameOrPlaneIdentifierValue: this.searchInputRef.current?.value.trim() || ''
                }
            });
        }
    }

    render() {
        return (<>
            {this.props.showLastKnownDateTypeFilter && <div style={{ minWidth: "150px" }}>
                <Formik innerRef={this.lastKnowDateTypeFormikRef as any} initialValues={{} as any} onSubmit={() => { }}  >
                    {formikProps => {
                        return <DropdownFieldEditor formikProps={formikProps} fieldDescriptor={flightEntityDescriptor.getField("lastKnownDateType")}
                            value={this.props.defaultFilter?.lastKnownDateTypeValue}
                            placeholder={_msg("FlightFilterComponent.lastKnownDateTypesFilter.placeholder")}
                            onChange={(entity: any) =>
                                this.props.r.setInReduxState({
                                    filter: {
                                        ...this.props.s.filter,
                                        lastKnownDateTypeValue: entity?.value as string || undefined
                                    }
                                })
                            }
                        />
                    }}
                </Formik>
            </div>}

            {this.props.showDepartureFilter && <div style={{ minWidth: "150px" }}>
                <SelectExt placeholder={_msg("FlightFilterComponent.departureFilter.placeholder")}
                    value={this.props.s.filter?.departureValue === undefined ? '' : this.props.s.filter.departureValue ? departure[1] : departure[0]}
                    isMulti={false}
                    isClearable={true}
                    options={departure}
                    closeMenuOnSelect={true}
                    onChange={entity => {
                        const departureValue = entity?.value === undefined ? undefined : entity?.value === "departure" ? true : false;
                        this.props.r.setInReduxState({
                            filter: {
                                ...this.props.s.filter,
                                departureValue
                            }
                        });
                    }}
                />
            </div>}

            {this.props.showFlightTypeFilter && <div style={{ minWidth: "150px" }}>
                <Formik innerRef={this.flightTypeFormikRef as any} initialValues={{} as any} onSubmit={() => { }}  >
                    {formikProps => {
                        return <DropdownFieldEditor formikProps={formikProps} fieldDescriptor={flightEntityDescriptor.getField("flightType")}
                            value={this.props.defaultFilter?.flightTypeValue}
                            placeholder={_msg("FlightFilterComponent.flightTypesFilter.placeholder")}
                            onChange={(entity: any) =>
                                this.props.r.setInReduxState({
                                    filter: {
                                        ...this.props.s.filter,
                                        flightTypeValue: entity?.value || undefined
                                    }
                                })
                            }
                        />
                    }}
                </Formik>
            </div>}

            <div className="flex-container-row">
                <Input type="text" placeholder={_msg("general.search")} onKeyDown={this.onSearchKeyDown} className='flex-grow' >
                    <input ref={this.searchInputRef} defaultValue={this.props.defaultFilter?.nameOrPlaneIdentifierValue} />
                </Input>
                <Button icon='search' color="green" onClick={() =>
                    this.props.r.setInReduxState({
                        filter: {
                            ...this.props.s.filter,
                            nameOrPlaneIdentifierValue: this.searchInputRef.current?.value.trim() || undefined
                        }
                    })
                } />
                <Popup content={_msg("FlightFilterComponent.searchInfo")} trigger={<Button icon='info' circular />} />
                <Button icon='delete' color="red" onClick={() => {
                    this.searchInputRef.current!.value = '';
                    this.lastKnowDateTypeFormikRef.current?.resetForm();
                    this.flightTypeFormikRef.current?.resetForm();
                    this.props.r.setInReduxState({
                        filter: {
                            departureValue: undefined,
                            flightTypeValue: undefined,
                            lastKnownDateTypeValue: undefined,
                            nameOrPlaneIdentifierValue: undefined
                        }
                    });
                }} />
            </div>

        </>);
    }
}

export const FlightFilterComponentRRC = ReduxReusableComponents.connectRRC(FlightFilterComponentState, FlightFilterComponentReducers, FlightFilterComponent);