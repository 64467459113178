import React from "react";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Button, Checkbox, Segment, Table } from "semantic-ui-react";
import { csvGetEntities } from "./GanttAssignmentCopyInputData";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { csvComment, csvNewLine, regexCsvText } from "@crispico/foundation-react/components/multiCsvEditor/MultiCsvEditor";
import { Utils } from "@crispico/foundation-react";
import { GanttAssignment } from "../GanttAssignmentPage";

class GanttAssignmentDeleteDataState extends State {
    modalIsOpen = false;
    allChecked = true;
    entities = {} as { [key: string]: boolean };
    confirmationPopupOpened: boolean = false;
}

class GanttAssignmentDeleteDataReducers<S extends GanttAssignmentDeleteDataState = GanttAssignmentDeleteDataState> extends Reducers<S> {

}

type GlobalProps = RRCProps<GanttAssignmentDeleteDataState, GanttAssignmentDeleteDataReducers> & {
    entity: GanttAssignment,
    updateInputOutput: (input: string, output: string) => void
};

export class GanttAssignmentDeleteDataButton extends React.Component<GlobalProps> {

    constructor(props: GlobalProps) {
        super(props);
        this.onDeleteDataClick = this.onDeleteDataClick.bind(this);
        this.deleteData = this.deleteData.bind(this);
    }

    onDeleteDataClick() {
        let entities: { [key: string]: boolean } = {};
        csvGetEntities(this.props.entity.inputEntitiesCsv).forEach(entity => entities[entity] = true);
        csvGetEntities(this.props.entity.outputEntitiesCsv).forEach(entity => entities[entity] = true);
        if (Object.keys(entities).length === 0) {
            Utils.showGlobalAlert({ message: _msg("GanttAssignment.deleteDataInfoMessage"), severity: Severity.INFO });
        } else {
            this.props.r.setInReduxState({ entities, modalIsOpen: true });
        }
    }

    protected removeEntityFromCsv(csvText: string, entityType: string): string {
        let pos = 0;
        let csvList: string[] = [];

        if (csvText && csvText.trim().length !== 0) {
            csvList = csvText.split(regexCsvText).filter(x => x && x.trim());

            while (pos < csvList.length) {
                if (!csvList[pos] || !csvList[pos].startsWith(csvComment)) {
                    pos++;
                    continue;
                }

                const fileSeparator: { entity: string } = JSON.parse(csvList[pos].replace(csvComment, "").trim());

                if (fileSeparator.entity === entityType) {
                    let inc = 1;
                    if (csvList[pos + 1] && !csvList[pos + 1].startsWith(csvComment)) {
                        inc++;// data exists, so we have to delete it also
                    }
                    for (let j = pos; j < csvList.length; j++) {
                        csvList[j] = csvList[j + inc];
                    }
                    break;
                }
                pos++;
            }
        }

        return csvList.join(csvNewLine);
    }

    protected deleteData() {
        if (this.props.entity.readOnly) {
            return;
        }
        let input = this.props.entity.inputEntitiesCsv;
        let output = this.props.entity.outputEntitiesCsv;
        Object.keys(this.props.s.entities).filter(key => this.props.s.entities[key]).forEach(entity => {
            input = this.removeEntityFromCsv(input, entity);
            output = this.removeEntityFromCsv(output, entity);
        });

        this.props.updateInputOutput(input, output);
        this.props.r.setInReduxState({ modalIsOpen: false });
    }

    render() {
        return <>
            <Button className="small-margin-left" negative icon="delete" content={_msg("GanttAssignment.deleteData")} onClick={this.onDeleteDataClick} />
            <ModalExt
                severity={Severity.CONFIRMATION}
                open={this.props.s.modalIsOpen}
                content={<Segment basic className="no-padding-top-bottom">
                    <Table compact celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing style={{ textAlign: "center" }} >
                                    <Checkbox checked={this.props.s.allChecked} onChange={() => {
                                        const entities = Object.assign({}, this.props.s.entities);
                                        Object.keys(entities).forEach(key => entities[key] = !this.props.s.allChecked);
                                        this.props.r.setInReduxState({ entities, allChecked: !this.props.s.allChecked })
                                    }} />
                                </Table.HeaderCell>
                                <Table.HeaderCell>{_msg("general.entityName")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.s.entities && Object.keys(this.props.s.entities).map(key => <Table.Row key={key}>
                                <Table.Cell collapsing style={{ textAlign: "center" }} >
                                    <Checkbox checked={this.props.s.entities[key]} onClick={() => this.props.r.setInReduxState({ entities: { ...this.props.s.entities, [key]: !this.props.s.entities[key] } })} />
                                </Table.Cell>
                                <Table.Cell>{entityDescriptors[key].getLabel()}</Table.Cell>
                            </Table.Row>)}
                        </Table.Body>
                    </Table>
                </Segment>}
                onClose={() => this.props.r.setInReduxState({ modalIsOpen: false })}
                actions={[
                    <Button key="close" onClick={() => this.props.r.setInReduxState({ modalIsOpen: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="delete" primary onClick={() => this.props.r.setInReduxState({ confirmationPopupOpened: true })}>{_msg("general.delete")}</Button>
                ]}
            />
            <ModalExt
                severity={Severity.INFO}
                open={this.props.s.confirmationPopupOpened}
                content={this.props.entity.readOnly ? _msg("GanttAssignment.readOnly.exception.message", this.props.entity?.id) : _msg("GanttAssignment.operation.message.validation")}
                onClose={() => this.props.r.setInReduxState({ confirmationPopupOpened: false, modalIsOpen: false })}
                actions={[
                    this.props.entity.readOnly ? null : <Button key="close" onClick={() => this.props.r.setInReduxState({ confirmationPopupOpened: false, modalIsOpen: false })}>{_msg("general.cancel")}</Button>,
                    <Button key="ok" primary onClick={this.deleteData}>{_msg("general.ok")}</Button>
                ]}
            />
        </>
    }
}

export const GanttAssignmentDeleteDataButtonRRC = ReduxReusableComponents.connectRRC(GanttAssignmentDeleteDataState, GanttAssignmentDeleteDataReducers, GanttAssignmentDeleteDataButton);