import React from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { ResponsiveBar, BarSvgProps, BarDatum } from "@nivo/bar";
import { customTheme, helper } from "@crispico/foundation-react/components/nivoExt";
import { ChartConfig, ChartTab, ChartTabProps, ChartTabReducers, ChartTabState, getChartGenerationPeriodFromConfig } from "pages/Chart/ChartTab";
import moment from "moment";
import Measure from "react-measure";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export class BarsDistanceTimeInTerritoriesTabState extends ChartTabState {
    barsDistanceTimeTerritories = [] as Array<Array<{territory: any, timeSpent: number, distanceCovered: number, equipmentResources: Array<{equipmentResource: any, timeSpent: number, distanceCovered: number}>}>>;
    timeData = [] as BarDatum[];
    distanceData = [] as BarDatum[];
    tooltipData = [] as any[];
    keys = [] as string[];
}

export class BarsDistanceTimeInTerritoriesTabReducers<S extends BarsDistanceTimeInTerritoriesTabState = BarsDistanceTimeInTerritoriesTabState> extends ChartTabReducers<S> {
    prepareData(p: { savedData: string, config: ChartConfig }) {
        let result = JSON.parse(p.savedData);
        if (result) {
            this.s.barsDistanceTimeTerritories = result;
        }

        let timeData: BarDatum[] = [];
        let distanceData: BarDatum[] = [];
        let tooltipData: any[] = [];
        let keys: Set<string> = new Set();
            
        const periodLength = 24 / this.s.barsDistanceTimeTerritories.length;
        this.s.barsDistanceTimeTerritories.forEach((samplingPeriod: any, index: number) => {
            let periodTimeData: BarDatum = {samplingStep: index * periodLength + "-" + (index + 1) * periodLength};
            let periodDistanceData: BarDatum = {samplingStep: index * periodLength + "-" + (index + 1) * periodLength};
            tooltipData[index] = {};
            samplingPeriod.forEach((t: {territory: any, timeSpent: number, distanceCovered: number, equipmentResources: Array<{equipmentResource: any, timeSpent: number, distanceCovered: number}>}) => {
                const territory = t.territory.name ? t.territory.name : t.territory.id
                periodTimeData[territory] = Number.parseFloat((t.timeSpent / 60).toPrecision(2));
                periodDistanceData[territory] = Number.parseFloat(t.distanceCovered.toPrecision(2));
                keys.add(territory);
                tooltipData[index][territory] = [];
                t.equipmentResources.forEach((e: any) => {
                    tooltipData[index][territory].push({name: e.equipmentResource.identifier + ' (' + e.equipmentResource.equipmentType?.name + ' )', min: e.timeSpent, km: Number.parseFloat(e.distanceCovered.toPrecision(2))});
                });
            })
            timeData.push(periodTimeData);
            distanceData.push(periodDistanceData);
        })
        this.s.timeData = timeData;
        this.s.distanceData = distanceData;
        this.s.tooltipData = tooltipData;
        this.s.keys = Array.from(keys);
    }
}

type Props = RRCProps<BarsDistanceTimeInTerritoriesTabState, BarsDistanceTimeInTerritoriesTabReducers> & ChartTabProps;
type LocalState = { height: number };

export class BarsDistanceTimeInTerritoriesTab extends ChartTab<Props, LocalState> {
    constructor(props: Props) {
        super(props);
        this.state = { height: 0 };
    }

    render() {
        const props = this.props;
        const { startDate, endDate } = getChartGenerationPeriodFromConfig(props.config);
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding gap5">
            {this.renderTopBar(moment(startDate), moment(endDate))}
            <Measure bounds onResize={(contentRect) => this.setState({ height: contentRect.bounds?.height ? contentRect.bounds?.height : 0 }) }>
                {({ measureRef }) => {
                    return <div ref={measureRef} style={{ width: "calc(100vw - 1em)", height: "100%",  marginTop: "0.25em" }}>
                        <SplitPaneExt size="50%" split="horizontal" style={{ height: this.state.height }}>
                            <Segment className="wh100 overflow-hidden" data-cy="Chart.pieDistanceTimeInTerritories.distanceChart">
                                <Header textAlign='center'>{_msg('Chart.distanceTime.distance.title')}</Header>
                                <BarChart data={props.s.distanceData} keys={props.s.keys} tooltipData={props.s.tooltipData} unit={'km'} secondaryUnit={'km'} />
                            </Segment>
                            <Segment className="wh100 overflow-hidden" data-cy="Chart.pieDistanceTimeInTerritories.timeChart">
                                <Header textAlign='center'>{_msg('Chart.distanceTime.time.title')}</Header>
                                <BarChart data={props.s.timeData} keys={props.s.keys} tooltipData={props.s.tooltipData} unit={'h'} secondaryUnit={'min'} />
                            </Segment>
                        </SplitPaneExt>
                    </div>
                }}
            </Measure>
        </div>;
    }
}

export const BarsDistanceTimeInTerritoriesTabRRC = ReduxReusableComponents.connectRRC(BarsDistanceTimeInTerritoriesTabState, BarsDistanceTimeInTerritoriesTabReducers, BarsDistanceTimeInTerritoriesTab);


type BarChartProps = Omit<BarSvgProps<BarDatum>, "width" | "height">;

const BarChart = (props: BarChartProps & {unit: string, secondaryUnit: string, tooltipData: any}) => (
    <ResponsiveBar
        keys={props.keys}
        indexBy={'samplingStep'}
        margin={{ top: 20, right: 130, bottom: 80, left: 60 }}
        padding={0.4}
        labelFormat={v => `${v}${props.unit}`}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'sampling step',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            format: value => `${Number(value)} ${props.unit}`,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 5 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        tooltip={({ id, value, color, index }) => (
            <Segment className='DistanceAndTimeInTerritories_tooltip'>
                <div style={{backgroundColor: color, width: '12px', height: '12px', display: 'inline-block'}}></div> {id}: <strong>{value}{props.unit}</strong>
                <p>{props.tooltipData[index][id] && props.tooltipData[index][id].length > 0 ? helper(Array.from(props.tooltipData[index][id]).map((t: any) => t.name + ' - ' + t[props.secondaryUnit] + props.secondaryUnit + ', ' )) : ''}</p>
            </Segment>
        )}
        theme={customTheme}

        {...props}
    />
)