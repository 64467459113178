import { Optional, RootReducerForPages, Utils, FieldDescriptor } from "@crispico/foundation-react";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { MapSettings, MarkerSettings } from "app";
import { MarkerData } from "components/MapContainerLeaflet/MapContainerLeaflet";
import moment from "moment";
import { equipmentResourceEntityDescriptor, equipmentTypeEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Label, Table, Icon, Popup, SemanticICONS } from "semantic-ui-react";
import { getIcon, eqImages256x256 } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { Messages } from "@crispico/foundation-gwt-js";
import { territoryEntityDescriptor } from "pages/Territory/territoryEntityDescriptor";
import { FieldType, fieldTypeToPrimitiveFieldTypeMapping } from "@crispico/foundation-react/entity_crud/FieldType";
import StringFieldRenderer, { StringFieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { IconProps } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

/**
 * Used to store utility methods for equipment resources, used in different places (real time map, historical map)
 */
export class EquipmentResourceUtils {

    static getEquipmentResourceMapSettings(mapMarkSettings: MapSettings): Optional<MarkerSettings> {
        return mapMarkSettings.markers.find(m => m.markerType === equipmentResourceEntityDescriptor.name);
    }

    static getMarkerFromEquipmentResource(eq: any, markerSettings: Optional<MarkerSettings>): MarkerData {
        return RealTimeUtils.getMarkerFromEntity(equipmentResourceEntityDescriptor.name, eq, markerSettings)
    }

    // By default size of svg/png will be 36x36, semantic icons default font-size: 1em
    static getImage(er: any, invertedColor: boolean = false, additionalStyleClassName: string = "", semanticIconProps: IconProps = {}) {
        if (!er?.equipmentType || !er.equipmentType.icon) {
            return <>{equipmentResourceEntityDescriptor.icon}</>
        } 
        
        // first try to get the icon from the server
        const imgSrc = (er.equipmentType.icon as string).endsWith(".svg")
            ? er.equipmentType.icon
            : (eqImages256x256[er.equipmentType.icon] || getIcon(er.equipmentType.icon)?.image.src);
        if (!Utils.isNullOrEmpty(imgSrc)) {
            return <img className={(Utils.isNullOrEmpty(additionalStyleClassName) ? "EqRes_mapIcon_svg_big" : additionalStyleClassName) + (invertedColor ? " EqRes_icon_svg" : "")} 
                        src={imgSrc} alt={er.equipmentType.icon}></img>
        }

        // else try to convert icon to SemanticICONS, will return a blank icon if it's not a valid one
        return <span style={{ display: 'contents !important' }}><Icon {...semanticIconProps} name={er.equipmentType.icon as SemanticICONS} className="margin-auto" /></span>
    }

    static renderEquipmentResourceIcon(eq: any, data: MarkerData, markerSettings: Optional<MarkerSettings>, additionalStyles?: { selected?: boolean, hovered?: boolean }): ReactNode {
        let iconContainer = <></>;
        // default icon for missing equipmentType or his icon
        if (Utils.isNullOrEmpty(eq?.equipmentType) || Utils.isNullOrEmpty(eq.equipmentType?.icon)) {
            iconContainer = <i className={'fa fa-times fa-stack-1x'} style={{ color: 'red' }} />
        } else {
            const icon = getIcon(eq?.equipmentType?.icon);
           // first try to get the icon from the server
            if (icon) {
                iconContainer = <>{icon.url.endsWith(".svg")
                    ? <img className='EqRes_mapIcon_svg EqRes_icon_svg' src={icon.image.src} alt='icon' />
                    : typeof (data.icon) === 'string'
                        ? <img className='fa fa-stack-1x' src={icon.image.src} alt='icon' style={{ margin: '2px' }} />
                        : <i className={'fa ' + data.icon?.icon + ' fa-stack-1x'} style={{ color: data.icon?.color, margin: '2px' }}></i>}</>;
            } else {
                // else try to convert to SemanticICONS, will return a blank icon if it's not a valid one
                iconContainer = <Icon name={eq.equipmentType!.icon! as SemanticICONS} className="margin-auto"></Icon>
            }
        }

        return RealTimeUtils.renderEntityIcon(data, markerSettings, iconContainer, additionalStyles)
    }

    static renderSmallInfoArea(eq: any, mapMarkSettings: MapSettings): React.ReactElement {
        return (<div className="flex-container">
            <div>{EquipmentResourceUtils.displayEqInfo(eq, mapMarkSettings)}<div>{equipmentResourceEntityDescriptor.getField("tags").renderTags({ value: eq.tags } as FieldRendererProps, false)}</div></div>
            <div>{EquipmentResourceUtils.getEqDataForSmallInfoArea(eq, mapMarkSettings).map(data => <Label key={data.name}>{data.value}</Label>)}</div>
        </div>);
    }

    private static getEqDataForSmallInfoArea(eq: any, mapMarkSettings: MapSettings): { name: string, value: any }[] {
        let data: any[] = [];
        const markerSetings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapMarkSettings);
        if (!markerSetings) {
            return data;
        }
        const smallPopupFieldsInfo = entityDescriptors["EquipmentResource"].getFieldsFromSettings(markerSetings.smallPopupFields, eq);
        const fields = smallPopupFieldsInfo.fields.length > 0 ? smallPopupFieldsInfo.fields : smallPopupFieldsInfo.defaultFields;
        fields.forEach(field => {
            const fieldInfo = RealTimeUtils.getFieldInfo(eq, equipmentResourceEntityDescriptor, field);
            if (fieldInfo.lastFd && !fieldInfo.lastFd.typeIsEntity()) {
                data.push({ name: equipmentResourceEntityDescriptor.getComposedFieldLabel(fieldInfo.fdChain), value: fieldInfo.fielValue || "-" })
            }
        });
        return data;
    }

    static displayEqInfo(eq: any, mapSettings: MapSettings) {
        const markerSettings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(mapSettings);
        const fieldDescriptor = equipmentResourceEntityDescriptor.getFieldDescriptorChain(markerSettings?.markerIdField ? markerSettings?.markerIdField : "identifier")[0];
        return fieldDescriptor ? fieldDescriptor.getFieldValue(eq) : "";
    }

}

type EquipmentResourceBigInfoAreaProps = { eq: any, mapMarkSettings: MapSettings }

export class EquipmentResourceBigInfoArea extends React.Component<EquipmentResourceBigInfoAreaProps> {

    private getEqDataForBigInfoArea(eq: any): any[] {
        let data: any[] = [];
        const markerSetings: Optional<MarkerSettings> = EquipmentResourceUtils.getEquipmentResourceMapSettings(this.props.mapMarkSettings);
        if (!markerSetings) {
            return data;
        }
        const bigPopupFieldsInfo = entityDescriptors["EquipmentResource"].getFieldsFromSettings(markerSetings.bigPopupFields, eq);
        const fields = bigPopupFieldsInfo.fields.length > 0 ? bigPopupFieldsInfo.fields : bigPopupFieldsInfo.defaultFields;
        fields.forEach(field => {
            const fieldInfo = RealTimeUtils.getFieldInfo(eq, equipmentResourceEntityDescriptor, field);
            if (fieldInfo.lastFd) {
                data.push({
                    name: equipmentResourceEntityDescriptor.getComposedFieldLabel(fieldInfo.fdChain),
                    value: fieldInfo.fielValue !== undefined ? fieldInfo.lastFd.renderField(fieldInfo.lastEntityFromFdChain) : _msg("general.notAvailable"),
                    icon: fieldInfo.lastFd.getIcon()
                })
            }
        });
        return data;
    }

    render = () => {
        const { eq } = this.props;
        if (!this.props.eq) {
            return <></>;
        }
        const data = this.getEqDataForBigInfoArea(eq);
        const lastDetectionDate = moment(this.props.eq.lastDetectionDate);
        const updated = moment(this.props.eq.updated);
        const showLastDetectionDate = moment().diff(lastDetectionDate, "days") > 0;
        const showUpdatedDate = moment().diff(updated, "days") > 0;
        return <div className="wh100 EqResBigInfoArea">
            <MessageExt headerClassName="flex-container-row flex-center flex-wrap">
                {EquipmentResourceUtils.getImage(eq, true, "EqRes_mapIcon_drawer", { size: 'huge' })}
                <div className="flex-container flex-center flex-grow-shrink-no-overflow flex-wrap EqResBigInfoArea_generalInfo">
                    <h1>
                        <NavLink to={equipmentResourceEntityDescriptor.getEntityEditorUrl(eq.id)}>{EquipmentResourceUtils.displayEqInfo(eq, this.props.mapMarkSettings)}</NavLink>
                    </h1>
                    {/* TODO by CS: should have used descriptor; I was forced to explicitly invoke Messages... */}
                    {eq.equipmentType ? <Label color="teal">{Messages.getInstance().maybeTranslateByUser(eq.equipmentType.name)}</Label> : null}
                    <Label basic>{Messages.getInstance().maybeTranslateByUser(eq.equipmentType?.name)}</Label>
                    {eq.tags && eq.tags.length > 0 ? <div>{equipmentResourceEntityDescriptor.getField("tags").renderTags({ value: eq.tags } as FieldRendererProps)}</div> : null}
                </div>
            </MessageExt>
            <div className="flex-container-row flex-wrap flex-center EqResBigInfoArea_available">
                <Popup
                    trigger={<Label size="large" basic color='green'><Icon name="truck" />{this.props.eq.lastDetectionDate ? moment(this.props.eq.lastDetectionDate).format(showLastDetectionDate ? Utils.dateTimeFormat : Utils.timeFormat) : _msg("general.notAvailable")}</Label>}>
                    <Popup.Header><Icon name="truck" />{_msg("EquipmentResource.lastDetectionDate.label")}</Popup.Header>
                    <Popup.Content>
                        <Label>{this.props.eq.lastDetectionDate ? moment(this.props.eq.lastDetectionDate).format(Utils.dateTimeFormat) : _msg("general.notAvailable")}</Label>
                    </Popup.Content>
                </Popup>
                <Popup
                    trigger={<Label size="large" basic color='blue'><Icon name="database" />{this.props.eq.updated ? moment(this.props.eq.updated).format(showUpdatedDate ? Utils.dateTimeFormat : Utils.timeFormat) : _msg("general.notAvailable")}</Label>}>
                    <Popup.Header><Icon name="database" />{_msg("EquipmentResource.updated.label")}</Popup.Header>
                    <Popup.Content>
                        <Label>{this.props.eq.updated ? moment(this.props.eq.updated).format(Utils.dateTimeFormat) : _msg("general.notAvailable")}</Label>
                    </Popup.Content>
                </Popup>
            </div>
            <div className="flex-container-row flex-wrap flex-center EqResBigInfoArea_available">
                <Label color={this.props.eq.available ? "green" : "grey"} size="large" content={_msg("EquipmentResource.available.true")}></Label>
                <Label color={"grey"} size="large">{_msg("EquipmentResource.available.partial")}</Label>
                <Label color={this.props.eq.available ? "grey" : "red"} size="large">{_msg("EquipmentResource.available.false")}</Label>

            </div>

            <Table celled padded selectable compact striped>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell key={"name"}>{_msg("MapRealTime.drawer.tableHeader.info")}</Table.HeaderCell>
                        <Table.HeaderCell key={"value"}>{_msg("MapRealTime.drawer.tableHeader.values")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((entity, i) => (
                        <Table.Row key={i}>
                            <Table.Cell key={"name"}><div className="flex-container-row flex-wrap">{entity.icon}{entity.name}</div></Table.Cell>
                            <Table.Cell textAlign='center' key={"value"}>{entity.value}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>;
    }
}

export type TerritoriesFieldRendererProps = {
    linkToEquipmentResourceTableWithFilter?: boolean,
    customLabel?: string,
    asLabel?: boolean
} & StringFieldRendererProps;

export class TerritoriesFieldDescriptor extends FieldDescriptor {
    constructor(name: string) {
        super();
        this.name = name;
        this.type = "territoriesName";
        this.icon = "object ungroup outline";
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        return React.createElement(TerritoriesFieldRenderer, props);
    }
}

export class TerritoriesFieldRenderer extends StringFieldRenderer<TerritoriesFieldRendererProps> {
    protected renderForEmptyValue() {
        return <Label><span data-tooltip={this.props.showTooltip && this.props.fieldDescriptor.getLabel()} data-position="top center">{_msg("Territory.noTerritory")}</span></Label>;
    }

    protected getMainSplitCharacter() {
        return ",";
    }

    protected getLabelFromValue(item: string, index: number) {
        const data = item.split("|");
        const id = data[0];
        let url = territoryEntityDescriptor.getEntityEditorUrl(id);
        if (this.props.linkToEquipmentResourceTableWithFilter) {
            url = new ShareLinkLogic().createLink(false, equipmentResourceEntityDescriptor, Filter.enableAllFilters(Filter.create("territories", FilterOperators.forString.contains, data[1])));
        }
        return data.length === 2 && id ? <NavLink key={id} to={url}><Label data-tooltip={this.props.showTooltip && this.props.fieldDescriptor.getLabel()} data-position="top center" horizontal={!this.props.asLabel}>{data[1]}</Label></NavLink> : null;
    }

    render(): JSX.Element | null {
        if (Utils.isNullOrEmpty(this.props.value)) {
            return this.renderForEmptyValue();
        }

        return this.props.value && this.props.value.split(this.getMainSplitCharacter()).map(
            (item: string, index: number) => this.getLabelFromValue(item, index));
    }
}

fieldTypeToPrimitiveFieldTypeMapping["territoriesName"] = FieldType.string;