import { FilterOperators } from "@crispico/foundation-gwt-js";
import { addEntityDescriptor, ColumnDefinition, createSliceFoundation, EntityDescriptor, Optional, Organization, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, getBaseImpures, SliceEntityEditorPage, sliceEntityEditorPageOnlyForExtension, Utils, ADD } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TerritoriesFieldDescriptor } from "pages/EquipmentResource/EquipmentResourceUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Location } from "history";
export class DetectionEventEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "DetectionEvent",
            miniFields: ["id"],
            defaultSort: { field: "date", direction: "DESC" },
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.today),
            icon: "rss"
        });
    }

    renderTable() {
        return <DetectionEventTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }

}

export const detectionEventEntityDescriptor = addEntityDescriptor(new DetectionEventEntityDescriptor()
    .isInDefaultColumnConfig(true, "equipmentResource", "detectedBy", "date", "longitude", "latitude", "territories", "enteredTerritories", "exitedTerritories")
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "detectedBy", type: "EquipmentResource" })
    .addFieldDescriptor({
        name: "date",
        type: FieldType.date,
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat })
    })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor(new TerritoriesFieldDescriptor("territories"))
    .addFieldDescriptor(new TerritoriesFieldDescriptor("enteredTerritories"))
    .addFieldDescriptor(new TerritoriesFieldDescriptor("exitedTerritories"))
);

export const sliceEditorDetectionEvent = detectionEventEntityDescriptor.infoEditor.slice = createSliceFoundation(class Ext extends SliceEntityEditorPage {

    nestedSlices = {
        ...sliceEntityEditorPageOnlyForExtension.nestedSlices,
    }

    impures = {
        ...sliceEntityEditorPageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        loadSuper: sliceEntityEditorPageOnlyForExtension.impures.load,
        async load(id: typeof ADD | any, columns: Optional<ColumnDefinition[]>, currentOrganization: Optional<Organization>, location?: Location<any>) {
            // do this here to re-write queries with last version of customFields; see getFieldsToRequest()    
            // race condition for initIfNeeded and appMeta.getEntityDescriptorsForServer()   
            this.getSlice().initQueries();
            await this.loadSuper(id, columns, currentOrganization, location);
        }
    }
}).setEntityDescriptor(detectionEventEntityDescriptor);

class DetectionEventTablePage extends EntityTablePage<EntityTablePageProps> {
    addOneToManyModeFilters(filters: Filter[], oneToManyModeField: string, oneToManyModeEntityDescriptor: EntityDescriptor, oneToManyModeCachedId: any) {
        filters!.push(
            Filter.createComposed(FilterOperators.forComposedFilter.or, [
                Filter.create("equipmentResource", FilterOperators.forNumber.equals, oneToManyModeCachedId),
                Filter.create("detectedBy", FilterOperators.forNumber.equals, oneToManyModeCachedId),
            ]));
    }
}

export const DetectionEventTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, DetectionEventTablePage);