import { Color, XopsColorRegistry } from "pages/XopsColor/XopsColorRegistry";

function getHumanResourceScheduleLayerStyle(schedule: any) {
    let result = { backgroundColor: "gray", opacity: ".2" };

    if (schedule.finished) {
        result.backgroundColor = XopsColorRegistry.INSTANCE.getColor(Color.SCHEDULE_FINISHED);
    } else if (!schedule.humanResource.vehicle?.id) {
        result.backgroundColor = XopsColorRegistry.INSTANCE.getColor(Color.SCHEDULE_NO_EQUIPMENT);
    } else {
        result.backgroundColor = XopsColorRegistry.INSTANCE.getColor(Color.SCHEDULE_WITH_EQUIPMENT);
    }
    return result;
}

export const getLayerStyle = (type: string, entity: any): any => {
    switch (type) {
        case "HumanResourceSchedule":
            return getHumanResourceScheduleLayerStyle(entity);
        default:
            return undefined;
    }
}
