import { ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom } from "@crispico/foundation-react";
import React from "react";
import { Message } from "semantic-ui-react";

export const sliceErrorPage = createSliceFoundation(class SliceErrorPage {

    reducers = {
        ...getBaseReducers<SliceErrorPage>(this),
    }
    impures = {
        ...getBaseImpures<SliceErrorPage>(this),
    }
})

type Props = PropsFrom<typeof sliceErrorPage>;

export class ErrorPage extends React.Component<Props> {

    render() {
        const state = (this.props.location as any)?.state;
        return <Message negative>
            <Message.Header>{state?.headerMessage || _msg("general.error")}</Message.Header>
            <p>{state?.errorMessage}</p>
        </Message>
    }
}

export const infoErrorPage = new ConnectedPageInfo(sliceErrorPage, ErrorPage, "ErrorPage", undefined, false);
infoErrorPage.routeProps = { path: "/error", exact: true };