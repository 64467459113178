import gql from "graphql-tag";
import { apolloClientHolder } from "@crispico/foundation-react";
import { FieldDescriptor, Utils } from "@crispico/foundation-react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { GalleryMedia } from "components/LiveVideo/GalleryMedia";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import moment from "moment";
import React, { ReactNode } from "react";
import { FILES_PATH, Video } from "pages/EquipmentResource/VideoPage";
import { Button, Icon, Modal, Divider } from "semantic-ui-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { VIDEO_ALLOW_VIEW_BLURRED, VIDEO_ALLOW_VIEW_ORIGINAL } from "app";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";

export class DrivingEventVideoButtonField extends FieldDescriptor {
    constructor(name: string) {
        super();
        this.name = name;
        this.icon = "film";
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
        this.isInDefaultColumnConfigForEditor = false;
    }

    getAppearsInUi(): boolean {
        return true;
    }

    protected renderFieldInternal(_: any, props: FieldRendererProps): ReactNode {
        return <DrivingEventVideoButtonRRC id={"VideoFieldRendererField_" + props.entity.id} drivingEventId={props.entity.id} />
    }
}

type DrivingEventVideoButtonProps = RRCProps<DrivingEventVideoButtonState, DrivingEventVideoButtonReducers<DrivingEventVideoButtonState>> & { drivingEventId: Number };

class DrivingEventVideoButtonState extends State {
    videoModalOpen: boolean = false;
    buttonsModalOpen: boolean = false;
    videos: { [key: string]: string } = {};
    drivingEventDate: string = '';
    drivingEventType: string = '';
}

class DrivingEventVideoButtonReducers<S extends DrivingEventVideoButtonState = DrivingEventVideoButtonState> extends Reducers<S> {
}

class DrivingEventVideoButton extends React.Component<DrivingEventVideoButtonProps> {
    async getVideosByDrivingEvent(drivingEventId: Number, blurred: boolean) {
        const getVideosByDrivingEventQuery = gql(`query getVideosByDrivingEvent($drivingEventId: Long!){
            smartwitnessService_videosByDrivingEvent(
                drivingEventId: $drivingEventId
            ) {date, thumbnailPath, videoPath, eventType, cameraChannel, entityId, latitude, longitude, duration}
          }`);
        const results: Video[] = await (await apolloClientHolder.apolloClient.query({ query: getVideosByDrivingEventQuery, variables: { drivingEventId } })).data.smartwitnessService_videosByDrivingEvent;
        const loadedVideos = {} as { [key: string]: string };

        // filter after DE id, maybe exists driving event with same information, but id must be unique
        results.forEach((result) => {
            result.videoPath += "?blurred=" + blurred;
            if (result.entityId === drivingEventId.toString()) {
                loadedVideos[result.cameraChannel] = FILES_PATH + result.videoPath;
            }
        });
        if (results.length !== 0) {
            this.props.r.setInReduxState({ drivingEventType: results[0].eventType, drivingEventDate: results[0].date.toString(), videos: loadedVideos });
        }
    }

    render() {
        return (
            <>
                <ModalExt open={this.props.s.buttonsModalOpen} size="mini" onClose={() => this.props.r.setInReduxState({ buttonsModalOpen: false })}>
                    <Modal.Header>{_msg("VideoPage.chooseToPlay")}</Modal.Header>
                    <Modal.Content className="wh100" style={{ display: "grid", justifyItems: "center" }}>
                        <Button
                            icon="play"
                            circular
                            size="small"
                            content={_msg("VideoPage.playOriginal")}
                            color="green"
                            onClick={() => {
                                this.props.r.setInReduxState({ buttonsModalOpen: false, videoModalOpen: true })
                                this.getVideosByDrivingEvent(this.props.drivingEventId, false);
                            }}
                        />
                        <Divider />
                        <Button
                            icon="play"
                            circular
                            size="small"
                            content={_msg("VideoPage.playBlurred")}
                            color="blue"
                            onClick={() => {
                                this.props.r.setInReduxState({ buttonsModalOpen: false, videoModalOpen: true })
                                this.getVideosByDrivingEvent(this.props.drivingEventId, true);
                            }}
                        />
                    </Modal.Content>
                </ModalExt>

                <ModalExt open={this.props.s.videoModalOpen} style={{ width: "95%", height: "95%" }} onClose={() => this.props.r.setInReduxState({ videoModalOpen: false })}>
                    <Modal.Content className="wh100">
                        <GalleryMedia data={this.props.s.videos} loading={false} video={true} progressbarVisible={true}
                            extraInfo={Object.keys(this.props.s.videos).length !== 0 ? entityDescriptors["EquipmentResource"].getField(this.props.s.drivingEventType)?.getLabel() + " " + moment(this.props.s.drivingEventDate).format(Utils.dateTimeWithSecFormat) : _msg("VideoPage.noInfo")} />
                    </Modal.Content>
                </ModalExt>

                <Button icon circular size="small" style={{ transform: 'translate(50%, -10%)' }}
                    onClick={() => {
                        if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) || AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED, true)) {
                            if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) && AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL)) {
                                this.props.r.setInReduxState({ buttonsModalOpen: true });
                            } else {
                                this.props.r.setInReduxState({ videoModalOpen: true });
                                this.getVideosByDrivingEvent(this.props.drivingEventId, AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED));
                            }
                        }
                    }}
                >
                    <Icon name="play" color="blue" />
                </Button>
            </>
        );
    }
}

export const DrivingEventVideoButtonRRC = ReduxReusableComponents.connectRRC(DrivingEventVideoButtonState, DrivingEventVideoButtonReducers, DrivingEventVideoButton);