import { ColumnDefinition, EntityDescriptor, EntityTableSimple, TestUtils, Utils, EntityTableSimpleState, EntityTableSimpleReducers, EntityTableSimpleProps, DummyToRememberPeopleToCast, FieldDescriptor, Optional } from "@crispico/foundation-react";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { ReactElement } from "react";
import { Icon, Menu, Modal, Popup, Segment, SemanticICONS, Button, ModalHeader } from "semantic-ui-react";
import ReactDOM from "react-dom";
import { ModalExt, ModalExtOpen } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { BackgroundLayer, Group, IGanttAction, IGanttActionParamForRun, IGanttOnContextMenuShowParam, Item, RowLayer, getNearestRowNumber, getPixelAtTime, getTimeAtPixel } from "@crispico/react-timeline-10000";
import { GanttTypeRenderer } from "./ganttTypeRenderers";
import { GanttUtils } from "./GanttUtils";
import { NavLink } from "react-router-dom";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import XopsTimeline from "./XopsTimeline";
import { GanttInfoRenderer } from "./GanttInfoRenderer";
import { Table, CellProps } from "fixed-data-table-2";
import Timeline, { PARENT_ELEMENT } from "@crispico/react-timeline-10000/types/timeline";
import { isFlexMode } from "app";
import { Draggable, Droppable } from "@crispico/foundation-react/components/DragAndDrop/DragAndDrop";
import { DragAndDropContoller } from "./DragAndDropControler";
import { AssignTasksToResourcePageProps, AssignTasksToResourcePageRRC } from "./AssignTasksToResourcePage";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";


// this constant is used in flexMode, to keep the size similar with mission gantt from flex
// remove it if  Object Gantt is unused
export const ITEM_HEIGHT_FLEX_MODE = 30;
export const ITEM_HEIGHT = 40;
export const HEADER_HEIGHT = 20;

export interface GanttLayer extends RowLayer {
    visible: boolean;
}

export interface GanttGroup extends Group {
    visible: boolean;
    [key: string]: any
}
export interface GanttData {
    layers: GanttLayer[],
    groups: GanttGroup[],
    items: GanttItem[];
}
export interface GanttItem extends Item {
    entityUid: string;
    visible: boolean;
    ganttId: string;
}
export class AbstractGanttState extends State {
    data: GanttData = { layers: [], groups: [], items: [] };
    isModalOpen: ModalExtOpen = false;
    assignTasksToResourcePageProps: AssignTasksToResourcePageProps | undefined;
    tableWidth: number = 200;
    start: number = moment().startOf("day").valueOf();
    end: number = moment().endOf("day").valueOf();
    popupEntityUid: string | undefined;
    isDraggingMode: boolean = false;
    tableFields?: string[];
    modalEntityUid: string | undefined;
}
export class AbstractGanttReducers<S extends AbstractGanttState = AbstractGanttState> extends Reducers<S> {

    updatePopupEntityUid(value: string | undefined) {
        this.s.popupEntityUid = value;
    }

    updateModalEntityUid(value?: string | undefined) {
        this.s.modalEntityUid = value;
    }

    updateAssignTasksToResourcePageProps(showModal: ModalExtOpen, props?: AssignTasksToResourcePageProps) {
        this.s.assignTasksToResourcePageProps = props;
        this.s.isModalOpen = showModal;
    }
}

export type AbstractGanttProps = {
    /**
     * Keeps the data in denormalization mode.
     * Shouldn't be enriched with other fields!
     */
    entities?: { [entityName: string]: { [id: number]: any } },
    entitiesVersion?: number,
    hideTopBar?: boolean,
    /**
     * Used to store the HR/ER that shouldn't be displayed 
     * (GanttResources will hide the lines, GanttTasks will calculate if task has mission based on this)
     * This should be replaced with a more general mechanism in the future!
     */
    hideResources?: { [key: string]: number[] },
    /**
     * If set, the topBar component will be displayed on this portal container.
     */
    portalContainerForTopBar?: any
};

type ContextProps = {
    entities?: { [entityName: string]: { [id: number]: any } },
    groups: GanttGroup[],
    useDnD: boolean,
}

export abstract class AbstractGantt<
    P extends AbstractGanttProps = AbstractGanttProps,
    R extends AbstractGanttReducers = AbstractGanttReducers,
    S extends AbstractGanttState = AbstractGanttState,
    LS extends {} = {}> extends React.Component<RRCProps<S, R> & P, LS> {

    static Context = React.createContext<ContextProps>(undefined as any);

    protected entitiesJustChanged = false;

    protected timelineRef = React.createRef<XopsTimeline>();
    protected entityTableSimpleRef = React.createRef<EntityTableSimple>();

    protected timelineId = "";

    constructor(props: RRCProps<S, R> & P) {
        super(props);
        this.itemRenderer = this.itemRenderer.bind(this);
        this.onContextMenuShow = this.onContextMenuShow.bind(this);
        this.onItemHover = this.onItemHover.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onSelectItemColumn = this.onSelectItemColumn.bind(this);
        this.renderTimeline = this.renderTimeline.bind(this);
        this.getEntityAt = this.getEntityAt.bind(this);
        this.getTimelineProps = this.getTimelineProps.bind(this);
        this.getHighlightedIntervals = this.getHighlightedIntervals.bind(this);

        this.timelineId = this.constructor.name + "_XopsTimeline";
        DragAndDropContoller.INSTANCE.registerGantt(this.constructor.name, this);

        // we need to invoke here, because shouldComponentUpdate() is not invoked for the first render
        this.shouldComponentUpdateInternal(this.props, true);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: RRCProps<S, R> & P) {
        this.componentDidUpdateInternal(prevProps);
    }

    getPixelAtTime(time: Moment) {
        if (!this.timelineRef.current) {
            throw new Error("The gantt isn't mounted, we cannot use this function with unmount component");
        }
        return getPixelAtTime(time, this.timelineRef.current!.getStartDate(), this.timelineRef.current!.getEndDate(), this.timelineRef.current!.getTimelineWidth(undefined as unknown as null))
    }

    protected entitiesChangedHandler(newEntities: any) {
        // nop
    }

    shouldComponentUpdate(nextProps: RRCProps<S, R> & P) {
        return this.shouldComponentUpdateInternal(nextProps, false);
    }

    getTimeAtPixel(pixel_location: number): typeof moment {
        if (!this.timelineRef.current) {
            throw new Error("The gantt isn't mounted, we cannot use this function with unmount component");
        }
        return getTimeAtPixel(pixel_location - PARENT_ELEMENT(this.timelineId).getBoundingClientRect().left,
            this.timelineRef.current!.getStartDate(),
            this.timelineRef.current!.getEndDate(),
            this.timelineRef.current!.getTimelineWidth(undefined as unknown as null),
            1);
    }

    protected shouldComponentUpdateInternal(nextProps: RRCProps<S, R> & P, firstRender: boolean) {
        if (!firstRender) {
            if (_.isEqual(nextProps.entitiesVersion, this.props.entitiesVersion)) {
                this.entitiesJustChanged = false;
                return true;
            } // else => entities was changed 
        }

        this.entitiesChangedHandler(nextProps.entities);

        /** 
         * code inspired from Tree.tsx
         * need to postpone a re-render until gantt data is calculated
         */
        this.entitiesJustChanged = !firstRender; // for firstRender, this is true

        return false;
    }

    protected async componentDidUpdateInternal(prevProps?: RRCProps<S, R> & P) {
        if (TestUtils.storybookMode) {
            return;
        }
        if (prevProps && (!_.isEqual(this.props.hideResources, prevProps.hideResources))) {
            // need to change the way we are notified when the entities has changed!!!
            this.entitiesChangedHandler(this.props.entities);
        }

    }

    protected renderTopBar(): React.ReactNode {
        return null;
    }

    public itemRenderer(itemProps: any) {
        return <GanttTypeRenderer {...itemProps} />;
    }

    protected renderAdditionalContextMenuAction(entityUid: string): IGanttAction[] {
        return [];
    }

    private onContextMenuShow(contextMenuShowParam: IGanttOnContextMenuShowParam): IGanttAction[] {
        if (contextMenuShowParam.actionParam.selection?.length != 1) {
            // show contextMenu only for one selected item
            return [];
        }
        // the key of the item = uid
        const entityUid: { entityName: string, id: number } = GanttUtils.fromEntityUid(contextMenuShowParam.actionParam.selection[0] as string);
        const actions: IGanttAction[] = [
            {
                renderInMenu: (param: IGanttActionParamForRun) => renderEditItem(param, "list alternate outline", _msg("general.edit"), entityUid.entityName, entityUid.id)
            },
            {
                icon: "info",
                label: _msg("AbstractGantt.contextMenu.tooltip.label"),
                run: (param: IGanttActionParamForRun) => this.props.r.updateModalEntityUid(contextMenuShowParam.actionParam.selection[0] as string)
            },
            ...this.renderAdditionalContextMenuAction(contextMenuShowParam.actionParam.selection[0] as string)
        ];
        return actions;
    }

    onItemHover(e: Event, itemKey: any) {
        if (this.props.s.popupEntityUid !== itemKey) {
            this.props.r.updatePopupEntityUid(itemKey);
        }
    }

    protected onSelectionChange(selectedItems: (number | string)[]) {
        // nop
    }

    protected getSnap(): number | undefined {
        return undefined;
        // nop
    }

    protected getTableWidth() {
        return this.props.s.tableWidth;
    }

    protected onSelectItemColumn(index: number) {
        this.entityTableSimpleRef.current?.setSelected(undefined);
        const group = this.props.s.data.groups[index];
        this.props.r.updatePopupEntityUid(group && group.entityUid);
    }

    protected getTableColumns(): ColumnDefinition[] | undefined {
        if (this.props.s.tableFields) {
            let cc: ColumnDefinition[] = [];
            this.props.s.tableFields.forEach(f => cc.push({ name: f, width: 120 }));
            return cc;
        }
        return undefined;
    }

    protected renderTable(table: ReactElement<Table>): ReactElement | null {
        return table;
    }

    protected getHighlightedIntervals(): JSX.Element[] {
        return [];
    }

    // @ts-ignore
    protected getTimelineProps(): XopsTimeline.propTypes {
        const ganttEntityName = this.constructor.name;
        return {
            ref: this.timelineRef,
            componentId: this.timelineId,
            // @ts-ignore
            timelineMode: Timeline.TIMELINE_MODES.SELECT,
            onSelectionChange: this.onSelectionChange,
            useMoment: false,
            onContextMenuShow: this.onContextMenuShow,
            startDate: moment(this.props.s.start).valueOf(),
            endDate: moment(this.props.s.end).valueOf(),
            groups: this.props.s.data.groups.filter((g: GanttGroup) => g.visible),
            items: this.props.s.data.items.filter((i: GanttItem) => i.visible),
            showCursorTime: false,
            backgroundLayer: <BackgroundLayer nowMarker={true} verticalGrid={true} verticalGridStyle={{ borderColor: '#ccc', "opacity": 0.2 }}
                highlightedIntervals={this.getHighlightedIntervals()} />,
            rowLayers: this.props.s.data.layers.filter((l: GanttLayer) => l.visible),
            itemHeight: this.getItemHeight(),
            itemRenderer: this.itemRenderer,
            onSplitChange: (tableWidth: number) => this.props.r.setInReduxState({ tableWidth }),
            onItemHover: this.onItemHover,
            onItemContextClick: () => this.props.r.updatePopupEntityUid(undefined),
            forceRedrawFunc: () => true,
            droppableProps: { dragable: true, item: { entityUid: GanttUtils.toEntityUid(ganttEntityName, 0) } },
            zoomEnabled: !isFlexMode()
        }
    }

    protected renderTimeline(table: ReactElement<Table>) {
        return <XopsTimeline {...this.getTimelineProps()} table={this.renderTable(table)} />
    }

    protected getEntityDescriptor(): EntityDescriptor {
        throw new Error("Method getEntityDescriptor must be implemented!");
    }

    protected getItemHeight(): number {
        return isFlexMode() ? ITEM_HEIGHT_FLEX_MODE : ITEM_HEIGHT;
    }

    protected getContextProps(): ContextProps {
        return { entities: this.props.entities, groups: this.props.s.data.groups, useDnD: true };
    }

    protected getEntityAt(index: number) {
        const group = this.props.s.data.groups[index];
        return group?.entityUid && GanttUtils.findByUid(group.entityUid, this.props.entities);
    }

    render() {
        if (this.entitiesJustChanged) {
            /** 
             * At the moment of writing, if shouldComponentUpdate() returns false => render() is not called. However the docs state that
             * in the future, the result of shouldComponentUpdate() may be taken as a hint; not as a guarantee, and hence render() may still
             * be called. And hence the code will arrive here. Note: that this is also true w/ the functional components / hooks version (i.e. React.memo()).
             * 
             * If this will happen, the only solution I see right now is to "memoize" (or simpler: just cache) the render function; i.e. if the code gets here => 
             * return the previous result.
             * 
             * copied comment from Tree.tsx
             */
            throw new Error("An illegal state was detected. This was anticipated, so please follow the instructions and update the code.")
        }

        const { props } = this;
        return <div className="flex-container flex-grow no-padding gap10">
            {this.props.portalContainerForTopBar
                ? ReactDOM.createPortal(<Segment className="less-padding no-margin flex-container-row flex-center gap5">
                    {this.renderTopBar()}
                </Segment>, this.props.portalContainerForTopBar)
                : !this.props.hideTopBar ? <Segment className="less-padding no-margin flex-container-row flex-center gap5">
                    {this.renderTopBar()}
                </Segment> : null}
            <AbstractGantt.Context.Provider value={this.getContextProps()}>
                <EntityTableSimpleDnDCellRRC ref={this.entityTableSimpleRef} id={this.constructor.name + "_EntityTableSimple"}
                    selectedIsRowIndex
                    tableProps={{ width: this.getTableWidth(), rowHeight: this.getItemHeight(), headerHeight: HEADER_HEIGHT }}
                    renderMainElementNormalModeFunction={this.renderTimeline}
                    renderEmptyRow={() => null}
                    onDoubleClickItem={(entity: any, rowIndex: number) => { AppMetaTempGlobals.history.push(this.getEntityDescriptor().getEntityEditorUrl(entity.id)) }}
                    entityDescriptor={this.getEntityDescriptor()} columns={this.getTableColumns()} onSelectItem={this.onSelectItemColumn}
                    getEntityAt={this.getEntityAt} />
            </AbstractGantt.Context.Provider>
            <Popup flowing onClose={(e) => {
                // reject if click on current selected item
                // workaround for a strange issue: Portal.js is triggering an additional document click event because it doesn't
                // have the info for triggerRef for current selected item; this event closes the popup at click -> no popup displayed KO
                // for the moment we didn't found a better way to do this
                let target = e.target as HTMLElement | null;
                while (target) {
                    if (target.hasAttribute('data-item-index')) {
                        break;
                    }
                    target = target.parentElement;
                }
                if (target) {
                    return;
                }
                this.props.r.updatePopupEntityUid(undefined);
            }} closeOnEscape
                position="bottom right"
                context={props.s.popupEntityUid ? document.querySelector('[data-item-index="' + props.s.popupEntityUid + '"]') as HTMLElement : undefined}
                open={props.s.popupEntityUid != undefined} >
                {props.s.popupEntityUid ? <GanttInfoRenderer entityName={GanttUtils.fromEntityUid(props.s.popupEntityUid).entityName} entity={GanttUtils.findByUid(props.s.popupEntityUid, this.props.entities)} /> : null}
            </Popup>
            <ModalExt size="tiny" style={{ whiteSpace: "pre-line" }} transparentDimmer={true}
                open={this.props.s.assignTasksToResourcePageProps !== undefined && this.props.s.isModalOpen}
                onClose={() => this.props.r.updateAssignTasksToResourcePageProps(false)}>
                <ModalHeader>{_msg("AssignTasksToResourcePage.title")}</ModalHeader>
                <Modal.Content className="less-padding flex-grow" style={{ maxHeight: "700px" }}>
                    <AssignTasksToResourcePageRRC {...this.props.s.assignTasksToResourcePageProps} id="assignTasksToResource"
                        onAssign={() => this.props.r.updateAssignTasksToResourcePageProps(false)} onCancel={() => this.props.r.updateAssignTasksToResourcePageProps(false)} />
                </Modal.Content>
            </ModalExt>
            <ModalExt closeOnEscape closeIcon style={{ maxWidth: '750px' }} open={this.props.s.modalEntityUid != undefined} onClose={() => this.props.r.updateModalEntityUid()}>
                <Modal.Header>
                    {this.props.s.modalEntityUid ? _msg("AbstractGantt.tooltipModal.header", _msg(GanttUtils.fromEntityUid(this.props.s.modalEntityUid).entityName + ".label")) : ""}
                </Modal.Header>
                <Modal.Content className="small-padding flex-grow" style={{ maxHeight: "300px" }}>
                    {this.props.s.modalEntityUid && <GanttInfoRenderer entityName={GanttUtils.fromEntityUid(this.props.s.modalEntityUid).entityName} entity={GanttUtils.findByUid(this.props.s.modalEntityUid, this.props.entities)} />}
                </Modal.Content>
            </ModalExt>
        </div>
    }
}

export function renderEditItem(param: IGanttActionParamForRun, icon: SemanticICONS, content: string | JSX.Element, entityName: string, id: number) {

    return <Menu.Item as={isFlexMode() ? Menu.Item : NavLink} to={entityDescriptors[entityName].getEntityEditorUrl(id)}
        onClick={() => {
            // don't open the crud editors if is flexMode only send the event to as
            if (isFlexMode()) {
                const header = document.getElementById("root")!;
                const event = new CustomEvent("openEditor", { detail: GanttUtils.toEntityUid(entityName, id) });
                header.dispatchEvent(event);
            }
            param.closeContextMenu();
        }}>
        <Icon name={icon} />
        {content}
    </Menu.Item>
}

export const EntityTableSimpleDnDCellRRC = ReduxReusableComponents.connectRRC(EntityTableSimpleState, EntityTableSimpleReducers, class extends EntityTableSimple<EntityTableSimpleProps> {

    protected getCellContent({ rowIndex, columnKey }: CellProps, fieldDescriptorChain?: FieldDescriptor[]): Optional<{ fieldDescriptor?: Optional<FieldDescriptor>, content: any, entity?: any }> {
        const cellContent = super.getCellContent({ rowIndex, columnKey }, fieldDescriptorChain);
        return { ...cellContent, content: this.getDndContent(cellContent!.content, rowIndex!) };
    }

    getDndContent(content: any, rowIndex: number) {
        return <AbstractGantt.Context.Consumer>
            {(ganttProps) => {
                const group = ganttProps.groups?.[rowIndex];
                if (!group || !group.entityUid) {
                    return null;
                }
                const cell = <div data-item-index={group.entityUid}> {content} </div>;
                return ganttProps.useDnD ? <Droppable item={group} accept={DragAndDropContoller.INSTANCE.getAcceptedType(group.entityUid)} canDrop={DragAndDropContoller.INSTANCE.canDrop} drop={DragAndDropContoller.INSTANCE.onDrop}>
                    <Draggable item={group} type={this.props.entityDescriptor.name} >
                        {cell}
                    </Draggable>
                </Droppable> : cell;
            }
            }
        </AbstractGantt.Context.Consumer>;
    }
});
