import { apolloClient, ConnectedPageInfo, createSliceFoundation, getBaseImpures, getBaseReducers, PropsFrom, StateFrom, Optional } from "@crispico/foundation-react";
import { TabbedPage } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import React from "react";
import { LOAD_INVENTORY, LOAD_INVENTORY_SETTINGS } from "./queries";
import { Segment, Grid, Menu, Header, List, Input, Button } from "semantic-ui-react";
import { InventoryData } from "apollo-gen/InventoryData";
import { loadInventory, loadInventoryVariables } from "apollo-gen/loadInventory";
import gql from "graphql-tag";
import { loadInventorySettings, loadInventorySettings_inventoryHistoryDataService_inventorySettings as InventorySettings } from "apollo-gen/loadInventorySettings";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ID } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";


export const sliceInventoryPage = createSliceFoundation(class SliceInventoryPage {
    initialState = {
        activeGridName: "" as string | null,
        entityList: [] as any,
        currentEntity: undefined as any
    }

    reducers = {
        ...getBaseReducers<SliceInventoryPage>(this),

        handleMenuClick(state: StateFrom<SliceInventoryPage>, name: string) {
            state.activeGridName = name;
        },

        handleInputChange(state: StateFrom<SliceInventoryPage>, event: any) {
            if (event && event.target) {
                for (let zoneIndex = 0; zoneIndex < state.entityList.length; zoneIndex++) {
                    let zone = state.entityList[zoneIndex].zone;
                    if (zone === state.activeGridName) {
                        state.entityList[zoneIndex].counters[event.target.name] = event.target.value.replace(/\D/,'');
                        break;
                    } else {
                        continue;
                    }
                }
            }
        }

    }

    impures = {
        ...getBaseImpures<SliceInventoryPage>(this),

        extractDriverFromLocalStorage() {
            let driverInfo = [];
            driverInfo[0] = "Test";
            driverInfo[1] = "TestId";
            return driverInfo;
        },

        async saveInventory() {
            for (let zoneIndex = 0; zoneIndex < this.getState().entityList.length; zoneIndex++) {
                let inventoryHistoryData = {} as any;
                let zone = this.getState().entityList[zoneIndex].zone;
                let counters = this.getState().entityList[zoneIndex].counters;
                if (counters["AKH"]?.length > 0 || counters["AKE"]?.length > 0 || counters["CPC"]?.length > 0 || counters["CPB"]?.length > 0) {
                    if (this.getState().currentEntity) {
                        inventoryHistoryData = { ...this.getState().currentEntity};
                    } else {
                        let driverInfo = this.extractDriverFromLocalStorage();
                        inventoryHistoryData["driverName"] = driverInfo[0];
                        inventoryHistoryData["driverIdentifier"] = driverInfo[1];
                        inventoryHistoryData["creationDate"] = new Date();
                        inventoryHistoryData["zone"] = zone;
                    }
                    inventoryHistoryData["akhData"] = counters["AKH"];
                    inventoryHistoryData["akeData"] = counters["AKE"];
                    inventoryHistoryData["cpcData"] = counters["CPC"];
                    inventoryHistoryData["cpbData"] = counters["CPB"];

                    
                    let fieldsAndValues = {} as { [key: string]: any };
                    ["driverName", "driverIdentifier", "creationDate", "zone", "akhData", "akeData", "cpcData", "cpbData"].forEach(f => {
                        if (f !== "id") {
                            fieldsAndValues[f] = inventoryHistoryData[f];
                        }
                    });
                    await apolloClient.mutate({
                        mutation: gql(`mutation q($params: SaveParams_LongInput) { 
                            inventoryHistoryDataService_save(params: $params) { ${ID} }
                        }`),
                        variables: { params : {
                            id: inventoryHistoryData,
                            fieldsAndValues: fieldsAndValues
                        } }
                    });
                }
            }
        },

        async loadInventory(entityId: number) {
            const filter = Filter.create("id", FilterOperators.forNumber.equals, entityId.toString());
            const inventoryEntries: Optional<InventoryData[]> = (await apolloClient.query<loadInventory, loadInventoryVariables>({
                query: LOAD_INVENTORY,
                variables: FindByFilterParams.create().filter(filter)
            })).data.inventoryHistoryDataService_findByFilter?.results;
            
            if (inventoryEntries && inventoryEntries.length > 0) {
                var entityList= [];
                let currentEntity = undefined;
                for (let inventoryEntry of inventoryEntries) {
                    currentEntity = inventoryEntry;
                    entityList.push({"zone": inventoryEntry["zone"], "counters": { "AKH": inventoryEntry["akhData"], "AKE": inventoryEntry["akeData"], "CPC": inventoryEntry["cpcData"], "CPB": inventoryEntry["cpbData"] }})
                }

                this.getDispatchers().setInReduxState({entityList: entityList, activeGridName: entityList[0]["zone"] as string, currentEntity: currentEntity});
            }
        },

        async loadSettings() {
            const inventorySettings: Optional<InventorySettings> = (await apolloClient.query<loadInventorySettings>({
                query: LOAD_INVENTORY_SETTINGS,
            })).data.inventoryHistoryDataService_inventorySettings;


            let activeGridName = null;
            let entityList = [];
            if (inventorySettings && inventorySettings.zones) {
                for (let zoneIndex = 0; zoneIndex < inventorySettings.zones.length; zoneIndex++) {
                    let zone = inventorySettings.zones[zoneIndex];
                    if (zone == null) {
                        continue;
                    }
                    
                    if (!activeGridName) {
                        activeGridName = zone.name;
                    }

                    let counters = {} as any;
                    if (zone.counters) {
                        for (let counterIndex = 0; counterIndex < zone.counters.length; counterIndex++) {
                            counters[zone.counters[counterIndex]?.name!] = "";
                        }
                    }

                    entityList.push({ "zone": zone.name, "counters": counters })
                }

                this.getDispatchers().setInReduxState(
                    {
                        activeGridName: activeGridName,
                        currentEntity: undefined as any,
                        entityList: entityList
                    }
                );
            }
        }
    }
})

type Props = PropsFrom<typeof sliceInventoryPage> & {
    entityId?: any;
}

export class InventoryPage extends TabbedPage<Props> {
    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        if (this.props.entityId && !prevProps?.entityId) {
            this.props.dispatchers.loadInventory(this.props.entityId);
        } else if (!this.props.entityId && !prevProps) {
            this.props.dispatchers.loadSettings();
        }
    }

    protected getTitle() {
        return _msg("InventoryPage.title");
    }

    protected renderZoneGridItems(menuEntry:any) {
        const props = this.props;
        let zone = menuEntry.zone;
        if (zone === props.activeGridName) {
            let listItems = [];
            for (let key in menuEntry.counters) {
                listItems.push(
                    <List.Item><Input fluid label={key} name={key} value={menuEntry.counters[key]} onChange={props.dispatchers.handleInputChange} /></List.Item>
                );
            }
            return listItems;
        } else {
            return;
        }
    }

    protected renderMenuItem(menuEntry:any) {
        const props = this.props;
        let zone = menuEntry.zone;
        return (
            <Menu.Item
                name={zone}
                key={zone}
                active={props.activeGridName === zone}
                onClick={() => props.dispatchers.handleMenuClick(zone)} >
            </Menu.Item>);
    }

    protected renderMain() {
        const props = this.props;
        return (<>
            <Segment>
                <Header size='large' textAlign='center'> {this.getTitle()} </Header>
                <Grid>
                    <Grid.Column width={4}>
                        <Menu fluid vertical tabular color="blue">
                            {props.entityList.map((menuEntry: any) => this.renderMenuItem(menuEntry))}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column stretched width={12}>
                        <Segment>
                            <Header size='medium' textAlign='center' block>{_msg("invoicePDF.number") + " - " + props.activeGridName}</Header>
                            <List>
                                {props.entityList.map((menuEntry: any) => this.renderZoneGridItems(menuEntry))}
                            </List>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
            <div>
                <Button fluid primary onClick={() => props.dispatchers.saveInventory()}>{_msg("InventoryPage.save")}</Button>
            </div>
        </>
        )
            ;
    }
}

export const infoInventoryPage = new ConnectedPageInfo(sliceInventoryPage, InventoryPage, "InventoryPage");
