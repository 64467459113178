import { addEntityDescriptor, EntityDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react/entity_crud/EntityTablePage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ChecklistReportTab, sliceChecklistReportTab } from "./ChecklistReport";
import { ConnectedComponentInSimpleComponent, ConnectedPageInfo } from "@crispico/foundation-react/reduxHelpers";
import { ChecklistEntityEditorPage, sliceChecklistEntityEditorPage } from "./ChecklistEditorPage";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

class ChecklistEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "Checklist",
            miniFields: ["template", "vehicle", "user"],
            icon: "list ol",
            defaultSort: { field: "creationDate", direction: "DESC" },
            defaultFilter: Filter.createForClient("creationDate", FilterOperators.forDate.today),
            toMiniString(entityWithMiniFields: any): string {
                return entityWithMiniFields.template + ', ' + entityDescriptors['EquipmentResource'].toMiniString(entityWithMiniFields.vehicle) + ', ' + entityDescriptors['User'].toMiniString(entityWithMiniFields.user)
            }
        });
    }

    renderTable() {
        return <ChecklistEntityTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

export const checklistEntityDescriptor = addEntityDescriptor(new ChecklistEntityDescriptor()
    .isInDefaultColumnConfig(true, "creationDate", "user", "vehicle", "template")
    .addFieldDescriptor({ name: "id", type: FieldType.string, enabled: false })
    // .addFieldDescriptor({ name: "mission", type: FieldType.defaultManyToOne })
);

checklistEntityDescriptor.infoEditor.slice = sliceChecklistEntityEditorPage.setEntityDescriptor(checklistEntityDescriptor)
checklistEntityDescriptor.infoEditor.wrappedComponentClass = ChecklistEntityEditorPage

class ChecklistEntityTablePage extends EntityTablePage<EntityTablePageProps> {
    protected getExtraTabPanes() {
        return [{
            routeProps: { path: "/Report" },
            menuItemProps: { content: _msg("Checklist.report"), icon: "chart pie" },
            render: () => <ConnectedComponentInSimpleComponent info={new ConnectedPageInfo(sliceChecklistReportTab, ChecklistReportTab, "checkListReportTab")} />
        }];
    }
}

const ChecklistEntityTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, ChecklistEntityTablePage);
