import { EntityDescriptor } from "@crispico/foundation-react";

const FIELDS_FROM_DATA = ["observation", "statusChangedAt", "stopInfo", "flight", "objectActionInfo"];

export class MissionEvent2EntityDescriptor extends EntityDescriptor {
    protected customize() {
        this.isInDefaultColumnConfig(true, "creationdate", "descriptorType", "missionId", "oagId", "stopId", "taskId", "deleted", "userId", "generationSource")
        this.doForFields(FIELDS_FROM_DATA, fd => fd.filterable = false);
        this.doForFields(FIELDS_FROM_DATA, fd => fd.sortable = false);
    }
}
