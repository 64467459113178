import { FilterOperators } from "@crispico/foundation-gwt-js";
import { apolloClient, DummyToRememberPeopleToCast, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ModalExt, Severity } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { SelectExt, SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { AssociationEditorProps, AssociationExtraProps, AssociationFieldEditor, QUERY_LIMIT_NUMBER } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { FormikProps } from "formik";
import moment from "moment";
import { ReactElement, ReactNode } from "react";
import { Button, Checkbox, Icon, SemanticICONS, TextArea } from "semantic-ui-react";
import { CREATE_ACTION_FROM_FLIGHT, DELETE_COMMENT_FROM_ACTION, LOAD_ROTATION_FLIGHTS } from "./queries";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, IFormValuesHolder, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import { FindByStringParams } from "@crispico/foundation-react/entity_crud/FindByStringParams";
import { AppContainerContext } from "@crispico/foundation-react/AppContainerContext";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";

export class DefaultShowFlightInGanttFieldDescriptor extends FieldDescriptor {
    optionMessages = [
        _msg("flight.defaultShowFlightInGantt.leaveUnset.label"),
        _msg("flight.defaultShowFlightInGantt.doNotShow.label"),
        _msg("flight.defaultShowFlightInGantt.showInGantt.label")
    ];

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
        return <span>
            {!entity["defaultShowFlightInGantt"] ? this.optionMessages[0] : this.optionMessages[entity["defaultShowFlightInGantt"]]}
        </span>;
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        let options: SelectExtOption[] = this.optionMessages.map((value, index) => ({
            label: value,
            value: index === 0 ? null : index
        }));

        return <div className="flex container FlightEditor_fieldsWithIgnore">
            <SelectExt options={options} isMulti={false} placeholder={_msg("flight.defaultShowFlightInGantt.selectAnOption.label")}
                closeMenuOnSelect value={!formikProps.values[this.name] ? options[0] : options[formikProps.values[this.name]]}
                onChange={(value) => formikProps.setFieldValue(this.name, value.value)} />
            {this.name !== "value" && <>
                <label className="FlightEditor_label">
                    {_msg("Flight.sariaShowFlightInGanttUpdateDisable.label")}
                </label><Checkbox className="FlightEditor_ignoreCheckBox" checked={formikProps.values["sariaShowFlightInGanttUpdateDisable"]}
                    onChange={() => formikProps.setFieldValue("sariaShowFlightInGanttUpdateDisable", !formikProps.values["sariaShowFlightInGanttUpdateDisable"])} />
            </>}
        </div>;
    }
}

export class ParkingFieldDescriptor extends FieldDescriptor {
    protected getFieldToUpdate() {
        return "sariaParkingUpdateDisable";
    }

    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return <div className="flex container FlightEditor_fieldsWithIgnore">
            {super.renderFieldEditor(formikProps, additionalFieldEditorProps)}
            {this.name !== "value" && <>
                <label className="FlightEditor_label">
                    {_msg("Flight.sariaIgnore.label")}
                </label><Checkbox className="FlightEditor_ignoreCheckBox" checked={formikProps.values[this.getFieldToUpdate()]}
                    onChange={() => formikProps.setFieldValue(this.getFieldToUpdate(), !formikProps.values[this.getFieldToUpdate()])} />
            </>}
        </div>;
    }
}

export class PaxParkingFieldDescriptor extends ParkingFieldDescriptor {
    protected getFieldToUpdate() {
        return "sariaPaxParkingUpdateDisable";
    }
}

export class PaxNoFieldDescriptor extends ParkingFieldDescriptor {
    protected getFieldToUpdate() {
        return "sariaPaxUpdateDisable";
    }
}

export class RotationFlightFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: FormikProps<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast) {
        return this.name !== "value" ?
            <RotationFlightFieldEditor formikProps={formikProps} fieldDescriptor={this}
                innerEntityDescriptor={entityDescriptors["Flight"]} /> :
            <AssociationFieldEditor formikProps={formikProps} fieldDescriptor={this}
                innerEntityDescriptor={entityDescriptors["Flight"]} />;
    }
}

export class CommentFieldDescriptor extends FieldDescriptor {
    renderFieldEditor(formikProps: IFormValuesHolder<any>, additionalFieldEditorProps?: DummyToRememberPeopleToCast): ReactElement {
        return <CommentFieldEditor formikProps={formikProps} fieldDescriptor={this} />;
    }
}

interface CommentFieldEditorState {
    openCommentConfirmationModal: boolean,
    headerMessage: string
}

class CommentFieldEditor extends FieldEditor<string, FieldEditorNotUsableStandAloneProps, CommentFieldEditorState> {
    constructor(props: FieldEditorNotUsableStandAloneProps) {
        super(props);

        this.state = {
            openCommentConfirmationModal: false,
            headerMessage: ""
        };
    }

    protected renderRecurrentButton(formikProps: IFormValuesHolder<any>, iconName: SemanticICONS, positive: boolean, className?: string) {
        // There were problems with some fields from data which are not existing on FlightInput and I send
        // only the fields that are used on Java
        let { airline, number, departure } = formikProps.values;
        let flight = { airline, number, departure }

        return <Button positive={positive} disabled={!positive && !formikProps.values["recurrentComment"]}
            negative={!positive} className={className} onClick={async () => {
                let query = positive ? CREATE_ACTION_FROM_FLIGHT : DELETE_COMMENT_FROM_ACTION;
                let variables = positive ? { flight, comment: formikProps.values["comment"] } : { flight };
                let queryName = positive ?
                    "flightAndObjectActionService_createActionFromFlight" :
                    "flightAndObjectActionService_removeCommentFromAction";

                let response = (await apolloClient.query({ query, variables })).data[queryName];

                if (response) {
                    this.setState({
                        openCommentConfirmationModal: true,
                        headerMessage: positive ? _msg("flight.editor.actionCreated.label") : _msg("flight.editor.commentRemoved.label")
                    });

                    formikProps.setFieldValue("recurrentComment", positive);
                }
            }}>
            <div className="FlightEditor_recurrentButtonContent">
                <Icon name={iconName} />{_msg("flight.editor.recurent.label")}
            </div>
        </Button>
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return <>
            <div className="flex container FlightEditor_commentContainer">
                <div className="FlightEditor_commentColumn">
                    <TextArea className="FlightEditor_commentTextarea" value={this.getValue()}
                        onChange={((e, data) => s.setFieldValue(hw, data.value))} />
                </div>
                <div className="FlightEditor_columnButtons">
                    {this.renderRecurrentButton(this.props.formikProps, "plus", true, "FlightEditor_addButton")}
                    {this.renderRecurrentButton(this.props.formikProps, "close", false)}
                </div>
            </div>
            <ModalExt
                open={this.state.openCommentConfirmationModal}
                size={"small"}
                onClose={() => this.setState({
                    openCommentConfirmationModal: false
                })}
                severity={Severity.INFO}
                header={this.state.headerMessage}
                content={_msg("flight.editor.confirmation.label")}
                actions={[
                    <Button primary onClick={() => this.setState({ openCommentConfirmationModal: false })}>
                        {_msg("general.ok")}
                    </Button>
                ]} />
        </>;
    }
}
export class FlightPlaneFieldDescriptor extends FieldDescriptor {    
    
    static ARRIVAL = "arrivalPlaneIcon";
    static DEPARTURE = "departurePlaneIcon";

    constructor(name: string) {
        super();
        this.name = name;
        this.getLabel = () => {
            return "";
        }
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
        this.isInDefaultColumnConfigForEditor = false;
    }

    getAppearsInUi(): boolean {
        return true;
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        let degrees = (this.name === FlightPlaneFieldDescriptor.ARRIVAL) ? 45 : (this.name === FlightPlaneFieldDescriptor.DEPARTURE ? -45 : 0);
        return <> 
            <Icon name="plane" style={{ transform: "rotate(" + degrees + "deg)" }} />
        </>;
    }
}

export class RotationFlightFieldEditor extends AssociationFieldEditor<FieldEditorProps & AssociationEditorProps & AssociationExtraProps> {
    static contextType = AppContainerContext;
    context!: XopsAppContainerContextValue;

    protected createFindByStringParams(searchQuery: string): FindByStringParams {
        const p = super.createFindByStringParams(searchQuery);
        const additionalFilters: Filter [] = [];
        const rotationFlightOffset = this.context.initializationsForClient.flightSettings.rotationFlightOffset;

        additionalFilters.push(Filter.create("departure", FilterOperators.forBoolean.equals, (!this.props.formikProps.values["departure"]).toString()));
        this.props.formikProps.values["departure"] ? 
            additionalFilters.push(Filter.create("date", FilterOperators.forDate.between, moment(this.props.formikProps.values["date"]).subtract(rotationFlightOffset, "hours").toISOString() + Filter.AND_DELIMITER + moment(this.props.formikProps.values["date"]).toISOString())) :
            additionalFilters.push(Filter.create("date", FilterOperators.forDate.between, moment(this.props.formikProps.values["date"]).toISOString() + Filter.AND_DELIMITER + moment(this.props.formikProps.values["date"]).add(rotationFlightOffset, "hours").toISOString()))
        
        let { enabled, label, ...composedFilter } = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, Utils.isNullOrEmpty(p.params.filter) ? additionalFilters : [ p.params.filter as Filter, ...additionalFilters ]);
        p.params.filter = composedFilter;
        return p;
    }
}