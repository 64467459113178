import { Optional, Utils } from '@crispico/foundation-react';
import { ID, Location, MarkerData, MARKER_TYPE, PolygonData, POLYGON_TYPE, PolylineData, POLYLINE_TYPE, IDData, HeatData, HEAT_TYPE, SelectedLayer, HoveredLayer, MapContainerLeaflet, SELECTED_BACKGROUND, HOVER_BACKGROUND } from 'components/MapContainerLeaflet/MapContainerLeaflet';
import lodash from 'lodash';

// keep L import above leaflet plugins, otherwise errors on dev
import * as L from 'leaflet';
import { LeafletMouseEvent } from 'leaflet';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import 'leaflet.markercluster';
import 'leaflet-polylinedecorator';
import 'leaflet.heat';
import React from 'react';
import { renderToStaticMarkup } from "react-dom/server";

export const DEFAULT_POLYGON_COLOR: string = "#3A527B";
const DEFAULT_COLOR: string = "#00116633";
const SELECT_COLOR: string = "#ff726f";
const HOVER_COLOR: string = "#6ab7ff";
const TRACK_HOVER_POINT_RADIUS: number = 8;

export const DEFAULT_ZOOM_LEVEL: number = 13;

/**
 * There are some problems setting icon in marker's mouseover event, so no style was added for hovered!
 */
export class LayerHelper<T = any, L = any, D extends IDData = any> {

    protected mapContainer: MapContainerLeaflet;
    protected type: string;
    protected groupLayer: T;
    protected cache: { [key: string]: L } = {};

    protected popupOffset: L.PointExpression = [0, 0];
    protected tooltipSticky: boolean = false;

    constructor(type: string, mapContainer: MapContainerLeaflet) {
        this.type = type;
        this.mapContainer = mapContainer;
        this.groupLayer = this.createGroupLayerInstance();
    }

    protected createGroupLayerInstance(): T {
        throw new Error("Method createGroupLayerInstance must be implemented!");
    }

    getLayerGroup(): T {
        return this.groupLayer;
    }

    getLayerGroupType(): string {
        throw new Error("Method getLayerGroupType must be implemented!");
    }

    getLeafletLayer(marker: L): L.Layer {
        return marker as unknown as L.Layer;
    }

    getLayerById(layerId: any): L | undefined {
        return this.cache[layerId];
    }

    getLayerData(layer: L): D {
        return (layer as any).layerData;
    }

    getVisibleLayers(): L.Layer[] {
        throw new Error("Method getVisibleLayers must be implemented!");
    }

    addOrUpdateLayers(list: D[]) {
        if (!this.groupLayer) {
            throw Error("Unknown layer with type=" + this.type + "! See 'layers' props on map!");
        }

        list.forEach(data => {
            if (!data.id) {
                return;
            }
            const item: { layer: L, isNew: boolean } = this.addOrUpdateLayer(data);
            this.cache[data.id] = item.layer;
            if (item.isNew) {
                this.add(item.layer);
                this.additionalConfigs(data.id);
            }
        });
    }

    protected add(layer: L) {
        throw new Error("Method add must be implemented!");
    }

    protected addOrUpdateLayer(data: D): { layer: L, isNew: boolean } {
        throw new Error("Method addOrUpdateLayer must be implemented!");
    }

    protected additionalConfigs(layerId: any) {
        // if there is a point on this that must be hightlighted, do it!
        this.mapContainer.props.s.highlightedPointsOnLayer
            .filter(point => point.layerType === this.type && point.layerId === layerId)
            .map(point => this.highlightPoint(point.layerId, point.pointId));

        if (this.mapContainer.props.s.selectedLayer?.type === this.type && this.mapContainer.props.s.selectedLayer?.id === layerId) {
            this.selectLayer(this.mapContainer.props.s.selectedLayer);
        }
        if (this.mapContainer.props.s.hoveredLayer?.type === this.type && this.mapContainer.props.s.hoveredLayer?.id === layerId) {
            this.hoverLayer(this.mapContainer.props.s.hoveredLayer);
        }
    }

    protected addEventsOnLayer(layer: L.Layer, layerData: any) {
        layer.on('click', (e: L.LeafletMouseEvent) => {
            if (this.mapContainer.props.s.editMode) {
                return;
            }
            this.mapContainer.props.r.setInReduxState({ selectedLayer: { id: layerData.id, type: this.type, additionalInfo: this.getAdditionalInfoFromEvent(layerData, e) } });
            this.mapContainer.props.onSelectLayer?.call(null, this.mapContainer.props.s.selectedLayer);
        });
        layer.on('mouseover', (e: LeafletMouseEvent) => {
            if (this.mapContainer.props.s.editMode) {
                return;
            }
            this.mapContainer.props.r.setInReduxState({ hoveredLayer: { id: layerData.id, type: this.type, additionalInfo: this.getAdditionalInfoFromEvent(layerData, e) } });
            this.mapContainer.props.onHoverLayer?.call(null, this.mapContainer.props.s.hoveredLayer);
        });
        layer.on('mouseout', (e: any) => {
            if (this.mapContainer.props.s.editMode) {
                return;
            }
            this.mapContainer.props.r.setInReduxState({ hoveredLayer: undefined });
            this.mapContainer.props.onHoverLayer?.call(null);
        });
    }

    removeLayer(layerId: any) {
        delete this.cache[layerId];
    }

    removeLayers() {
        this.cache = {};
    }

    updateStyle(layer: L) {
    }

    selectLayer(data: SelectedLayer) {
        const layer = this.getLayerById(data.id);
        if (!layer) {
            return;
        }
        if (this.mapContainer.props.layers[this.type].options?.flyToSelectedMarker || data.additionalInfo?.flyToLayer) {
            this.flyTo(layer);
        }
        this.updateStyle(layer);
        if (layer && data.additionalInfo?.showPopup) {
            this.openPopup(layer);
        }
    }

    unselectLayer(layerId: any) {
        const layer = this.getLayerById(layerId);
        this.mapContainer.getMap().closePopup();
        if (!layer) {
            return;
        }
        this.updateStyle(layer);
    }

    hoverLayer(data: HoveredLayer) {
        const layer = this.getLayerById(data.id);
        if (!layer) {
            return;
        }
        this.updateStyle(layer);

        if (layer && !this.mapContainer.props.layers[this.type].options?.hideTooltipOnHoveredLayer) {
            this.openTooltip(layer, data.additionalInfo?.pointId);
        }
    }

    unhoverLayer(layerId: any) {
        const layer = this.getLayerById(layerId);
        if (!layer) {
            return;
        }
        this.updateStyle(layer);
        this.closeTooltip(layer);
    }

    openPopup(layer: L) {
        L.popup({ offset: this.popupOffset })
            .setLatLng(this.getLayerCenter(layer))
            .setContent(this.getLayerPopupContent(layer))
            .openOn(this.mapContainer.getMap());
    }


    closePopup(layer: Optional<L>) {
        if (layer) {
            this.getLeafletLayer(layer).closePopup();
        }
    }

    openTooltip(layer: L, pointId?: ID) {
        const leafletMarker = this.getLeafletLayer(layer);
        const content = this.getLayerTooltipContent(layer);
        if (content !== undefined) {
            if (leafletMarker.getTooltip()) {
                leafletMarker.setTooltipContent(content);
            } else {
                leafletMarker.bindTooltip(content, { direction: "top", sticky: this.tooltipSticky });
            }
            leafletMarker.openTooltip(this.getTooltipPosition(layer, pointId));
        }
    }

    protected getTooltipPosition(layer: L, pointId?: ID): L.LatLngExpression | undefined {
        return undefined;
    }

    protected getAdditionalInfoFromEvent(layerData: D, e: LeafletMouseEvent) {
        return undefined;
    }

    closeTooltip(layer: Optional<L>) {
        if (layer) {
            this.getLeafletLayer(layer).closeTooltip();
        }
    }

    getLayerPopupContent(layer: L) {
        return this.mapContainer.props.renderPopupContent ? renderToStaticMarkup(this.mapContainer.props.renderPopupContent(this.getLayerData(layer), this.type)) : "";
    }

    getLayerTooltipContent(layer: L) {
        let content = undefined;
        if (this.mapContainer.props.renderTooltipContent) {
            content = this.mapContainer.props.renderTooltipContent(this.getLayerData(layer), this.type, this.mapContainer.props.s.hoveredLayer?.additionalInfo);
            if (content !== undefined) {
                content = renderToStaticMarkup(content);
            }
        }
        return content;
    }


    getLayerCenter(layer: L): L.LatLngExpression {
        throw new Error("Method getLayerCenter must be implemented!");
    }

    flyTo(layer: L) {
        this.mapContainer.getMap().setView(this.getLayerCenter(layer), this.mapContainer.getMap().getZoom());
    }

    highlightPoint(layerId: ID, pointId: ID) {
    }

    unhighlightPoint(layerId: ID, pointId?: ID) {
    }
}

class BasicLayerHelper<L extends L.Layer, D extends IDData> extends LayerHelper<L.LayerGroup, L, D> {

    protected createGroupLayerInstance(): L.LayerGroup {
        return new L.LayerGroup();
    }

    protected add(layer: L) {
        this.groupLayer.addLayer(layer);
    }

    removeLayer(markerId: any) {
        if (!this.cache[markerId]) { return }
        this.groupLayer.removeLayer(this.cache[markerId]);
        super.removeLayer(markerId);
    }

    removeLayers() {
        super.removeLayers();
        this.groupLayer.clearLayers();
    }

}

export class ClusterHelper<T = any, L = any> extends LayerHelper<T, L> {

    constructor(type: string, mapContainer: MapContainerLeaflet) {
        super(type, mapContainer);
        this.getMarkerIcon = this.getMarkerIcon.bind(this);
        this.getLayerPopupContent = this.getLayerPopupContent.bind(this);

        this.popupOffset = [-6, -12];
    }

    getLayerGroupType(): string {
        return MARKER_TYPE;
    }

    getMarkerIconContent(markerData: MarkerData, additionalStyles?: { selected?: boolean, hovered?: boolean }): string {
        return renderToStaticMarkup(<div className="MapContainer_Marker_circle" style={{ backgroundColor: additionalStyles?.selected && !this.mapContainer.props.layers[this.type].options?.hideStyleOnSelectedLayer ? SELECTED_BACKGROUND : additionalStyles?.hovered && !this.mapContainer.props.layers[this.type].options?.hideStyleOnHoveredLayer ? HOVER_BACKGROUND : 'transparent' }}>
            {this.mapContainer.props.renderMarkerIcon?.call(null, markerData, this.type, additionalStyles) || ""}
        </div>);
    }

    getMarkerIcon(markerData: MarkerData, additionalStyles?: { selected?: boolean, hovered?: boolean }) {
        return L.divIcon({
            html: this.getMarkerIconContent(markerData, additionalStyles),
            iconAnchor: [24, 24],
            tooltipAnchor: [0, -24],
            className: ''
        });
    }

    updateStyle(layer: L) {
        const data: MarkerData = this.getLayerData(layer);
        const additionalStyles = { selected: this.mapContainer.props.s.selectedLayer?.id === data.id, hovered: this.mapContainer.props.s.hoveredLayer?.id === data.id };

        const l = this.getLeafletLayer(layer);
        if (l) {
            (l as L.Marker).setIcon(this.getMarkerIcon(this.getLayerData(layer), additionalStyles));
        }
    }

    getVisibleLayers() {
        const visibleLayers: L.Layer [] = [];    
        if (this.groupLayer instanceof L.LayerGroup) {
            this.groupLayer.getLayers().forEach((layer:  L.Layer) => {
                if(this.mapContainer.getMap().getBounds().contains((layer as L.Marker).getLatLng())) {
                    visibleLayers.push(layer);
                }
            });
        }       
        return visibleLayers;
    }
}

export class ClassicGroupHelper extends ClusterHelper<L.LayerGroup, L.Marker<any>> {

    protected createGroupLayerInstance(): L.LayerGroup {
        return L.layerGroup([]);
    }

    getLayerData(marker: L.Marker<any>): MarkerData {
        return (marker as any).markerData;
    }

    addOrUpdateLayers(list: MarkerData[]) {
        let toAdd: L.Marker<any>[] = [];
        list.forEach(m => {
            var marker: Optional<L.Marker<any>> = this.cache[m.id!];
            if (marker) {
                let updated = false;
                if (!marker.getLatLng().equals([m.point.latitude, m.point.longitude])) {
                    marker.setLatLng([m.point.latitude, m.point.longitude]);
                    updated = true;
                }
                const currentData = (marker as any).markerData as MarkerData;
                if (!lodash.isEqual(currentData, m)) {
                    (marker as any).markerData = m;
                    updated = true;
                }
                if (updated) {
                    this.cache[m.id!] = marker;
                    this.updateStyle(marker);
                }
            } else {
                marker = L.marker([m.point.latitude, m.point.longitude], { contextmenu: false, contextmenuItems: [], icon: this.getMarkerIcon(m) });
                (marker as any).markerData = m;
                this.addEventsOnLayer(marker, m);
                toAdd.push(marker);
            }
            this.cache[m.id!] = marker;
        });
        toAdd.forEach(marker => {
            this.groupLayer.addLayer(marker);
            this.additionalConfigs((marker as any).markerData.id);
        });
    }

    removeLayer(markerId: any) {
        if (!this.cache[markerId]) { return }
        this.groupLayer.removeLayer(this.cache[markerId]);
        super.removeLayer(markerId);
    }

    removeLayers() {
        super.removeLayers();
        this.groupLayer.clearLayers();
    }

    getLayerCenter(marker: L.Marker) {
        return marker.getLatLng();
    }

}

export class ClassicClusterHelper extends ClusterHelper<L.MarkerClusterGroup, L.Marker<any>> {

    protected createGroupLayerInstance(): L.MarkerClusterGroup {
        return L.markerClusterGroup({ spiderfyDistanceMultiplier: 2, maxClusterRadius: 40 });
    }

    getLayerData(marker: L.Marker<any>): MarkerData {
        return (marker as any).markerData;
    }

    addOrUpdateLayers(list: MarkerData[]) {
        let toAdd: L.Marker<any>[] = [];
        list.forEach(m => {
            var marker: Optional<L.Marker<any>> = this.cache[m.id!];
            if (marker) {
                let updated = false;
                if (!marker.getLatLng().equals([m.point.latitude, m.point.longitude])) {
                    marker.setLatLng([m.point.latitude, m.point.longitude]);
                    updated = true;
                }
                const currentData = (marker as any).markerData as MarkerData;
                if (!lodash.isEqual(currentData, m)) {
                    (marker as any).markerData = m;
                    updated = true;
                }
                if (updated) {
                    this.cache[m.id!] = marker;
                    this.updateStyle(marker);
                }
            } else {
                marker = L.marker([m.point.latitude, m.point.longitude], { contextmenu: false, contextmenuItems: [], icon: this.getMarkerIcon(m) });
                (marker as any).markerData = m;
                this.addEventsOnLayer(marker, m);
                toAdd.push(marker);
                this.cache[m.id!] = marker;
            }

        });
        if (toAdd.length > 0) {
            this.groupLayer.addLayers(toAdd);
        }
        toAdd.forEach(marker => {
            this.additionalConfigs((marker as any).markerData.id);
        });
    }

    removeLayer(markerId: any) {
        if (!this.cache[markerId]) { return }
        this.groupLayer.removeLayer(this.cache[markerId]);
        super.removeLayer(markerId);
    }

    removeLayers() {
        super.removeLayers();
        this.groupLayer.clearLayers();
    }

    getLayerCenter(marker: L.Marker) {
        return marker.getLatLng();
    }

    flyTo(marker: L.Marker) {
        // https://github.com/Leaflet/Leaflet.markercluster/issues/954#issuecomment-529728174
        const clusterBounds = (marker as any).__parent.getBounds();
        this.mapContainer.getMap().flyTo(this.getLayerCenter(marker), this.mapContainer.getMap().getBoundsZoom(clusterBounds), { duration: 0.2 });
        this.groupLayer.zoomToShowLayer(marker);
    }

    selectLayer(data: SelectedLayer) {
        const layer = this.getLayerById(data.id);
        if (!layer) {
            return;
        }
        this.groupLayer.zoomToShowLayer(layer, () => super.selectLayer(data));
    }

}

export class PolygonLayerHelper extends BasicLayerHelper<L.Polygon, PolygonData> {

    getLayerGroupType(): string {
        return POLYGON_TYPE;
    }

    getLayerData(layer: L.Polygon): PolygonData {
        let data: PolygonData = (layer as any).layerData;
        data = { ...data, readableArea: L.GeometryUtil.readableArea(L.GeometryUtil.geodesicArea((layer.getLatLngs()[0] as L.LatLng[])), true) };
        return data;
    }

    protected addOrUpdateLayer(data: PolygonData): { layer: L.Polygon, isNew: boolean } {
        var polygon: Optional<L.Polygon> = this.getLayerById(data.id);
        let isNew: boolean = false;

        var coordinates: L.LatLngExpression[] = [];
        (data.points as Array<any>).forEach(point => coordinates.push([point.latitude, point.longitude]));

        if (!polygon) {
            polygon = new L.Polygon(coordinates, { bubblingMouseEvents: false });
            this.addEventsOnLayer(polygon, data);
            isNew = true;
        } else {
            polygon.setLatLngs(coordinates);
        }
        (polygon as any).layerData = data;
        this.updateStyle(polygon);

        return { layer: polygon, isNew: isNew };
    }


    getLayerCenter(layer: L.Polygon) {
        return layer.getCenter();
    }

    updateStyle(layer: L.Polygon) {
        const data: PolygonData = this.getLayerData(layer);
        const additionalStyles = { selected: this.mapContainer.props.s.selectedLayer?.id === data.id, hovered: this.mapContainer.props.s.hoveredLayer?.id === data.id };

        layer.setStyle({ color: additionalStyles?.selected ? SELECT_COLOR : additionalStyles?.hovered && !this.mapContainer.props.layers[this.type].options?.hideStyleOnHoveredLayer ? HOVER_COLOR : this.getLayerData(layer).color || DEFAULT_POLYGON_COLOR });
    }

    flyTo(layer: L.Polygon) {
        this.mapContainer.getMap().fitBounds(layer.getBounds(), { padding: [30, 30] });
    }

    getVisibleLayers() {
        const visibleLayers: L.Layer [] = [] 
        this.groupLayer.getLayers().forEach((layer: L.Layer) => {
            if(this.mapContainer.getMap().getBounds().contains((layer as L.Polygon).getBounds())) {
                visibleLayers.push(layer);
            }
        });
        return visibleLayers;
    }
}

export class PolylineLayerHelper extends BasicLayerHelper<L.FeatureGroup, PolylineData> {

    constructor(type: string, mapContainer: MapContainerLeaflet) {
        super(type, mapContainer);

        this.tooltipSticky = true;
    }

    protected createGroupLayerInstance(): L.FeatureGroup {
        return new L.FeatureGroup();
    }

    getLayerGroupType(): string {
        return POLYLINE_TYPE;
    }

    getLayerData(layer: L.FeatureGroup): PolylineData {
        let data: PolylineData = (layer as any).layerData;
        return data;
    }

    protected addOrUpdateLayer(data: PolylineData): { layer: L.FeatureGroup, isNew: boolean } {
        var polylineGroup: Optional<L.FeatureGroup> = this.getLayerById(data.id);
        let isNew: boolean = false;

        var coordinates: L.LatLngExpression[] = [];
        (data.points as Array<any>).forEach(point => coordinates.push([point.latitude, point.longitude]));

        if (!polylineGroup) {
            let color = data.color || DEFAULT_COLOR;
            let polyline = new L.Polyline(coordinates, { weight: 2, color: color, bubblingMouseEvents: false, renderer: L.svg({ tolerance: 5 }) });
            let polylineSelected = new L.Polyline(coordinates, { weight: 4, opacity: 0, bubblingMouseEvents: false });
            let polylineHighlightedPoints = new L.FeatureGroup();

            let decorator = L.polylineDecorator(polyline, {
                patterns: []
            });
            polylineGroup = new L.FeatureGroup([polylineSelected, decorator, polylineHighlightedPoints]);
            polylineGroup.addLayer(polyline);
            this.addEventsOnLayer(polylineGroup, data);

            isNew = true;
        } else {
            (polylineGroup.getLayers()[3] as L.Polyline).setLatLngs(coordinates);
        }
        (polylineGroup as any).layerData = data;
        this.updateStyle(polylineGroup);

        return { layer: polylineGroup, isNew: isNew };
    }

    getLayerCenter(polylineGroup: L.FeatureGroup) {
        return (polylineGroup.getLayers()[3] as L.Polyline).getCenter();
    }

    updateStyle(polylineGroupLayer: L.FeatureGroup) {
        const data: PolylineData = this.getLayerData(polylineGroupLayer);
        const additionalStyles = { selected: this.mapContainer.props.s.selectedLayer?.id === data.id, hovered: this.mapContainer.props.s.hoveredLayer?.id === data.id };

        const polyline = (polylineGroupLayer.getLayers()[3] as L.Polyline);
        const polylineSelected = (polylineGroupLayer.getLayers()[0] as L.Polyline);
        const decorator = (polylineGroupLayer.getLayers()[1] as L.PolylineDecorator);

        polyline.setStyle({ color: data.color || DEFAULT_COLOR });
        polylineSelected.setStyle({ opacity: additionalStyles?.selected || additionalStyles?.hovered ? 1 : 0, color: additionalStyles?.selected ? SELECT_COLOR : HOVER_COLOR });

        const color = additionalStyles?.selected ? SELECT_COLOR : additionalStyles?.hovered ? HOVER_COLOR : data.color || DEFAULT_COLOR;

        let circleSymbol = L.Symbol.marker({
            rotate: true, markerOptions: {
                contextmenu: false, contextmenuItems: [],
                icon:
                    L.divIcon({ className: '', iconAnchor: [6, 6], html: renderToStaticMarkup(<i className="fa fa-circle" style={{ color: color }}></i>) })
            }
        });

        decorator.setPatterns([
            { offset: 0, repeat: "0%", symbol: circleSymbol },
            { offset: "100%", repeat: "100%", symbol: circleSymbol },
            { offset: "20%", repeat: "10%", symbol: L.Symbol.arrowHead({ pixelSize: additionalStyles?.selected || additionalStyles?.hovered ? 8 : 6, pathOptions: { color: color } }) }
        ]);

        polyline.bringToFront();
    }

    flyTo(polylineGroup: L.FeatureGroup) {
        this.mapContainer.getMap().fitBounds((polylineGroup.getLayers()[3] as L.Polyline).getBounds(), { padding: [30, 30], animate: false });
    }

    highlightPoint(layerId: ID, pointId: ID) {
        const polylineGroupLayer: Optional<L.FeatureGroup> = this.getLayerById(layerId);
        if (!polylineGroupLayer) {
            return;
        }
        const highlightsGroupLayer = (polylineGroupLayer.getLayers()[2] as L.FeatureGroup);
        let data: PolylineData = this.getLayerData(polylineGroupLayer);

        let layer = highlightsGroupLayer.getLayers().find(layer => (layer as any).id === pointId);
        if (!layer) {
            const point = data.points.find(p => p.id === pointId);
            if (point) {
                const icon = createCircleWithBorder(TRACK_HOVER_POINT_RADIUS, "#000000", undefined, undefined, 2.5, 5);
                layer = new L.Marker([point.latitude, point.longitude], { contextmenu: false, contextmenuItems: [], icon: L.icon({ iconUrl: icon.url, iconAnchor: [icon.width / 2, icon.height / 2] }) });
                (layer as any).id = pointId;
                highlightsGroupLayer.addLayer(layer);
            }
        }
    }

    unhighlightPoint(layerId: ID, pointId?: ID) {
        const polylineGroupLayer: Optional<L.FeatureGroup> = this.getLayerById(layerId);
        if (!polylineGroupLayer) {
            return;
        }
        const highlightsGroupLayer = (polylineGroupLayer.getLayers()[2] as L.FeatureGroup);

        if (!pointId) {
            highlightsGroupLayer.clearLayers();
            return;
        }
        const layer = highlightsGroupLayer.getLayers().find(layer => (layer as any).id === pointId);
        if (layer) {
            highlightsGroupLayer.removeLayer(layer);
        }
    }

    protected getTooltipPosition(layer: L.FeatureGroup, pointId?: ID): L.LatLngExpression | undefined {
        const polylineGroupLayer: Optional<L.FeatureGroup> = layer;
        if (!polylineGroupLayer) {
            return undefined;
        }
        const point = this.getLayerData(polylineGroupLayer).points.find(p => p.id === pointId);

        return point ? L.latLng(point.latitude, point.longitude) : undefined;
    }

    protected getAdditionalInfoFromEvent(layerData: PolylineData, e: LeafletMouseEvent): any {
        const a: L.Point = new L.Point(e.latlng.lng, e.latlng.lat);
        let minDist: number;
        let closestPoint: Location | undefined;
        layerData.points.forEach((b: Location) => {
            const dist = a.distanceTo(new L.Point(b.longitude, b.latitude));
            if (minDist === undefined || minDist > dist) {
                minDist = dist;
                closestPoint = b;
            }
        });
        return closestPoint?.id ? { pointId: closestPoint.id } : undefined;
    }

    getVisibleLayers() {
        const visibleLayers: L.Layer [] = [] 
        this.groupLayer.getLayers().forEach((layer: L.Layer) => {
            if(this.mapContainer.getMap().getBounds().contains((layer as L.Polyline).getBounds())) {
                visibleLayers.push(layer);
            }
        });
        return visibleLayers;
    }
    
}

export class HeatLayerHelper extends LayerHelper<L.LayerGroup, L.CircleMarker, HeatData> {

    getLayerGroupType(): string {
        return HEAT_TYPE;
    }

    private getCircleLayerGroup(): L.LayerGroup {
        return (this.groupLayer as L.LayerGroup).getLayers()[0] as L.LayerGroup;
    }

    private getHeatLayer(): L.HeatLayer {
        return (this.groupLayer as L.LayerGroup).getLayers()[1] as L.HeatLayer;
    }

    protected add(layer: L.CircleMarker) {
        this.getCircleLayerGroup().addLayer(layer);
    }

    removeLayer(id: any) {
        throw new Error("Method HeatLayerHelper.removeLayer not implemented! Considered not-used!");
    }

    removeLayers() {
        super.removeLayers();
        this.getCircleLayerGroup().clearLayers();
        this.getHeatLayer().setLatLngs([]);
    }

    protected createGroupLayerInstance(): L.LayerGroup {
        return new L.LayerGroup([new L.LayerGroup(), L.heatLayer([], { radius: 15 })]);
    }

    getLayerData(layer: L.CircleMarker): HeatData {
        let data: HeatData = (layer as any).layerData;
        return data;
    }

    protected addOrUpdateLayer(data: HeatData): { layer: L.CircleMarker, isNew: boolean } {
        var circleLayer: Optional<L.CircleMarker> = this.getLayerById(data.id);
        let isNew: boolean = false;

        if (!circleLayer) {
            circleLayer = L.circleMarker(L.latLng(data.point.latitude, data.point.longitude), { radius: 5, bubblingMouseEvents: false });
            this.addEventsOnLayer(circleLayer, data);
            isNew = true;
        } else {
            circleLayer.setLatLng(L.latLng(data.point.latitude, data.point.longitude));
        }
        (circleLayer as any).layerData = data;
        this.updateStyle(circleLayer);

        return { layer: circleLayer, isNew: isNew };
    }

    addOrUpdateLayers(list: HeatData[]) {
        if (this.mapContainer.props.layers[this.type].options?.showPoints) {
            super.addOrUpdateLayers(list);
        }
        this.getHeatLayer().setLatLngs(list.map(item => L.latLng(item.point.latitude, item.point.longitude)));
    }

    updateStyle(circleLayer: L.CircleMarker) {
        const data: HeatData = this.getLayerData(circleLayer);
        const isSelected = this.mapContainer.props.s.selectedLayer?.id === data.id;
        circleLayer.setRadius(isSelected ? 10 : 5);
        circleLayer.setStyle({ fillOpacity: isSelected ? 0.7 : 0.2, color: isSelected ? "red" : DEFAULT_COLOR });
    }

    getLayerCenter(circleLayer: L.CircleMarker) {
        return circleLayer.getLatLng();
    }

    getVisibleLayers() {
        const visibleLayers: L.Layer [] = [];
        this.getCircleLayerGroup().getLayers().forEach((layer:  L.Layer) => {
            if(this.mapContainer.getMap().getBounds().contains((layer as L.Marker).getLatLng())) {
                visibleLayers.push(layer);
            }
        });
        return visibleLayers;
    }
}

const createCircleWithBorder = (radius: number, fillColor: string, text?: string, textFont?: string, borderOffset?: number, shadowOffset?: number): { url: string, width: number, height: number } => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    // set canvas width and height to double the outer diameter
    canvas.width = radius * 2 + (shadowOffset ? shadowOffset * 2 : 0);
    canvas.height = radius * 2 + (shadowOffset ? shadowOffset * 2 : 0);

    const x: number = radius + (shadowOffset ? shadowOffset : 0);
    const y: number = radius + (shadowOffset ? shadowOffset : 0);
    if (ctx == null) {
        return { url: canvas.toDataURL(), width: 0, height: 0 };
    }
    if (shadowOffset) {
        ctx.beginPath();
        ctx.arc(x, y, radius + shadowOffset, 0, 2 * Math.PI);
        ctx.fillStyle = Utils.getRGBAColor(Utils.convertColorFromHex("#000000"), 0.3);
        ctx.fill();
    }

    // white border
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fillStyle = '#ffffff';
    ctx.fill();

    // inner circle
    ctx.beginPath();
    ctx.arc(x, y, radius - (borderOffset ? borderOffset : 1.5), 0, 2 * Math.PI); // the -1.5 makes a nice offset
    ctx.fillStyle = fillColor;
    ctx.fill();


    if (text) {
        // text in the circle
        ctx.beginPath();
        if (textFont) {
            ctx.font = textFont;
        }
        ctx.fillStyle = '#ffffff';
        ctx.textAlign = 'center'; // center horizontally
        ctx.textBaseline = 'middle'; // center vertically
        ctx.fillText(text, radius, radius);
    }
    return { url: canvas.toDataURL(), width: canvas.width, height: canvas.height };
};