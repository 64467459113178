import { EntityDescriptor, ENT_READ, PrivateRoute, Utils, EntityEditorPage, PropsFrom, FieldDescriptor, OmitFieldsOfTypeFunction } from "@crispico/foundation-react";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import { MissionsAwaitingForDriversPageHOC } from "./MissionsAwaitingForDriversPage";
import { MissionEventValidationPageRRC } from "./MissionEventValidationPage";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { MissionDetailsComponent } from "./MissionGanttComponent";

const additionalDateFieldEditorProps = FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat });
const additionalDateFieldRendererProps = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat });

export const MISSION_DETAILS_TAB_PATH = "/missionDetails";

export class Mission2EntityDescriptor extends EntityDescriptor {

    protected customize() {
        this.isInDefaultColumnConfig(true, "creationDate", "humanResource", "equipmentResource", "startTime", "endTime", "type", "comment", "currentState", "mobileDevice")
            .removeFieldDescriptors("humanResourceId", "equipmentResourceId", "inactivityTypeId", "mobileDeviceId", "dataString", "dataVersion", "day", "currentStopId")
            .addFieldDescriptor({ name: "status", type: FieldType.dropdown })
            .addFieldDescriptor({ name: "creationDate", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "startTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "endTime", type: FieldType.date, additionalFieldEditorProps: additionalDateFieldEditorProps, additionalFieldRendererProps: additionalDateFieldRendererProps })
            .addFieldDescriptor({ name: "tasks", type: FieldType.oneToMany("Task"), enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
            .addFieldDescriptor({ name: "events", type: FieldType.oneToMany("MissionEvent2"), enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
            .addFieldDescriptor({ name: "stops", type: FieldType.oneToMany("Stop2"), enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true, oneToManyOppositeField: "mission" } as OmitFieldsOfTypeFunction<FieldDescriptor>)
        this.infoEditor.wrappedComponentClass = class Ext extends EntityEditorPage<PropsFrom<typeof this.infoEditor.slice>> {
            protected getExtraTabPanes() {
                const pannes = super.getExtraTabPanes();

                pannes.push(...[
                    this.createOneToManyTabPane("MissionEvent2", "missionId", { entityField: "id" }),
                    {
                        routeProps: { path: "/eventsValidation" }, menuItemProps: { content: _msg("MissionEventValidationPage.title") },
                        render: () => <MissionEventValidationPageRRC id="missionEventValidation" entityName="Mission2" entityId={this.props.entity?.id} />
                    },
                    {
                    routeProps: { path: MISSION_DETAILS_TAB_PATH }, menuItemProps: { content: _msg("Mission2.detailsTab.label") },
                    render: () => this.props.entity ? <MissionDetailsComponent id={this.props.entity.id} /> : null
                    }
                ])
                return pannes;
            }
        }
    }
}

export const MissionsAwaitingForDriversPageUrl = "/MissionsAwaitingForDrivers";
export const missionsAwaitingForDriversPageRoute = () =>
    <PrivateRoute key="missionsAwaitingForDriversPage"
        path={MissionsAwaitingForDriversPageUrl}
        render={(props) => <MissionsAwaitingForDriversPageHOC {...props} id="missionsAwaitingForDriversPage" />} />

export const missionsAwaitingForDriversPageMenuEntry = () => {
    return {
        id: "missionsAwaitingForDriversPage",
        content: _msg("MissionsAwaitingForDriversPage.title"),
        to: MissionsAwaitingForDriversPageUrl, exact: true, icon: "list",
        permission: Utils.pipeJoin([ENT_READ, missionEntityDescriptor.name])
    }
};
