import { apolloClient } from "@crispico/foundation-react"
import gql from "graphql-tag"

export const loadChecklistTemplates = async () => {
    const query = gql(`query q {
        checklistService_checklistSettings {
          templates {
            name, label, buttonsUnderDescription, fields {
              mode, name, label, valueType, values, hasComment
            }
          }
        }
    }`)
    return ((await apolloClient.query({ query: query })).data['checklistService_checklistSettings'].templates as any[]).reduce(function(templates, template) {
      templates[template.name] = template;
      return templates;
    }, {});
}
