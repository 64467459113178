import React from "react";
import { FlightInfoRenderer } from "./typeRenderers/FlightInfoRenderer";
import { TaskInfoRenderer } from "./typeRenderers/TaskInfoRenderer";
import { HumanResourceInfoRenderer } from "./typeRenderers/HumanResourceInfoRenderer";
import { MissionEventInfoRenderer, MissionEventsInfoRenderer } from "./typeRenderers/MissionEventInfoRenderer";
import { MissionInfoRenderer } from "./typeRenderers/MissionInfoRenderer";
import { Divider } from "semantic-ui-react";
import _ from "lodash";
import { HumanResourceScheduleInfoRenderer } from "./typeRenderers/HumanResourceScheduleInfoRenderer";
import { FieldDescriptor, Utils } from "@crispico/foundation-react";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";

export const options = FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true, showTooltip: true });
export const optionsForDate = FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { asLabel: true, showTooltip: true, format: Utils.dateTimeFormatShorter, formatForToday: Utils.timeFormat });


export class GanttInfoRenderer extends React.Component<{ entityName: string, entity: any }> {

    render() {
        const { entity, entityName } = this.props
        if (!entity) {
            return null;
        }
        switch (entityName) {
            case "Flight":
                return <FlightInfoRenderer entity={entity} />;
            case "Task":
                const { mission } = entity
                return <div className="flex-container gap5">
                    <TaskInfoRenderer entity={entity} />
                    {/* <Divider fitted /> */}
                    <FlightInfoRenderer entity={entity.taskGroup} />
                    {/* <Divider fitted /> */}
                    <MissionInfoRenderer entity={mission} />
                </div>;
            case "HumanResourceSchedule":
                return <div className="flex-container-row gap5">
                    <HumanResourceScheduleInfoRenderer entity={entity} />
                    <HumanResourceInfoRenderer entity={entity.humanResource} />
                </div>
            case "Mission2":
                const { events, tasks } = entity;
                const uniqueFlights: { [key: string]: any } = {};
                tasks && tasks.forEach((task: any) => uniqueFlights[task.taskGroup.id] = task.taskGroup);
                return <div className="flex-container gap5">
                    <MissionInfoRenderer entity={entity} />
                    {Object.keys(uniqueFlights).map(flightId => {
                        const flight = uniqueFlights[flightId];
                        return <div className="flex-container gap5">
                            <Divider fitted />
                            <FlightInfoRenderer entity={flight} />
                            {tasks.filter((task: any) => task.taskGroup.id == flight.id).map((task: any) => <TaskInfoRenderer entity={task} />)}
                        </div>
                    })}
                    {events?.length ? <Divider fitted /> : null}
                    {events && <MissionEventsInfoRenderer events={events} />}
                </div>
            case "MissionEvent2":
                return <MissionEventInfoRenderer entity={entity}/>
            default:
                return <></>;
        }

    }
}