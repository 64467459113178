import React from "react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { optionsForDate } from "../GanttInfoRenderer";

export class HumanResourceScheduleInfoRenderer extends React.Component<{ entity: any }> {

    render() {
        const { entity } = this.props;
        if (!entity) {
            return null;
        }
        const ed = entityDescriptors["HumanResourceSchedule"];
        return <div className="flex-container-row gap5">
            {ed.getField("startTime").renderField(entity, optionsForDate)}
            {ed.getField("endTime").renderField(entity, optionsForDate)}
        </div>
    }
}
