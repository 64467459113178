import React from "react";
import moment, { Moment } from "moment";
import { Utils } from "@crispico/foundation-react";
import { Button, Checkbox, Form, FormGroup, Modal } from "semantic-ui-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import { RRCProps, Reducers, ReduxReusableComponents, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";


/**
 * Add dates given as parameter or from state are in ISO format, having the start of the day as time.
 */
class GanttSelectDateState extends State {
    date: string | undefined;
    modal = { show: false, date: undefined as string | undefined };
}

class GanttSelectDateReducers<S extends GanttSelectDateState = GanttSelectDateState> extends Reducers<S> { }

type Props = RRCProps<GanttSelectDateState, GanttSelectDateReducers> & {
    initialDate?: string | undefined,
    onChange(date: string): void
}

export class GanttSelectDate extends React.Component<Props> {

    constructor(props: any) {
        super(props);
        this.onRefreshClick = this.onRefreshClick.bind(this);
        this.onArchiveDateChanged = this.onArchiveDateChanged.bind(this);
    }

    componentDidMount(): void {
        // set now as initial value from date
        const nowStartOfDay = moment(Utils.now()).startOf("day").toISOString();
        this.props.r.setInReduxState({ date: nowStartOfDay, modal: { ...this.props.s.modal, date: nowStartOfDay } });
        this.componentDidUpdateInternal();      
    }

    componentDidUpdate(prevProps: Props) {
       this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        if (prevProps?.initialDate !== this.props.initialDate) { // initial value for date
            this.props.r.setInReduxState({ date: this.props.initialDate, modal: { ...this.props.s.modal, date: this.props.initialDate }});
        }
        if (prevProps?.s.date != this.props.s.date && this.props.s.date !== this.props.initialDate) {
            this.props.onChange(this.props.s.date!);
        }
    }

    protected onRefreshClick() {
        const { modal } = this.props.s;
        this.props.r.setInReduxState({ date: modal.date });
        this.displayModal(false);
    }

    protected async onArchiveDateChanged(selectedDate: Moment | null) {
        if (selectedDate) {
            await this.props.r.setInReduxState({ modal: { ...this.props.s.modal, date: selectedDate.startOf("day").toISOString() } });
            this.onRefreshClick();
        }
    }

    protected displayModal(show: boolean) {
        this.props.r.setInReduxState({ modal: { ...this.props.s.modal, show: show } });
    }

    protected isRealTime(date: string | undefined): boolean {       
        return date ? moment(Utils.now()).startOf("day").toISOString() === date : true;
    }

    protected renderModal() {
        const { modal } = this.props.s;
        const isRealTime = this.isRealTime(modal.date);
        return <ModalExt size="mini" open={modal.show} >
            <Modal.Header>{_msg("DatePicker.placeholder")}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup inline>
                        <Form.Field width={6}>
                            <Checkbox radio name='checkboxRadioGroup' label={_msg("GanttSelectDate.realTime.label")} checked={isRealTime}
                                onChange={() => this.props.r.setInReduxState({ modal: { ...modal, date: moment(Utils.now()).startOf("day").toISOString() } })} />
                        </Form.Field>
                        <Form.Field width={9} className={!isRealTime ? "disabledItem" : ""} >
                            <input type='text' readOnly value={isRealTime ? moment(modal.date).format(Utils.dateFormat) : ""} />
                        </Form.Field>
                    </FormGroup>
                    <FormGroup inline>
                        <Form.Field width={6}>
                            <Checkbox radio name='checkboxRadioGroup' label={_msg("GanttSelectDate.archive.label")} checked={!isRealTime}
                                onChange={() => this.props.r.setInReduxState({ modal: { ...modal, date: moment(Utils.now()).add(-1, "day").startOf("day").toISOString() } })} />
                        </Form.Field>
                        <Form.Field width={12} className={isRealTime ? "disabledItem" : ""} >
                            <DatePickerReactCalendar format={Utils.dateFormat}
                                onChange={this.onArchiveDateChanged} value={!isRealTime ? moment(modal.date) : undefined} />
                        </Form.Field>
                    </FormGroup>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button content={_msg("general.refresh")} onClick={this.onRefreshClick} />
                <Button content={_msg("general.cancel")} onClick={() => this.displayModal(false)} />
            </Modal.Actions>
        </ModalExt>
    }

    render() {
        return (<>
            <Button icon="calendar" content={(this.isRealTime(this.props.s.date) ? _msg("GanttSelectDate.realTime.label") : _msg("GanttSelectDate.archive.label")) + " " + moment(this.props.s.date).format(Utils.dateFormat)}
                onClick={() => this.displayModal(true)} />
            {this.renderModal()}
        </>);
    }
}

export const GanttSelectDateRRC = ReduxReusableComponents.connectRRC(GanttSelectDateState, GanttSelectDateReducers, GanttSelectDate);