import { apolloClient, EntityDescriptor, FieldDescriptor, Optional } from "@crispico/foundation-react";
import { ResponsivePieExt } from "@crispico/foundation-react/components/nivoExt";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import { ResponsiveBar } from "@nivo/bar";
import { populateAmazonReport } from "apollo-gen/populateAmazonReport";
import React from "react";
import SplitPane from "react-split-pane";
import { Button, Dimmer, Dropdown, Form, FormGroup, Icon, Label, Segment, DropdownItemProps } from "semantic-ui-react";
import { POPULATE_USAGE_ENTRIES } from "./queries";
import moment from "moment-timezone";
import { ResponsiveLine } from "@nivo/line";
import { NavLink } from "react-router-dom";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
//@ts-ignore - apparently categoricalColorSchemes is not exported
import { categoricalColorSchemes } from "@nivo/colors";
//@ts-ignore - needed because Defs is not defined in @nico/core but it is in the js code ...
import { getRelativeCursor } from "@nivo/core";
//@ts-ignore
import { useSlices } from "@nivo/line"
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { cloneDeep } from "lodash";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Chart } from "../ChartTab";
import { FieldEditor, FieldEditorProps, ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export const equipmentUsageConfigDescriptor = new EntityDescriptor({name: "EquipmentUsageReport"}, false)
    .addFieldDescriptor({ name: "predefinedPeriod", type: "ColumnConfig" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            let options: Array<any> = [
                { key: 0, text: "Custom", value: 0 },
                { key: 1, text: "Yesterday", value: 1 },
                { key: 2, text: "Last week", value: 2 }
            ];
            return(<PredefinedPeriodFieldEditor {...props} options={options} />);
        }
    }())
    .addFieldDescriptor({ name: "startDate", type: FieldType.date })
    .addFieldDescriptor({ name: "endDate", type: FieldType.date })
    .addFieldDescriptor({ name: "timeZone", type: FieldType.timeZone })
    .addFieldDescriptor({ name: "useEngineState", type: FieldType.boolean });

export const equipmentUsageDayConfigDescriptor = new EntityDescriptor({name: "EquipmentUsageDayReport"}, false)
    .addFieldDescriptor({ name: "predefinedPeriod", type: "ColumnConfig" }, new class extends FieldDescriptor {
        protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
            let options: Array<any> = [
                { key: 0, text: "Custom", value: 0 },
                { key: 1, text: "Yesterday", value: 1 }
            ];
            return(<PredefinedPeriodFieldEditor {...props} options={options} />);
        }
    }())
    .addFieldDescriptor({ name: "date", type: FieldType.date })
    .addFieldDescriptor({ name: "timeZone", type: FieldType.timeZone })
    .addFieldDescriptor({ name: "useEngineState", type: FieldType.boolean });

class PredefinedPeriodFieldEditor extends FieldEditor<number, FieldEditorProps & { options: DropdownItemProps[] }> {

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        return(<Dropdown defaultValue={0} selection options={this.props.options} value={this.getValue()}
            onChange={(e, { value }) => s.setFieldValue(hw, value)} />);
    }

}

export interface Config {
    startDate: number;
    endDate: number;
    timeZone: string;
    useTimeModeHours: boolean ;
    useEngineState: boolean;
}

export class EquipmentUsageTabState  extends State {
    usageEntries = [] as Array<any>;
    airportList = [] as Array<string>;
    typeList = [] as Array<string>;
    selectedDate = "" as string;
    selectedAirport = "All" as string;
    selectedTypeInGraph = "" as string;
    selectedTypeInPage = "All" as string;
    selectedPieChartData = [] as Array<any>;
    barChartData = [] as Array<any>;
    lineChartData = [] as Array<any>;
    selectedUsageEntries = {} as any;
    showTableSpinner = false as boolean;
    isLineChart = false as boolean;
    currentLineChartPoints = [] as Array<any>;
    generateButtonEnabled = false as boolean;
    previousConfig = undefined as any;
    totalDataPercetange = "" as string;
}

export class EquipmentUsageTabReducers<S extends EquipmentUsageTabState = EquipmentUsageTabState> extends Reducers<S> {
    deserializeSavedData(p: string) {
        let result = JSON.parse(p);
        if (result && result["usageEntries"] && result["airportList"] && result["typeList"]) {
            this.s.usageEntries = result["usageEntries"];
            this.s.airportList = result["airportList"];
            this.s.typeList = result["typeList"];
        }
    }

    setPreviousConfig(config: any) {
        this.s.previousConfig = config;
    }

    onGenerateButtonChanged(value: boolean) {
        this.s.generateButtonEnabled = value;
    }

    resetSelectedData() {
        this.s.selectedTypeInGraph = "";
        this.s.selectedPieChartData = [];
        this.s.selectedUsageEntries = {};
    }

    onTimeModeChange(value: boolean) {
        this.s.isLineChart = value;
    }

    onAirportSelectionChange(value: string) {
        this.s.selectedAirport = value;
        this.resetSelectedData();
        this.populateChart();
    }

    onTypeSelectionChange(value: string) {
        this.s.selectedTypeInPage = value;
        this.resetSelectedData();
        this.populateChart();
    }

    onPointHover(points: Array<any>) {
        this.s.currentLineChartPoints = points;
    }

    onBarGraphClick(p: { date: string, airport: string, type: string, usageEntryIndexes: any, percentage: any }) {
        this.s.selectedDate = p.date;
        this.s.selectedTypeInGraph = p.type;
        this.s.selectedUsageEntries = p.usageEntryIndexes;

        let positive: number = 0;
        let negative: number = 0;
        this.s.selectedUsageEntries.forEach((entry: number) => {
            if (this.s.usageEntries[entry].value === 1) {
                positive = positive + 1;
            } else {
                negative = negative + 1;
            }
        })

        const percentage = (p.percentage as number === 0.55) ? 0 : p.percentage;
        this.s.selectedPieChartData = [
            { id: "Used", label: percentage + "%", value: positive, color: 'teal' },
            { id: "Not used", label: (100 - (percentage as number)) + "%", value: negative, color: "grey" }
        ]
    }

    populateChart() {
        if ( this.s.isLineChart) {
            this.populateLineChart();
        } else {
            this.populateBarChart();
        }
    }

    computeLineChartData(newLineChartDataMap: any, usageEntriesPercentageMap: any, usageEntry:any, index:number, colorIndex:number) {
        if ((usageEntry.airport === this.s.selectedAirport ||  this.s.selectedAirport === _msg("viewOptions.all"))
                && (usageEntry.type === this.s.selectedTypeInPage || this.s.selectedTypeInPage === _msg("viewOptions.all"))) {

                if (newLineChartDataMap[usageEntry.type] === undefined) {
                    newLineChartDataMap[usageEntry.type] = {
                        id: usageEntry.type, color: categoricalColorSchemes["paired"][colorIndex],
                        airport: this.s.selectedAirport, usageEntries: [], data: []
                    };
                    usageEntriesPercentageMap[usageEntry.type] = {};
                    colorIndex++;
                }

                if (newLineChartDataMap[usageEntry.type].usageEntries[usageEntry.date] === undefined) {
                    newLineChartDataMap[usageEntry.type].usageEntries[usageEntry.date] = []
                }
                newLineChartDataMap[usageEntry.type].usageEntries[usageEntry.date].push(index);

                if (usageEntriesPercentageMap[usageEntry.type][usageEntry.date] === undefined) {
                    usageEntriesPercentageMap[usageEntry.type][usageEntry.date] = { positive: 0, negative: 0 };
                }
                if (usageEntry.value === 1) {
                    usageEntriesPercentageMap[usageEntry.type][usageEntry.date].positive = usageEntriesPercentageMap[usageEntry.type][usageEntry.date].positive + 1;
                } else {
                    usageEntriesPercentageMap[usageEntry.type][usageEntry.date].negative = usageEntriesPercentageMap[usageEntry.type][usageEntry.date].negative + 1;
                }
            }

            return colorIndex;
    }

    populateLineChart() {
        let newLineChartDataMap: any = {};
        let usageEntriesPercentageMap: any = {};
        let colorIndex = 0;
        for (let i = 0; i < this.s.usageEntries.length; i++) {
            let usageEntry = cloneDeep(this.s.usageEntries[i]);
            usageEntry.type = _msg("viewOptions.all");
            colorIndex = this.computeLineChartData(newLineChartDataMap, usageEntriesPercentageMap, usageEntry, i, colorIndex);
            usageEntry = cloneDeep(this.s.usageEntries[i]);
            colorIndex = this.computeLineChartData(newLineChartDataMap, usageEntriesPercentageMap, usageEntry, i, colorIndex);
        }

        const usageEntriesPercentageMapKeys = Object.keys(usageEntriesPercentageMap);
        for (let i = 0; i < usageEntriesPercentageMapKeys.length; i++) {
            const typeKey = usageEntriesPercentageMapKeys[i];
            const usageEntriesPercentageMapDateKeys = Object.keys(usageEntriesPercentageMap[typeKey]);
            for (let j = 0; j < usageEntriesPercentageMapDateKeys.length; j++) {
                const dateKey = usageEntriesPercentageMapDateKeys[j];

                const total = usageEntriesPercentageMap[typeKey][dateKey].positive + usageEntriesPercentageMap[typeKey][dateKey].negative;
                const percentage = usageEntriesPercentageMap[typeKey][dateKey].positive * 100 / total;
                newLineChartDataMap[typeKey].data.push({
                    x: dateKey, y: Math.floor(percentage), positive: usageEntriesPercentageMap[typeKey][dateKey].positive, total: total,
                    airport: newLineChartDataMap[typeKey].airport, usageEntries: newLineChartDataMap[typeKey].usageEntries[dateKey]
                });

            }
        }

        this.s.lineChartData = Object.values(newLineChartDataMap).reverse();
    }

    computeBarChartData(newBarChartDataMap: any, usageEntriesPercentageMap: any, usageEntry:any, index:number) {
        if ((usageEntry.airport === this.s.selectedAirport || this.s.selectedAirport === _msg("viewOptions.all"))
                && (usageEntry.type === this.s.selectedTypeInPage || this.s.selectedTypeInPage === _msg("viewOptions.all"))) {

                if (!newBarChartDataMap[usageEntry.date]) {
                    newBarChartDataMap[usageEntry.date] = { date: usageEntry.date, airport: this.s.selectedAirport, usageEntries: [] };
                    usageEntriesPercentageMap[usageEntry.date] = {};
                }

                if (newBarChartDataMap[usageEntry.date][usageEntry.type] === undefined) {
                    newBarChartDataMap[usageEntry.date][usageEntry.type] = 0;
                    newBarChartDataMap[usageEntry.date].usageEntries[usageEntry.type] = []
                    usageEntriesPercentageMap[usageEntry.date][usageEntry.type] = { positive: 0, negative: 0 };
                }

                newBarChartDataMap[usageEntry.date].usageEntries[usageEntry.type].push(index);
                if (usageEntry.value === 1) {
                    usageEntriesPercentageMap[usageEntry.date][usageEntry.type].positive = usageEntriesPercentageMap[usageEntry.date][usageEntry.type].positive + 1;
                } else {
                    usageEntriesPercentageMap[usageEntry.date][usageEntry.type].negative = usageEntriesPercentageMap[usageEntry.date][usageEntry.type].negative + 1;
                }
            }
    }

    populateBarChart() {
        let newBarChartData: Array<any> = [];
        let newBarChartDataMap: any = {};
        let usageEntriesPercentageMap: any = {};

        let startDate = moment(this.s.previousConfig?.startDate).startOf("day");
        let endDate = moment(this.s.previousConfig?.endDate).endOf("day");
        const addTotal = (this.s.previousConfig?.predefinedPeriod && this.s.previousConfig?.predefinedPeriod > 1) || 
            ((!this.s.previousConfig?.predefinedPeriod || this.s.previousConfig?.predefinedPeriod === 0) && endDate.diff(startDate, 'days') > 0);
        
        if (addTotal) {
            newBarChartDataMap['Total'] = { date: 'Total', airport: this.s.selectedAirport, usageEntries: [] };
            usageEntriesPercentageMap['Total'] = {};
        }
            
        for (let i = 0; i < this.s.usageEntries.length; i++) {
            let usageEntry = cloneDeep(this.s.usageEntries[i]);
            this.computeBarChartData(newBarChartDataMap, usageEntriesPercentageMap, usageEntry, i);
            usageEntry.type = _msg("viewOptions.all");
            this.computeBarChartData(newBarChartDataMap, usageEntriesPercentageMap, usageEntry, i);
            if (addTotal) {
                    usageEntry.date = 'Total';
                    this.computeBarChartData(newBarChartDataMap, usageEntriesPercentageMap, usageEntry, i);
                    usageEntry = cloneDeep(this.s.usageEntries[i]);
                    usageEntry.date = 'Total';
                    this.computeBarChartData(newBarChartDataMap, usageEntriesPercentageMap, usageEntry, i);
            }
        }

        let newBarChartDataMapValues = Object.values(newBarChartDataMap);
        for (let i = 0; i < newBarChartDataMapValues.length; i++) {
            let value: any = newBarChartDataMapValues[i];
            let newBarChartKeys = Object.keys(value);
            for (let j = 0; j < newBarChartKeys.length; j++) {
                if (this.s.typeList.indexOf(newBarChartKeys[j]) > -1) {
                    const total = usageEntriesPercentageMap[value.date][newBarChartKeys[j]].positive + usageEntriesPercentageMap[value.date][newBarChartKeys[j]].negative;
                    const percentage = usageEntriesPercentageMap[value.date][newBarChartKeys[j]].positive * 100 / total;
                    value[newBarChartKeys[j]] = Math.floor(percentage);
                    value[newBarChartKeys[j]] = value[newBarChartKeys[j]] === 0 ? 0.55 : value[newBarChartKeys[j]];
                    value[newBarChartKeys[j] + "Positive"] = usageEntriesPercentageMap[value.date][newBarChartKeys[j]].positive
                    value[newBarChartKeys[j] + "Total"] = total;
                    if (value.date === "Total" && newBarChartKeys[j] === "All") {
                        this.s.totalDataPercetange = value[newBarChartKeys[j]];
                    }
                }
            }

            newBarChartData.push(value);
        }

        this.s.barChartData = newBarChartData;
    }
}

type Props = RRCProps<EquipmentUsageTabState, EquipmentUsageTabReducers> & {config: Optional<Config>, entity: Chart, zeroTrainingMode: boolean };

export class EquipmentUsageTab extends React.Component<Props> {

    protected entityDescriptor = entityDescriptors["EquipmentResource"] as any;
    protected slices = [] as any[];
    // in order not use an expensive cache (like lodash.memoize).
    // we are strictly interested to recompute only when one of this parameters change
    protected localDataToRecompute: any = { width: undefined, height: undefined, data: undefined }

    onGenerateButtonClick(entityId: number) {
        let startDate;
        let endDate;
        if (this.props.s.previousConfig?.predefinedPeriod && this.props.s.previousConfig?.predefinedPeriod > 0) {
            if (this.props.s.previousConfig?.predefinedPeriod === 1) {
                startDate = moment().subtract(1, 'days').startOf("day").toISOString();
                endDate = moment().subtract(1, 'days').endOf("day").toISOString();
            } else if (this.props.s.previousConfig?.predefinedPeriod === 2) {
                startDate = moment().subtract(7, 'days').startOf("day").toISOString();
                endDate = moment().subtract(1, 'days').endOf("day").toISOString();
            }
        } else {
            if (this.props.s.isLineChart) {
                startDate = moment(this.props.s.previousConfig?.date).startOf("day").toISOString();
                endDate = moment(this.props.s.previousConfig?.date).endOf("day").toISOString();
            } else {
                startDate = moment(this.props.s.previousConfig?.startDate).startOf("day").toISOString();
                endDate = moment(this.props.s.previousConfig?.endDate).endOf("day").toISOString();
            }
        }
        this.loadUsageEntries(startDate, endDate, this.props.s.previousConfig?.timeZone, this.props.s.isLineChart, this.props.s.previousConfig?.useEngineState, entityId);
        this.props.r.setInReduxState({ generateButtonEnabled: false });
    }

    async loadUsageEntries(startDate: any, endDate: any, timeZone: any, timeMode: any, useEngineState: any, entityId: number) {
        startDate = moment(startDate).toISOString();
        endDate = moment(endDate).toISOString();
        this.props.r.resetSelectedData();
        this.props.r.setInReduxState({ showTableSpinner: true });
        const result: any = (await apolloClient.query<populateAmazonReport>({
            query: POPULATE_USAGE_ENTRIES,
            variables: { startDateString: startDate, endDateString: endDate, timeZone: timeZone.value, timeMode: timeMode && true, useEngineState: useEngineState && true, entityId: entityId.toString() }
        })).data.equipmentResourceServiceCustomReportAmazon_populateAmazonReport;
    }

    protected getTitle() {
        return _msg("EquipmentUsageReport.title");
    }

    componentWillUnmount() {
        this.slices = [];
        this.localDataToRecompute = {};
    }

    componentDidMount() {
        if (this.props.entity.type === "equipmentUsage") {
            this.props.r.onTimeModeChange(false);
        } else {
            this.props.r.onTimeModeChange(true);
        }

        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    componentDidUpdateInternal(prevProps?: Props) {
        const props = this.props

        if (props.config && props.config !== props.s.previousConfig) {
            if (props.s.previousConfig) { // generate only if it had a previous configuration
                props.r.onGenerateButtonChanged(true);
            }
            props.r.setPreviousConfig(props.config);
        }

        if (!prevProps && props.entity.savedData) {
            props.r.deserializeSavedData(props.entity.savedData);
            props.r.populateChart();
        } else if (!prevProps && !props.entity.savedData) {
            props.r.onGenerateButtonChanged(true);
        }
    }

    computeSlicesIfNeeded(p: any) {
        if (this.localDataToRecompute.width !== p.innerWidth
            || this.localDataToRecompute.height !== p.innerHeight
            || this.localDataToRecompute.data !== p.data) {
            this.localDataToRecompute = {
                width: p.innerWidth,
                height: p.innerHeight,
                data: p.data
            }
            // TODO de fixat; temporar am silenced eroarea
            // :( copy paste din/in ResponsiveLineChart
            // eslint-disable-next-line react-hooks/rules-of-hooks
            this.slices = useSlices({ enableSlices: "x", points: p.points, width: p.innerWidth, height: p.innerHeight });
        } else {
            // need this bogus call to avoid "Rendered fewer hooks than expected. This may be caused by an accidental early return statement." error
            // TODO de fixat; temporar am silenced eroarea
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useSlices({ enableSlices: false, points: [], width: p.innerWidth, height: p.innerHeight });
        }
    }

    renderPieChartTooltip(data: any) {
        return (
            <div className="ResponsivePieChart_Tooltip" style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={data.id}>
                <span style={{ display: "block", width: "12px", height: "12px", background: data.color, marginRight: "7px" }} />
                {data.id + ": " + data.value + " [" + data.label + "]"}
            </div>
        );
    }

    renderBarChartTooltip(value: any) {
        return (
            <div className="ResponsivePieChart_Tooltip" style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={value.indexValue}>
                <span style={{ display: "block", width: "12px", height: "12px", background: value.color, marginRight: "7px" }} />
                {value.id + ": " + (value.data[value.id] === "0.55" ? "0" : value.data[value.id]) +
                    "% [" + value.data[value.id + "Positive"] + " / " + value.data[value.id + "Total"] + "]"}
            </div>
        );
    }

    renderLineChartTooltip(value: any) {
        let tooltipResult: any[] = [];
        this.props.s.currentLineChartPoints.map((value) => tooltipResult.push(
            <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={value.serieId}>
                <span style={{ display: "block", width: "12px", height: "12px", background: value.serieColor, marginRight: "7px" }} />
                {value.serieId + ": " + value.data.y + "% [" + value.data.positive + " / " + value.data.total + "]"}
            </div>
        ));

        return (<div style={{
            background: 'white',
            padding: '5px 9px',
            borderRadius: '2px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px'
        }}>
            {tooltipResult}
        </div>
        );
    }

    renderBarChartLabel(value: any) {
        return (value.data[value.id] === "0.55" ? "0" : value.data[value.id]) + "%";
    }

    renderBarChart() {
        const props = this.props;

        let startDate = moment(props.s.previousConfig?.startDate).startOf("day");
        let endDate = moment(props.s.previousConfig?.endDate).endOf("day");
        const extendedForm = (props.s.previousConfig?.predefinedPeriod && props.s.previousConfig?.predefinedPeriod > 1) || 
                ((!props.s.previousConfig?.predefinedPeriod || props.s.previousConfig?.predefinedPeriod === 0) && endDate.diff(startDate, 'days') > 2);

        let legend:any = [];
        if (extendedForm) {
            legend = [{
                dataFrom: 'keys', anchor: 'bottom', direction: 'row', justify: false, translateX: 0, translateY: 48, itemsSpacing: 130,
                itemWidth: 100, itemHeight: 20, itemDirection: 'left-to-right', itemOpacity: 0.85, symbolSize: 20
                }];
        } else {
            legend = [{
                dataFrom: 'keys', anchor: 'bottom-right', direction: 'column', justify: false, translateX: 160, translateY: -20, itemsSpacing: 25,
                itemWidth: 100, itemHeight: 20, itemDirection: 'top-to-bottom', itemOpacity: 0.85, symbolSize: 20
            }];
        }

        return (
            <ResponsiveBar
                data={props.s.barChartData} keys={props.s.typeList} indexBy="date" groupMode={"grouped"}
                margin={{ top: 10, right: extendedForm ? 10 : 200, bottom: extendedForm ? 50 : 20, left: 40 }} padding={0.3} innerPadding={1} borderRadius={5}
                colors={{ scheme: 'paired' }} borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                minValue={0} maxValue={100}
                axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => value + "%" }}
                label={(value: any) => this.renderBarChartLabel(value)} labelSkipWidth={12} labelSkipHeight={12} labelTextColor={{ from: 'color', modifiers: [['darker', 8]] }}
                legends={legend}
                animate={true} /*motionStiffness={90} motionDamping={15}*/
                tooltip={(value: any) => this.renderBarChartTooltip(value)}
                onClick={(data: any, event: any) => props.r.onBarGraphClick({
                    date: data.data.date as string, airport: data.data.airport as string,
                    type: data.id as string, usageEntryIndexes: data.data.usageEntries[data.id] as any,
                    percentage: data.data[data.id] as string
                })}
            />
        );
    }

    getPointsUnderMouse(event: any) {
        const info = getRelativeCursor(event.currentTarget, event);
        let points: any[] | undefined = undefined;
        let ptTemp: any;

        for (ptTemp of this.slices) {
            if (ptTemp.x0 < info[0] && ptTemp.x0 + ptTemp.width > info[0]) {
                points = ptTemp.points;
                break;
            }
        }

        return points;
    }

    areaLayer = (p: any) => {
        this.computeSlicesIfNeeded(p);
        return (<></>);
    }

    renderLineChart() {
        const props = this.props;

        return (<ResponsiveLine
            data={props.s.lineChartData} margin={{ top: 10, right: 200, bottom: 57, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 100, stacked: false, reverse: false }}
            axisTop={null} axisRight={null}
            // axisBottom={{ orient: 'bottom', tickSize: 5, tickPadding: 5, tickRotation: 30 }}
            // axisLeft={{ orient: 'left', tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => value + "%" }}
            colors={{ datum: 'color' }} curve="monotoneX"
            pointSize={10} pointColor={{ from: 'color' }}
            pointBorderWidth={2} pointBorderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            pointLabel="y" pointLabelYOffset={-12} useMesh={true}
            legends={[{
                anchor: 'bottom-right', direction: 'column', justify: false, translateX: 160, translateY: -20, itemsSpacing: 25,
                itemWidth: 100, itemHeight: 20, itemDirection: 'top-to-bottom', itemOpacity: 0.85, symbolSize: 20
            }]}
            animate={true} motionConfig={{ damping: 15 }} // motionStiffness={90} // this param was removed from nivo
            tooltip={(value: any) => this.renderLineChartTooltip(value)}
            layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                this.areaLayer,
                'lines',
                'slices',
                'points',
                'mesh',
                'legends',
            ]}
            onClick={(point: any, event: any) => props.r.onBarGraphClick({
                date: point.data.x as string, airport: point.data.airport as string,
                type: point.serieId as string, usageEntryIndexes: point.data.usageEntries as any,
                percentage: point.data.y as string
            })}
            onMouseMove={(point1, event) => {
                const points = this.getPointsUnderMouse(event);

                if (points === undefined) {
                    return;
                }

                props.r.onPointHover(points);
            }}
            onMouseLeave={(point, event) => {
                props.r.onPointHover([])
            }}
            markers={[
                {
                    axis: 'x',
                    value: props.s.currentLineChartPoints[0] === undefined ? 0 : this.props.s.currentLineChartPoints[0].data.x,
                    lineStyle: {
                        stroke: '#000',
                        strokeWidth: this.props.s.currentLineChartPoints[0] === undefined ? 0 : 1,
                        strokeOpacity: 0.75,
                        strokeDasharray: "6, 6"
                    }
                }
            ]}
        />);
    }

    renderUsageEntries() {
        const props = this.props;
        let list: JSX.Element[] = [];
        Object.keys(props.s.selectedUsageEntries).forEach(key => {
            const color = props.s.usageEntries[props.s.selectedUsageEntries[key]].value === 1 ? 'teal' : 'grey';
            list.push(<NavLink
                to={this.entityDescriptor.getEntityEditorUrl(props.s.usageEntries[props.s.selectedUsageEntries[key]].erId)}>
                <Label color={color} key={props.s.usageEntries[props.s.selectedUsageEntries[key]].erId} className="EquipmentUsageReport_usageEntries">
                    <Icon name={this.entityDescriptor.icon} />
                    {props.s.usageEntries[props.s.selectedUsageEntries[key]].erIdentifier}
                </Label>
            </NavLink>);
        });
        return list;
    }

    render() {
        const props = this.props;

        let airportDropdownOptions: Array<any> = [];
        airportDropdownOptions.push({ key: 0, text: _msg("viewOptions.all"), value: 0 });
        if (props.s.airportList) {
            for (let i = 0; i < props.s.airportList.length; i++) {
                airportDropdownOptions.push({ key: i + 1, text: props.s.airportList[i], value: i + 1 })
            }
        }

        let typeDropdownOptions: Array<any> = [];
        if (props.s.typeList) {
            for (let i = 0; i < props.s.typeList.length; i++) {
                typeDropdownOptions.push({ key: i, text: props.s.typeList[i], value: i })
            }
        }

        return (<div className="flex-container flex-grow ">
            <div>
                <MessageExt>
                    <Form>
                        <FormGroup inline>
                            {_msg("EquipmentUsageReport.airport")}:
                            <Dropdown defaultValue={0} selection options={airportDropdownOptions} className="Message_spacingRight"
                                onChange={(e, { value }) => props.r.onAirportSelectionChange(airportDropdownOptions[value as number].text)} />
                            {_msg("EquipmentUsageReport.type")}:
                            <Dropdown defaultValue={0} selection options={typeDropdownOptions} className="Message_spacingRight"
                                onChange={(e, { value }) => props.r.onTypeSelectionChange(typeDropdownOptions[value as number].text)} />
                            {_msg("EquipmentUsageReport.total")}:
                            <Label basic className="Message_spacingRight" size='large'>{(props.s.totalDataPercetange == '0.55') ? '0' : props.s.totalDataPercetange}%</Label>
                            <Button color='green' disabled={(props.entity.percentage && props.entity.percentage < 100) || !props.s.generateButtonEnabled} onClick={(event: any) => this.onGenerateButtonClick(props.entity.id)}>{_msg("EquipmentUsageReport.generate")}</Button>
                            <Button color='red' onClick={(event: any) => this.onGenerateButtonClick(props.entity.id)}>{_msg("EquipmentUsageReport.forceGenerate")}</Button>
                            {props.s.generateButtonEnabled ? (<Label color='red' tag>{_msg("EquipmentUsageReport.configChanged")}</Label>) : (<></>)}
                        </FormGroup>
                    </Form>
                </MessageExt>
            </div>
            <SplitPaneExt split="horizontal" defaultSize="40%" primary="second">
                <Segment className="HistogramPresenceInTerritoriesTab_pieContainer chart_tableHeight">
                    {((props.entity.percentage && props.entity.percentage < 100) || props.s.showTableSpinner) ? (<Dimmer active>Chart is generating!</Dimmer>) : (<></>)}
                    {props.s.isLineChart ? this.renderLineChart() : this.renderBarChart()}
                </Segment>
                <div className="HistogramPresenceInTerritoriesTab_segmentPie">
                    <MessageExt>
                        {_msg("EquipmentUsageReport.series")}:
                        <Label basic className="Message_spacingRight"><Icon name='calendar alternate outline' />{props.s.selectedDate}</Label>
                        {_msg("EquipmentUsageReport.airport")}:
                        <Label basic className="Message_spacingRight">{props.s.selectedAirport}</Label>
                        {_msg("EquipmentUsageReport.type")}:
                        <Label basic className="Message_spacingRight">{props.s.selectedTypeInGraph}</Label>
                    </MessageExt>

                    <SplitPane split="vertical" defaultSize="25%" style={{ position: "static" }}>
                        <Segment className="HistogramPresenceInTerritoriesTab_pieContainer">
                            <ResponsivePieExt data={props.s.selectedPieChartData} colors={{ datum: 'color' }} arcLabel={'label'} arcLinkLabel={'value'}
                                tooltip={(value: any) => this.renderPieChartTooltip(value)} />
                        </Segment>
                        <Segment className="flex-grow">
                            {this.renderUsageEntries()}
                        </Segment>
                    </SplitPane>
                </div>
            </SplitPaneExt>
        </div>);
    }
}

export const EquipmentUsageTabRRC = ReduxReusableComponents.connectRRC(EquipmentUsageTabState, EquipmentUsageTabReducers, EquipmentUsageTab);
