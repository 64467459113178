export const toolbox = `<xml id="toolbox" style="display: none">
	<category name="Variables" colour="%{BKY_VARIABLES_HUE}">
    <block type="get_variable"></block>
    <block type="get_eqType"></block>
	</category>
	<category name="Custom" colour="%{BKY_CUSTOM_HUE}">
		<block type="custom_calls_previous"></block>
		<block type="custom_calls_output"></block>
		<block type="execute">
			<value name="LIST_REQUIRED">
				<block type="lists_create_with" inline="true">
					<mutation items="3"></mutation>
					<value name="ADD0">
						<block type="get_variable" />
					</value>
					<value name="ADD1">
						<block type="get_variable" />
					</value>
					<value name="ADD2">
						<block type="get_variable" />
					</value>
				</block>
			</value>
		</block>
	</category>
	<category name="Logic" colour="%{BKY_LOGIC_HUE}">
		<block type="logic_boolean"></block>
		<block type="controls_if"></block>
		<block type="logic_compare"></block>
		<block type="logic_operation"></block>
		<block type="logic_negate"></block>	
		<block type="logic_operation_with_output" inline="false"></block>
	</category>
	<category name="Math" colour="%{BKY_MATH_HUE}">
		<block type="math_number">
			<field name="NUM">123</field>
		</block>
	</category>
	<category name="Text" colour="%{BKY_TEXTS_HUE}">
		<block type="text"></block>
	</category>
</xml>`