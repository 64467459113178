import React from "react";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { Label } from "semantic-ui-react";
import { options } from "../GanttInfoRenderer";


export class HumanResourceInfoRenderer extends React.Component<{ entity: any }> {

    render() {
        const { entity } = this.props;
        if (!entity) {
            return null;
        }
        const ed = entityDescriptors["HumanResource"];
        let qualificationTypes: any[] = [];
        entity.qualifications?.forEach((qualification: any) => qualificationTypes.push(qualification.qualificationType))

        return <div className="flex-container-row gap5">
            {ed.getField("firstName").renderField(entity, options)}
            {ed.getField("lastName").renderField(entity, options)}
            {ed.getField("identifier").renderField(entity, options)}
            {ed.getField("vehicle").renderField(entity, options)}
            {ed.getField("mobileDevice").renderField(entity, options)}
            {qualificationTypes.length ? <Label content={qualificationTypes.map(q => q.name).sort().reduce((previousValue, currentValue) => previousValue + (previousValue != "" ? ", " : "") + currentValue, "")} /> : null}
        </div>
    }
}
