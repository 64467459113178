import { FilterOperators } from "@crispico/foundation-gwt-js";
import { EntityDescriptor, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { ID, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { NavLink } from "react-router-dom";

class DiagnosticTroubleCodeTablePage extends EntityTablePage<EntityTablePageProps> {

    constructor(props: EntityTablePageProps) {
        super(props);

        this.setOptions({ countMode: undefined });
    }

    getFieldsToRequestAsString(fieldsToRequest: string[]) {
        return super.getFieldsToRequestAsString(fieldsToRequest) + " timestamp equipmentResource { plateNumber } ";
    }
}

const DiagnosticTroubleCodeTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, DiagnosticTroubleCodeTablePage);

export class DiagnosticTroubleCodeEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "DiagnosticTroubleCode",
            miniFields: ["equipmentResource.identifier", "code"],
            icon: "car",
            defaultFilter: Filter.createComposedForClient(FilterOperators.forComposedFilter.or, [Filter.createForClient("timestamp", FilterOperators.forDate.lessThanHoursAgo, "1")]),
            defaultSort: { field: "timestamp", direction: "DESC" }
        });
    }

    renderTable() {
        return <DiagnosticTroubleCodeTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

export class GoToPositionFieldDescriptor extends FieldDescriptor {
    constructor() {
        super();
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
    }

    getAppearsInUi() {
        return true;
    }

    renderField(entity: any) {
        const erPlateNumberField = entityDescriptors["EquipmentResource"].getField("plateNumber");
        return <NavLink key={entity[ID]} to={
            new ShareLinkLogic().createLink(false, entityDescriptors["Position"],
                Filter.enableAllFilters(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                    Filter.create("plateNumber", FilterOperators.forString.equals, erPlateNumberField.getFieldValue(entity["equipmentResource"])),
                    Filter.create("date", FilterOperators.forDate.between, entity["timestamp"] + ' AND ' + entity["timestamp"])]))
            )}
        >{_msg("DiagnosticTroubleCode.goToPosition")}</NavLink>
    }
}

