import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import React from "react";
import moment from "moment";
import _ from "lodash";
import lodash from 'lodash';
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { GanttAssignmentEntities } from "./GanttAssignmentEntityDescriptor";
import { HistogramWithDetailsRRC, HistogramPoint, HistogramSerie } from "@crispico/foundation-react/components/histogramWithDetails/HistogramWithDetails";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";
import { GanttUtils } from "pages/gantt/GanttUtils";

export class GanttAssignmentHistogramState extends State {
    histogramData = [] as Array<HistogramSerie>;
    startDate = 0;
    endDate = 0;
}

export class GanttAssignmentHistogramReducers<S extends GanttAssignmentHistogramState = GanttAssignmentHistogramState> extends Reducers<GanttAssignmentHistogramState> { };

type Props = RRCProps<GanttAssignmentHistogramState, GanttAssignmentHistogramReducers> & { entities: GanttAssignmentEntities };
export class GanttAssignmentHistogram extends React.Component<Props> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        this.prepareData();
    }

    componentDidUpdate(prevProps: Props) {
        this.componentDidUpdateInternal(prevProps);
    }

    private componentDidUpdateInternal(prevProps?: Props) {
        const { props } = this;
        if (!prevProps || !lodash.isEqual(prevProps.entities, props.entities)) {
            this.prepareData();
        }
    }

    protected getLabelForMission(mission: any, entities: { [key: string]: { [key: number]: any; }; }): string {
        const m = GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", mission.id), entities);
        let hr = m.humanResource;
        const flights = m.tasks.map((task: { taskGroup: any; }) => task?.taskGroup);
        return hr?.firstName + " " + hr?.lastName + " " + flights.map((flight: { airline: any; number: any; }) => flight.airline + flight.number).join(" ");
    }

    protected createPoint(id: number, x: number, y: number, total: string[], inList: string[], out: string[], serieId: string | number) {
        return { id, x, y, total: _.cloneDeep(total), in: inList, out, serieId };
    }

    protected async prepareData() {
        let histogramData = [] as Array<HistogramSerie>;
        const ET = this.props.entities["EquipmentType"];
        let endDate = Number.MIN_VALUE;
        let startDate = Number.MAX_VALUE;
        ET && Object.keys(ET).forEach((key) => {
            const id = Number(key);
            const missions = GanttUtils.find("Mission2", "equipmentType.id", id, this.props.entities);
            const points: Array<HistogramPoint> = [];
            let idP = 1;
            const data: { time: moment.Moment; type: string; label: string }[] = [];
            missions.forEach((key) => {
                data.push({ time: moment(key.startTime), type: "in", label: this.getLabelForMission(key, this.props.entities) });
                data.push({ time: moment(key.endTime), type: "out", label: this.getLabelForMission(key, this.props.entities) });
            });
            let total: string[] = [];
            let oldX: number | undefined = undefined;
            let inMission: string[] = [];
            let outMission: string[] = [];
            let x: number = -1;
            let maxValueSerie = 0;
            data.sort((a: any, b: any) => a.time - b.time).forEach((d) => {
                x = moment(d.time).valueOf();
                if (oldX && oldX != x) {
                    if (maxValueSerie < total.length) {
                        maxValueSerie = total.length;
                    }
                    points.push(this.createPoint(idP++, oldX, total.length, total, inMission, outMission, id));
                    inMission = [];
                    outMission = [];
                }
                if (d.type === "in") {
                    inMission.push(d.label);
                    total.push(d.label);
                } else {
                    outMission.push(d.label);
                    total = total.filter(x => x !== d.label);
                }
                if (endDate < x) {
                    endDate = x;
                }
                if (startDate > x) {
                    startDate = x;
                }
                oldX = x;
            })
            if (inMission.length || outMission.length) {
                if (maxValueSerie < total.length) {
                    maxValueSerie = total.length;
                }
                points.push(this.createPoint(idP++, x, total.length, total, inMission, outMission, id));
            }
            histogramData.push({ id: ET[id].id, label: entityDescriptors["EquipmentType"].toMiniString(ET[id]), color: AppMetaTempGlobals.appMetaInstance.getColor(ET[id].color), data: points, maxY: maxValueSerie });
        });
        ET && Object.keys(ET).forEach((key, index) => {
            let points = [];
            if (histogramData[index].data.length) {
                if (histogramData[index].data[0].x != startDate) {
                    points.push(this.createPoint(0, startDate, 0, [], [], [], key));
                } else {
                    points.push(histogramData[index].data[0]);
                }
            }
            points = points.concat(histogramData[index].data as []);
            if (points.length && points[points.length - 1].x != endDate) {
                points.push(this.createPoint(points.length, endDate, 0, [], [], [], Number(key)));
            }
            histogramData[index].data = points;
        });
        histogramData.sort((a: HistogramSerie, b: HistogramSerie) => (a.label as string).localeCompare(b.label as string));
        this.props.r.setInReduxState({ histogramData, startDate, endDate });
    }

    protected renderHistogramSerieLabel(serie: HistogramSerie): React.ReactNode | string {
        return <NavLink to={entityDescriptors["EquipmentType"].getEntityEditorUrl(serie.id)}>{serie.label}</NavLink>
    }

    render() {
        return <div className="flex-container flex-grow-shrink-no-overflow less-padding">
            <HistogramWithDetailsRRC id="histogramWithDetails" data={this.props.s.histogramData} startDate={this.props.s.startDate} endDate={this.props.s.endDate}
                legendY={entityDescriptors["Mission2"].getLabel(true)} gridSerieLabel={entityDescriptors["EquipmentType"].getLabel()}
                renderHistogramSerieLabel={(serie) => this.renderHistogramSerieLabel(serie)}
            />
        </div>;
    }

}

export const GanttAssignmentHistogramRRC = ReduxReusableComponents.connectRRC(GanttAssignmentHistogramState, GanttAssignmentHistogramReducers, GanttAssignmentHistogram);
