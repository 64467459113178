import gql from "graphql-tag";

const Checklist = gql`
fragment Checklist on Checklist {
  template, values
}
`;

export const LOAD_CHECKLISTS = gql`
  query loadChecklists($params: FindByFilterParamsInput) { 
    checklistService_findByFilter(params: $params) {
      results {...Checklist}
    }
  }
${Checklist}
`;