import gql from "graphql-tag";


export const DRIVING_EVENT_FOR_MAP = gql`
    fragment DrivingEventForMap on DrivingEvent {
        id latitude longitude
    }   
`;

export const LOAD_DRIVING_EVENTS_FOR_MAP = gql`
    query loadDrivingEventsForMap($params: FindByFilterParamsInput) { 
        drivingEventService_findByFilter(params: $params) {
            results  { ...DrivingEventForMap } 
        }
    }
    ${DRIVING_EVENT_FOR_MAP}
`;
