import { addEntityDescriptor, apolloClient, EntityDescriptor, EntityTablePage, EntityTablePagePartialProps, EntityTablePageReducers, EntityTablePageState, FieldDescriptor, Optional, Utils } from "@crispico/foundation-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DrivingEventForMap } from "apollo-gen/DrivingEventForMap";
import { loadDrivingEventsForMap, loadDrivingEventsForMapVariables } from "apollo-gen/loadDrivingEventsForMap";
import { HeatData, HEAT_TYPE, MapContainerLeafletRRC, MapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { LOAD_DRIVING_EVENTS_FOR_MAP } from "pages/DrivingEvent/queries";
import React from "react";
import { Dimmer, Loader, Button, Icon } from "semantic-ui-react";
import lodash from "lodash";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { DrivingEventVideoButtonField } from "./DrivingEventVideoButton";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { XopsAppContainerContextValue } from "XopsAppContainerContext";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

class DrivingEventEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "DrivingEvent",
            miniFields: ["type", "equipmentResource.identifier", "humanResource.identifier"],
            icon: "car",
            defaultFilter: Filter.createForClient("date", FilterOperators.forDate.thisWeek),
            defaultSort: { field: "date", direction: "DESC" }
        });
    }

    renderTable() {
        return <DrivingEventTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

export const drivingEventEntityDescriptor = addEntityDescriptor(new DrivingEventEntityDescriptor()
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "type", type: FieldType.string })
    .addFieldDescriptor({ name: "value", type: FieldType.double })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "humanResource", type: "HumanResource" })
    .addFieldDescriptor({
        name: "date", type: FieldType.date,
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat })
    })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor(new DrivingEventVideoButtonField("video"))
);

class DrivingEventTablePageState extends EntityTablePageState {
    drivingEvents: Optional<DrivingEventForMap[]> = undefined;
    showPointsOnMap: boolean = true;
    selectedEntityIndex: number | undefined = undefined;
}

class DrivingEventTablePageReducers<S extends DrivingEventTablePageState = DrivingEventTablePageState> extends EntityTablePageReducers<S> { }

type DrivingEventTablePageProps = EntityTablePagePartialProps & RRCProps<DrivingEventTablePageState, DrivingEventTablePageReducers>;

class DrivingEventTablePage<P extends DrivingEventTablePageProps = DrivingEventTablePageProps> extends EntityTablePage<P> {

    context!: XopsAppContainerContextValue;

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    async loadDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
        let filterFromCQ = this.getFilterForLoad();
        const events: Optional<DrivingEventForMap[]> = (await apolloClient.query<loadDrivingEventsForMap, loadDrivingEventsForMapVariables>({
            query: LOAD_DRIVING_EVENTS_FOR_MAP,
            variables: FindByFilterParams.create().filter(filterFromCQ)
        })).data.drivingEventService_findByFilter?.results;

        if (!events || events.length === 0) {
            this.props.r.setInReduxState({ drivingEvents: [] });
            return;
        }

        this.props.r.setInReduxState({ drivingEvents: events });
        this.addDrivingEventsOnMap(mapContainer);
    }

    addDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
        let data: HeatData[] = [];
        Object.values(this.props.s.drivingEvents!).forEach((event: DrivingEventForMap) => {
            if (event.longitude && event.latitude) {
                data.push({ id: event.id, point: { longitude: event.longitude, latitude: event.latitude } });
            }
        });
        mapContainer?.addOrUpdateLayers(data, drivingEventEntityDescriptor.name);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdateInternal(prevProps?: P) {
        super.componentDidUpdateInternal(prevProps);

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.context.initializationsForClient.mapSettings?.airport !== null) {
            RealTimeUtils.setAirportCoordinates(this.context.initializationsForClient.mapSettings?.airport!, this.mapContainerRef);
        }

        if (this.mapContainerRef && this.props.s.drivingEvents && prevProps?.s.showPointsOnMap !== this.props.s.showPointsOnMap) {
            this.mapContainerRef.current?.clearMap();
            this.addDrivingEventsOnMap(this.mapContainerRef.current);
        }
    }

    protected onCustomQueryFilterChanged() {
        this.props.r.setInReduxState({ drivingEvents: undefined });
        this.entityTableSimpleRef.current?.setSelected(undefined);
        this.mapContainerRef.current?.clearMap();
        this.loadDrivingEventsOnMap(this.mapContainerRef.current);
        super.onCustomQueryFilterChanged();
    }

    protected onSelectItem(entityId: any): void {
        this.onSelectedEntityChanged(entityId, false);
    }

    protected getTableProps() {
        return { ...super.getTableProps(), scrollToRow: this.props.s.selectedEntityIndex };
    }

    protected onSelectedEntityChanged(entityId: number | undefined, fromMap: boolean) {
        if (entityId) {
            if (fromMap) {
                const { entityDescriptor } = this.props;
                this.goToEditor(entityDescriptor.getEntityEditorUrl(entityId));
                this.props.r.setInReduxState({ selectedEntityIndex: this.entityTableSimpleRef.current?.props.s.entities.findIndex(entity => entity.id === entityId) });
                this.entityTableSimpleRef.current?.setSelected(entityId);
            } else {
                this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: entityId, type: drivingEventEntityDescriptor.name, additionalInfo: { flyToLayer: true } } });
            }
        }
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirport" className="MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.s.drivingEvents === undefined}></Dimmer>
                        <Button color={this.props.s.showPointsOnMap ? "orange" : "teal"} onClick={() => this.props.r.setInReduxState({ showPointsOnMap: !this.props.s.showPointsOnMap })}><Icon name='point' />{_msg(this.props.s.showPointsOnMap ? "DrivingEventTable.hidePoints" : "DrivingEventTable.showPoints")}</Button>
                        <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                    </div>
            }
        ];
    };

    renderMain() {
        return (<>
            <Utils.Observer value={this.context.initializationsForClient.currentOrganization} didUpdate={() => {
                RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(this.mapContainerRef, this.context.initializationsForClient.currentOrganization);
            }} />
            <SplitPaneExt size="30%">
                {this.renderTableSimple()}
                <>
                    <Dimmer inverted active={this.props.s.drivingEvents === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                    <MapContainerLeafletRRC id={"mapContainerLeafletDrivingEventTable"} ref={this.mapContainerRef} mapId={"driving-event-map"} saveCenterZoomInStorage={true}
                        layers={{ [drivingEventEntityDescriptor.name]: { layerType: HEAT_TYPE, options: { hideTooltipOnHoveredLayer: true, showPoints: this.props.s.showPointsOnMap } } }}
                        pruneClusterMode={false}
                        azureMapsAPIKey={this.context.initializationsForClient.mapSettings.azureMapsAPIKey}
                        bingAPIKey={this.context.initializationsForClient.mapSettings?.bingAPIKey}
                        onSelectLayer={() => this.onSelectedEntityChanged(this.mapContainerRef.current?.props.s.selectedLayer?.id as number, true)} />
                </>
            </SplitPaneExt>
        </>)
    }
}

const DrivingEventTablePageRRC = ReduxReusableComponents.connectRRC(DrivingEventTablePageState, DrivingEventTablePageReducers, DrivingEventTablePage);