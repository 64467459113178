import gql from "graphql-tag";

const MISSION = gql`
    fragment Mission2 on Mission2 {
        id tasks {
            id name eventMonitoringRulesJson taskGroup { id date name } 
            mission { id events { id descriptorType taskId creationdate } } 
        } 
        eventMonitoringActualNumberOfEvents eventMonitoringExpectedNumberOfEvents
    }
`;


export const GET_MISSION = gql`
    query mission2Service_findById($id: Long) {
        mission2Service_findById(id: $id) {
           ...Mission2
        }
    }
    ${MISSION}
`;

export const GET_TASK = gql`
    query taskService_findById($id: Long) {
        taskService_findById(id: $id) {
            id mission { ...Mission2 }
        }
    }
    ${MISSION}
`;
