import gql from "graphql-tag";

export const GET_GANTT_ASSIGNMENT_ALGORITHMS = gql`
    query getGanttAssignmentAlgorithms {
        ganttAssignmentService_ganttAssignmentAlgorithms
    }
`;

export const GET_GANTT_ASSIGNMENT_ALGORITHM_DATE_FILTERS = gql`
    query getGanttAssignmentAlgorithmDateFilters($algorithm: String, $startDate: Date, $endDate: Date) {
        ganttAssignmentService_algorithmDateFilters(algorithm: $algorithm, startDate: $startDate, endDate: $endDate)
    }
`;

export const RUN_GANTT_ASSIGNMENT_ALGORITHM = gql`
    mutation runGanttAssignmentAlgorithm($assignmentId: Long!) {
        ganttAssignmentService_runGanttAssignmentAlgorithm(assignmentId: $assignmentId) {
            id, name, flightsStartDate, flightsEndDate, inputEntitiesCsv, outputEntitiesCsv, algorithmType, readOnly
        }
    }
`;

export const CREATE_TASKS = gql`
    mutation createTasks($assignmentId: Long!) {
        ganttAssignmentService_createTasks(assignmentId: $assignmentId) {
            id, name, flightsStartDate, flightsEndDate, inputEntitiesCsv, outputEntitiesCsv, algorithmType, readOnly
        }
    }
`;

export const COPY_INPUT_DATA_FROM_DB = gql`
    mutation copyInputDataFromDb($assignmentId: Long!, $config: [CopyInputDataFromDbConfigInput!]!) {
        ganttAssignmentService_copyInputDataFromDb(assignmentId: $assignmentId, config: $config) {
            id, name, flightsStartDate, flightsEndDate, inputEntitiesCsv, outputEntitiesCsv, algorithmType, readOnly
        }
    }
`;

export const SAVE_FROM_DISK_CSV_FILES = process.env.NODE_ENV === "development" ? gql`
    mutation saveFromDiskCsvFiles($inputPaths: [String], $outputPaths: [String], $startDate: Date, $endDate: Date, $id: Long) {
        ganttAssignmentDevService_saveFromDiskCsvFiles(inputPaths: $inputPaths, outputPaths: $outputPaths, startDate: $startDate, endDate: $endDate, id: $id)
    }
` : null;

export const COPY_GANTT_ASSIGNMENT_TO_DB = gql`
    mutation copyGanttAssignmentToDb($assignmentId: Long!) {
        ganttAssignmentService_copyGanttAssignmentToDb(assignmentId: $assignmentId)
    }
`;

export const COPY_GANTT_ASSIGNMENT_TO_DB_EM = gql`
    mutation copyGanttAssignmentToDbEm($assignmentId: Long!) {
        ganttAssignmentService_copyGanttAssignmentToDbEm(assignmentId: $assignmentId)
    }
`;

export const IMPORT_ENTITIES_FROM_CSV = gql`
    query importEntitiesFromCsv($csv: String) {
        ganttAssignmentService_importEntitiesFromCsv(csv: $csv)
    }
`;

export const GET_PATHS_GA = gql`
    mutation getPaths {
        ganttAssignmentDevService_paths
    }
`;

export const RECALCULATE_GANTT_ASSIGNMENT = gql`
    mutation recalculateGanttAssignment($assignmentId: Long!, $startDate: Date, $endDate: Date) {
        ganttAssignmentService_recalculateGanttAssignment(assignmentId: $assignmentId, startDate: $startDate, endDate: $endDate)
    }
`;

export const DELETE_OUTPUT_DATA_FROM_DB = gql`
    mutation deleteOutputDataBetweenDates($startDate: Date, $endDate: Date, $algorithmType: AlgorithmType, $assignmentId: Long!) {
        ganttAssignmentService_deleteOutputDataBetweenDates(startDate: $startDate, endDate: $endDate, algorithmType: $algorithmType, assignmentId: $assignmentId)
    }
`;

export const EXECUTE_ALL_OPERATIONS = gql`
    mutation executeAllOperations($startDate: Date, $endDate: Date) {
        ganttAssignmentService_executeAllOperations(startDate: $startDate, endDate: $endDate)
    }
`;