import gql from "graphql-tag";

export const GET_PLATE_NUMBERS_FROM_IDS = gql`
    query getPlateNumberFromIds($params: FindByFilterParamsInput) { 
        equipmentResourceService_findByFilter(params: $params) {
            results { id plateNumber equipmentType { name icon } }
        }
    }
`;

export const POSITION_FOR_HISTORY_GRAPH_MAP = gql`
    fragment PositionForHistoryMapGraph on Position {
        id latitude longitude date plateNumber speed gpsProvider telemetry
    }
`;

export const LOAD_POSITIONS_FOR_HISTORY_GRAPH_MAP = gql`
query loadPositionsForHistoryMapGraph($params: FindByFilterParamsInput) {
    positionService_findByFilter(params: $params) {
        results {...PositionForHistoryMapGraph}
    }  
}
${POSITION_FOR_HISTORY_GRAPH_MAP}
`;

export const TRACK_FOR_HISTORY_GRAPH_MAP = gql`
    fragment HistoryTrackForHistoryMapGraph on HistoryTrack {
        id equipmentResource { id identifier } startTime endTime color
    }
`;

export const LOAD_TRACKS_FOR_HISTORY_GRAPH_MAP = gql`
query loadTracksForHistoryMapGraph($params: FindByFilterParamsInput) { 
    historyTrackService_findByFilter(params: $params) {
        results  { ...HistoryTrackForHistoryMapGraph } 
    }
}
${TRACK_FOR_HISTORY_GRAPH_MAP}
`;

export const LOAD_DIAGNOSTIC_TROUBLE_CODE = gql`
query loadDiagnosticTroubleCode($params: FindByFilterParamsInput) {
    diagnosticTroubleCodeService_findByFilter(params: $params) {
        results { id name categoryDescription categoryCode severity description code source rawValue timestamp } totalCount
    }
}`