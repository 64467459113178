import { FilterOperators } from "@crispico/foundation-gwt-js";
import { Utils, apolloClientHolder, FieldDescriptor } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { SelectExtOption } from "@crispico/foundation-react/components/selectExt/SelectExt";
import { AssociationFieldEditor, AssociationFieldEditorProps, AssociationFieldEditorState } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldEditorProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import arrayMove from "array-move";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { SortEnd } from "react-sortable-hoc";
import { ScriptableUiFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/FieldEditor";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";

export const ASSOCIATION_MARKER = "$";
export const ASSOCIATION_SEPARATOR = ",";

type AssociationStringFieldEditorState = {
    values: any[] // these are the entities corresponding to the currently selected ids
} & AssociationFieldEditorState;

export class AssociationStringFieldEditor<P> extends AssociationFieldEditor<P & AssociationFieldEditorProps, AssociationStringFieldEditorState> {
    constructor(props: P & AssociationFieldEditorProps) {
        super(props);
        this.state = {...this.state, values: []};
    }

    componentDidMount(): void {
        this.init();
    }

    protected async init() {
        const selectedOption = this.getSelectedOption();
        if (!selectedOption?.length) {
            return;
        }
        const name: string = `${_.lowerFirst(this.props.innerEntityDescriptor!.name)}Service_findByFilter`;
        const fields: string[] = this.props.innerEntityDescriptor!.miniFields.concat(this.props.additionalSearchFields ? this.props.additionalSearchFields : []);
        const query = gql(`query q($params: FindByFilterParamsInput) { 
            ${name}(params: $params) {
                results {
                    ${this.props.innerEntityDescriptor!.idFields.join(" ")} ${this.props.innerEntityDescriptor!.getGraphQlFieldsToRequest(fields)}
                }
            }
        }`);
        const values = (await apolloClientHolder.apolloClient.query({
            query: query,
            variables: FindByFilterParams.create().filter(Filter.create("id", FilterOperators.forEntityOneToMany.in, this.removeMarker(this.getSelectedOption()))),
            context: { showSpinner: false }
        })).data[name].results;
        this.setState({ values });
    }

    protected changeSelectedValue(option: any, s?: WithHW<ScriptableUiFieldEditor.Main>, hw?: ScriptableUiHighlightWrapper) {
        s?.setFieldValue(hw!, this.addMarker(option?.map((o: { id: number }) => o.id).join(ASSOCIATION_SEPARATOR)));
        this.setState({ values: option ? option : [] });
    }

    protected onSortEnd(sort: SortEnd) {
        this.props.formikProps?.setFieldValue(this.props.fieldDescriptor.name, this.addMarker(arrayMove(this.getSelectedValue() as SelectExtOption[], sort.oldIndex, sort.newIndex).map(o => o.value).join(ASSOCIATION_SEPARATOR)));
    }
 
    protected getSelectedValue() {
        const allEntities = this.state.entities.concat(this.state.values);
        return allEntities.length > 0 ? this.removeMarker(this.getSelectedOption()).split(ASSOCIATION_SEPARATOR).filter((o: string) => !Utils.isNullOrEmpty(o)).map((o: string) => {
            const entity = allEntities.find(entity => entity.id === Number(o));
            return entity ? { label: this.getLabel(entity), value: String(entity.id), entity } : undefined;
        }).filter(entity => entity) as any : [];
    }

    protected addMarker(value: string) {
        return value?.length ? (ASSOCIATION_MARKER + value) : "";
    }

    protected removeMarker(value: string) {
        return value?.length ? value.replace(ASSOCIATION_MARKER, "") : "";
    }
}

export function getIdsFromAssociationStringField(field?: string | null) {
    if (!field || !field.startsWith(ASSOCIATION_MARKER)) {
        return [];
    }
    return field.substring(1).split(ASSOCIATION_SEPARATOR).map(id => Number(id));
}

export function getAssociationStringFieldFromIds(ids: number[]) {
    if (ids.length === 0) {
        return "";
    }
    return ASSOCIATION_MARKER + ids.join(ASSOCIATION_SEPARATOR);
}

export const AssociationStringFieldEditorFieldDescriptor = (entityTypeName: string) => new class extends FieldDescriptor {
    protected renderFieldEditorInternal(EditorClass: any, props: FieldEditorProps) {
        const newProps = {
            ...props,
            innerEntityDescriptor: entityDescriptors[entityTypeName],
            isMulti: true
        } as FieldEditorProps;
        return React.createElement(AssociationStringFieldEditor as any, newProps);
    }
}();
