import { Timeline } from '@crispico/react-timeline-10000';
import PropTypes from 'prop-types';
import { DragAndDropContoller } from './DragAndDropControler';
import { Droppable } from '@crispico/foundation-react/components/DragAndDrop/DragAndDrop';
import { isFlexMode } from 'app';

export default class XopsTimeline extends Timeline {

    static propTypes = {
        ...Timeline.propTypes,
        droppableProps: PropTypes.shape({
            dragable: PropTypes.bool,
            item: PropTypes.object,
        })
    }

    static defaultProps = {
        ...Timeline.defaultProps,
        droppableProps: {
            dragable: false,
            item: {},
        }
    }

    get selectedItems() {
        return this._selectionHolder ? this._selectionHolder.state.selectedItems : [];
    }

    renderMenuButton() {
        return null;
    }

    renderGanttPart({ bodyHeight, timebarHeight }) {
        const { dragable, item } = this.props.droppableProps;
        if (dragable) {
            return <Droppable className="flex-container flex-grow" item={item} accept={DragAndDropContoller.INSTANCE.getAcceptedType(item.entityUid)} canDrop={isFlexMode() ? undefined : DragAndDropContoller.INSTANCE.canDrop} drop={isFlexMode() ? undefined : DragAndDropContoller.INSTANCE.onDrop}>
                {super.renderGanttPart({ bodyHeight, timebarHeight })}
            </Droppable>
        }
        return super.renderGanttPart({ bodyHeight, timebarHeight });
    }
}
