import { addEntityDescriptor, EntityDescriptor, EntityEditorPage, EntityEditorPageProps } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";

export class FlightScheduleEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "FlightSchedule",
            miniFields: ["airline", "flightNumber", "airport.code", "startDate", "endDate"],
            icon: "plane"
        });
    }

    protected customize() {
        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {
            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                return [
                    ...super.getExtraTabPanes(),
                    this.createOneToManyTabPane("Flight", "flightSchedule", { sort: { field: "date", direction: "DESC" }, filter: Filter.createComposedForClient(FilterOperators.forComposedFilter.and, []) })
                ];
            }
        }
    }
}

export const flightScheduleEntityDescriptor = addEntityDescriptor(new FlightScheduleEntityDescriptor()
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "airline", type: FieldType.string })
    .addFieldDescriptor({ name: "flightNumber", type: FieldType.string })
    .addFieldDescriptor({ name: "startDate", type: FieldType.string })
    .addFieldDescriptor({ name: "endDate", type: FieldType.string })
    .addFieldDescriptor({ name: "airport", type: "Airport" })
    .addFieldDescriptor({ name: "departure", type: FieldType.boolean })
    .addFieldDescriptor({ name: "daysOfTheWeek", type: FieldType.string })
    .addFieldDescriptor({ name: "rotation", type: "FlightSchedule" })
    .addFieldDescriptor({ name: "targetForThisException", type: "FlightSchedule" })
    .addFieldDescriptor({ name: "rotationDuration", type: FieldType.number })
    .addFieldDescriptor({ name: "deactivatedAt", type: FieldType.date })
    .addFieldDescriptor({ name: "properties", type: FieldType.text, sortable: false, enabled: false })
);
