import { FilterOperators } from "@crispico/foundation-gwt-js";
import { EntityDescriptor, EntityTablePage, EntityTablePageProps, EntityTablePageReducers, EntityTablePageState } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { HumanResourceScheduleTeamsPageRRC } from "./HumanResourceScheduleTeamsPage";
import { AssociationStringFieldEditorFieldDescriptor } from "components/AssociationStringFieldEditor";
import { MissionTotalTimeFieldDescriptor } from "pages/CommonFieldDescriptors";
import { ReduxReusableComponents } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";

export class HumanResourceScheduleEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "HumanResourceSchedule",
            miniFields: ["humanResource.identifier", "startTime"],
            icon: "group",
            defaultFilter: Filter.createForClient("startTime", FilterOperators.forDate.today)
        });
    }

    protected customize() {
        this.addFieldDescriptor({ name: "team", type: FieldType.string }, AssociationStringFieldEditorFieldDescriptor("HumanResource"))
            .addFieldDescriptor(new MissionTotalTimeFieldDescriptor())
            // it doesn't work on a table/editor page, was used only in gantt for get missions using the proxy 
            .addFieldDescriptor({ name: "missions", type: FieldType.oneToMany("Mission2"), oneToManyOppositeField: "humanResource", oneToManyEntityField: "humanResource", enable: false, filterable: false, sortable: false, isInDefaultColumnConfig: false, clientOnly: true })
        this.isInDefaultColumnConfig(false, "humanResourceId", "finished", "units", "autoPauseProcessed", "equipment", "radio", "driverAssistant1Id", "driverAssistant2Id");
    }

    renderTable() {
        return <HumanResourceScheduleTablePageRRC {...super.renderTable().props} ref={this.entityTablePage} />;
    }
}

class HumanResourceScheduleTablePage extends EntityTablePage<EntityTablePageProps> {
    protected getExtraTabPanes(): (TabRouterPane | null)[] {
        return [humanResourceScheduleTeamsPageTab, ...super.getExtraTabPanes()];
    }
};

export const HumanResourceScheduleTablePageRRC = ReduxReusableComponents.connectRRC(EntityTablePageState, EntityTablePageReducers, HumanResourceScheduleTablePage);

export const humanResourceScheduleTeamsPageTab = {
    routeProps: { path: "/teamsPage" },
    menuItemProps: { icon: "group", content: _msg("HumanResourceScheduleTeams.title") },
    render: () => <HumanResourceScheduleTeamsPageRRC id="humanResourceScheduleTable_teamsPage" />
};


