import gql from "graphql-tag";

export const INVENTORY_DATA = gql`
    fragment InventoryData on InventoryHistoryData {
        id driverName driverIdentifier creationDate zone akhData akeData cpcData cpbData
    }
`;

export const LOAD_INVENTORY = gql`
    query loadInventory($params: FindByFilterParamsInput) { 
        inventoryHistoryDataService_findByFilter(params: $params) {
            results  { ...InventoryData } 
        }
    }
    ${INVENTORY_DATA}
`;

export const LOAD_INVENTORY_SETTINGS = gql`
    query loadInventorySettings {
        inventoryHistoryDataService_inventorySettings {
            zones { name counters { name } }
        }
    }
`;