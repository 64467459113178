import gql from "graphql-tag";

export const LOAD_TASK_TYPES = gql`
    query loadTaskTypes($params: FindByFilterParamsInput) {
        taskTypeService_findByFilter(params: $params) {
            results {
                id, name, __typename
            }
        }
    }
`;

export const LOAD_CONNECTION_FLIGHTS = gql`
    query getConnectionFlights($params: FindByFilterParamsInput) {
        flightService_findByFilter(params: $params) {
            results { id name date origin destination __typename }
        }
    }
`;

export const LOAD_CONNECTION_FLIGHTS_IDS = gql`
    query getConnectionFlightsIds($params: FindByFilterParamsInput) {
        flightConnexionService_findByFilter(params: $params) {
            results { id }
        }
    }
`;

export const UPDATE_TASK = gql`
    mutation updateTask($params: SaveParams_LongInput) {
        taskService_save(params: $params) {
            id
        }
    }
`;

export const LOAD_TYPOLOGIES = gql`
    query loadTypologies($params: FindByFilterParamsInput) {
        typologyService_findByFilter(params: $params) {
            results {id, name} 
        }
    }
`;

export const LOAD_VALUES_GROUP = gql`
    query loadValuesGroup($params: FindByFilterParamsInput) {
        valuesGroupService_findByFilter(params: $params) {
            results {id, values} 
        }
    }
`;

export const LOAD_FLIGHT_DETAILS = gql`
    query getFlight($Id: Long) {
        flightService_findById(id: $Id) {
            departure
            parking {
                id name
            }
        }
    }
`;

export const CREATE_ADDRESS = gql`
    mutation createAddress($sariaPort: String) {
        addressService_orCreateAddress(code: $sariaPort) {
            id name __typename
        }
    }
`;

export const LOAD_TASKS_FOR_KPI_PAGE = gql`
    query loadTasksForKPIPage($params: FindByFilterParamsInput) {
        taskService_findByFilter(params: $params) {
            results { id name startTime endTime actualStartTime actualEndTime actualStartTimeFixed actualEndTimeFixed
                taskGroup { id name date departure arrivalDate planeIdentifier organization { id name qualifiedName airport { id code name timeZone } } } 
                mission { id status equipmentResource { id identifier plateNumber } customFields } 
            }
        }
    }
`;

export const LOAD_REPORT_DATA_FOR_KPI_PAGE = gql`
query prepareKpiReport($start: Date, $end: Date, $timezone: String) {
    taskService_prepareKpiReport(start: $start, end: $end, timezone: $timezone) {
        kpiSumsPerOrganization operationTimeLostPerReason equipmentsCountPerOrgs numberOfCalculationDaysPerMonth
        tasksForExport { id name startTime endTime actualStartTime actualEndTime firstGaStartTime firstGaEndTime flightDate connectedTime disconnectedTime plannedConnectedTime plannedDisconnectedTime taskDuration firstGaTaskDuration operationTimeDone opportunityLost 
        timeToConnect ganttAssignmentUsed status reason flightName registration flightId plateNumber equipmentResourceId  }
    }
}
`;