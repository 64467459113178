import { LoginPage, LoginTypeEnum } from "@crispico/foundation-react/pages/loginPage/LoginPage";
import { Form, Label, Button, Message, Icon, SemanticShorthandItem, TabPaneProps, Grid } from "semantic-ui-react";
import React from "react";
import { humanResourceEntityDescriptor } from "AppEntityDescriptors";
import { AuthenticationStatus } from "@crispico/foundation-react/apollo-gen-foundation/globalTypes";
import { Utils } from "@crispico/foundation-react/utils/Utils";
import { XOPS_MOBILE_SETTINGS_PATH, XopsMobileWrapper } from "./XopsMobile/XopsMobileWrapper";
import { NavLink } from "react-router-dom";
import { LoginParamsInput } from "apollo-gen/globalTypes";
import { mobileDeviceEntityDescriptor } from "./MobileDevice/mobileDeviceEntityDescriptor";

// extend from LoginTypeEnum
// tip found here: https://devimalplanet.com/typescript-how-to-extend-one-enum-from-another
export const XopsLoginTypeEnum = { ...LoginTypeEnum, HRLOGIN: 'HRLOGIN' } as const;
type XopsLoginTypeEnum = typeof XopsLoginTypeEnum[keyof typeof XopsLoginTypeEnum];
export class XopsLoginPage extends LoginPage {

    protected backgroundImageURL() {
        if (XopsMobileWrapper.isDeviceMobileApp()) {
            return undefined;
        }
        return super.backgroundImageURL();
    }

    protected verifyCredentials(username: string, password: string, otpToken: string) {
        if (this.state.loginType === XopsLoginTypeEnum.HRLOGIN) {
            if (Utils.isNullOrEmpty(password)) {
                this.loginCallback(AuthenticationStatus.FAILURE, _msg("XopsLoginPage.pdaIdentifier.notNull", _msg("HumanResource.pdaIdentifier.label")));
                return false;
            }
            return true;
        }
        return super.verifyCredentials(username, password, otpToken);
    }

    protected getPanes(): {
        pane?: SemanticShorthandItem<TabPaneProps>
        menuItem?: any
        render?: () => React.ReactNode
    }[] {
        return [...super.getPanes(),
        {
            pane: { loginType: XopsLoginTypeEnum.HRLOGIN as string },
            menuItem: { key: XopsLoginTypeEnum.HRLOGIN as string, icon: humanResourceEntityDescriptor.getIcon(), content: _msg("XopsLoginPage.label") },
            render: () => this.renderHRLoginTab()
        }
        ];
    }

    protected renderHRLoginTab() {
        return <Form error onSubmit={this.onSubmit} className="attached segment">
            {this.renderPasswordInput(_msg("XopsLoginPage.humanResource.placeholder"), true)}
            <Button disabled={this.state.disableLoginBtn} primary fluid size='large'>{_msg("login.signIn")}</Button>
            {this.state.error ? <Message style={{ textAlign: 'left' }} error header={_msg("alert.loginFailed")} content={this.state.error} /> : null}
        </Form>
    }

    protected renderAdditionalRows() {
        if (!XopsMobileWrapper.isDeviceMobileApp()) {
            return null;
        }

        return (<>
            <Grid.Row style={{ marginTop: "100px" }}><Label color="orange" content={<><Icon name={mobileDeviceEntityDescriptor.icon} /> {XopsMobileWrapper.getMobileDeviceInfo().mobileDeviceIdentifier}</>} /></Grid.Row>
            <Grid.Row>
                <NavLink to={"/" + XOPS_MOBILE_SETTINGS_PATH}><Label color="blue"><Icon name='settings' />{_msg("XopsMobile.settingsPage.title")}</Label></NavLink>
            </Grid.Row>
        </>);
    }

    protected createLoginParams(): LoginParamsInput {
        const loginParams = super.createLoginParams();
        loginParams.data = XopsMobileWrapper.getMobileDeviceInfo();
        return loginParams;
    }
}
