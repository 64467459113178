import Blockly from "blockly";

Blockly.Msg['LISTS_CREATE_WITH_INPUT_WITH'] = "on new telemetry event";

export const new_blocks_func = function (varsPerType, functionsDropdownList, functionsDropdownMap) {

    Blockly.defineBlocksWithJsonArray([{
        "type": "get_variable",
        "message0": "%1",
        "args0": [{
            "type": "field_dropdown",
            "name": "VARIABLE_NAME",
            "options": varsPerType["eqFields"]
        }],
        "colour": 355,
        "output": null
    },
    {
        "type": "get_eqType",
        "message0": "%1",
        "args0": [{
            "type": "field_dropdown",
            "name": "EQ_TYPE",
            "options": varsPerType["eqTypes"]
        }],
        "colour": '#9fa55b',
        "output": null
    },
    {
        "type": "execute",
        "message0": "execute %1",
        "args0": [{
            "type": "input_value",
            "name": "LIST_REQUIRED"
        }],
        "message1": "%1",
        "args1": [{
            "type": "input_statement",
            "name": "DO",
            "nextStatement": null
        }],
        "colour": 355
    }
    ]);

    Blockly.Blocks['custom_calls_previous'] = {
        init: function () {
            this.appendDummyInput("dropdownInput").appendField(new Blockly.FieldDropdown(functionsDropdownList), 'MODE');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(354);
            this.setInputsInline(true);
            this.fieldsList_ = [];
        },
        onchange: function (event) {
            this.fieldsList_ = functionsDropdownMap[this.getFieldValue('MODE')];
            this.setValues(this.fieldsList_);
        },
        mutationToDom: function () {
            var container = document.createElement('mutation');
            container.setAttribute('MODE', this.fieldsList_.join(","));
            return container;
        },
        domToMutation: function (xmlElement) {
            var fields = xmlElement.getAttribute('mode');
            if (fields && fields.trim()) {
                this.fieldsList_ = fields.split(",");
            } else {
                this.fieldsList_ = [];
            }
            this.setValues(this.fieldsList_);
        },
        setValues(params) {
            // in case list was not modified, no need to reinitialize the block (it will also cause bugs if you don't do this)
            if (this.currentParams != null) {
                if (this.currentParams.length === params.length && this.currentParams.every((value, index) => value === params[index])) {
                    return;
                }
            }

            // clear all inputs
            while (this.inputList.length > 1) {
                this.removeInput(this.inputList[1].name);
            }

            // add new inputs
            for (var j = 0; j < params.length; j++) {
                var elemName = params[j];
                this.appendDummyInput(elemName + "Dummy").appendField(elemName, elemName + "Label");
                this.appendValueInput(elemName + "Var");
            }

            this.currentParams = params;
        }
    };

    Blockly.Blocks['custom_calls_output'] = {
        init: function () {
            this.appendDummyInput("dropdownInput").appendField(new Blockly.FieldDropdown(functionsDropdownList), 'MODE');
            this.setOutput(true);
            this.setNextStatement(true);
            this.setColour(354);
            this.setInputsInline(true);
            this.fieldsList_ = [];
        },
        onchange: function (event) {
            this.fieldsList_ = functionsDropdownMap[this.getFieldValue('MODE')];
            this.setValues(this.fieldsList_);
        },
        mutationToDom: function () {
            var container = document.createElement('mutation');
            container.setAttribute('MODE', this.fieldsList_.join(","));
            return container;
        },
        domToMutation: function (xmlElement) {
            var fields = xmlElement.getAttribute('mode');
            if (fields && fields.trim()) {
                this.fieldsList_ = fields.split(",");
            } else {
                this.fieldsList_ = [];
            }
            this.setValues(this.fieldsList_);
        },
        setValues(params) {
            // in case list was not modified, no need to reinitialize the block (it will also cause bugs if you don't do this)
            if (this.currentParams != null) {
                if (this.currentParams.length === params.length && this.currentParams.every((value, index) => value === params[index])) {
                    return;
                }
            }

            // clear all inputs
            while (this.inputList.length > 1) {
                this.removeInput(this.inputList[1].name);
            }

            // add new inputs
            for (var j = 0; j < params.length; j++) {
                var elemName = params[j];
                this.appendDummyInput(elemName + "Dummy").appendField(elemName, elemName + "Label");
                this.appendValueInput(elemName + "Var");
            }

            this.currentParams = params;
        }
    };

    Blockly.Blocks['logic_operation_with_output'] = {
        init: function () {
            this.setStyle("logic_blocks");
            this.enableField = this.appendDummyInput('ENABLED').appendField(new Blockly.FieldCheckbox(true), 'ENABLED');
            this.enableField.setVisible(false);
            this.operatorField = this.appendDummyInput().appendField(
                new Blockly.FieldDropdown([
                    ["%{BKY_LOGIC_OPERATION_AND}", "AND"],
                    ["%{BKY_LOGIC_OPERATION_OR}", "OR"]
                ]),
                "OP"
            );
            this.itemCount_ = 2;
            this.updateShape_();
            this.setOutput(true, "Boolean");
            this.setMutator(new Blockly.Mutator(["lists_create_with_item"]));
            this.setInputsInline(true);
            this.setColour('%{BKY_LOGIC_HUE}');
        },
    
        getOperator: function () {
            return this.operatorField.fieldRow[0].value_;
        },
    
        isDisabled: function () {
            return !this.enableField.fieldRow[0].value_;
        },
    
        mutationToDom: function () {
            var a = document.createElement("mutation");
            a.setAttribute("items", this.itemCount_);
            return a;
        },
    
        domToMutation: function (a) {
            this.itemCount_ = parseInt(a.getAttribute("items"), 10);
            this.updateShape_();
        },
    
        decompose: function (a) {
            var b = a.newBlock("lists_create_with_container");
            b.initSvg();
            for (var c = b.getInput("STACK").connection, d = 0; d < this.itemCount_; d++) {
                var e = a.newBlock("lists_create_with_item");
                e.initSvg();
                c.connect(e.previousConnection);
                c = e.nextConnection;
            }
            return b;
        },
    
        compose: function (a) {
            var b = a.getInputTargetBlock("STACK");
            for (a = []; b;) {
                a.push(b.valueConnection_); b = b.nextConnection && b.nextConnection.targetBlock();
            }
            for (b = 0; b < this.itemCount_; b++) {
                var c = this.getInput("ADD" + b).connection.targetConnection;
                c && -1 === a.indexOf(c) && c.disconnect();
            }
            this.itemCount_ = a.length;
            this.updateShape_();
            for (b = 0; b < this.itemCount_; b++)Blockly.Mutator.reconnect(a[b], this, "ADD" + b);
        },
        saveConnections: function (a) {
            a = a.getInputTargetBlock("STACK");
            for (var b = 0; a;) {
                var c = this.getInput("ADD" + b);
                a.valueConnection_ = c && c.connection.targetConnection;
                b++;
                a = a.nextConnection && a.nextConnection.targetBlock()
            }
        },
    
        updateShape_: function () {
            this.itemCount_ && this.getInput("EMPTY")
                ? this.removeInput("EMPTY")
                : this.itemCount_ ||
                this.getInput("EMPTY") ||
                this.appendDummyInput("EMPTY").appendField(
                    Blockly.Msg.LISTS_CREATE_EMPTY_TITLE
                );
            for (var a = 0; a < this.itemCount_; a++)
                if (!this.getInput("ADD" + a)) {
                    this.appendValueInput("ADD" + a).setCheck("Boolean");
                }
            for (; this.getInput("ADD" + a);) {
                this.removeInput("ADD" + a);
                a++;
            }
        }
    }

}